import React from 'react';
import { cn } from '../../lib/utils';

export interface AvatarProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: 'xs-16' | 'sm-24' | 'md-40' | 'lg-48' | 'xl-64';
  src?: string;
  fallback?: string;
}

const sizeClasses = {
  'xs-16': 'w-4 h-4 text-[8px]',    // 16px
  'sm-24': 'w-6 h-6 text-xs',       // 24px
  'md-40': 'w-10 h-10 text-lg',     // 40px
  'lg-48': 'w-12 h-12 text-xl',     // 48px
  'xl-64': 'w-16 h-16 text-3xl',    // 64px
};

export const Avatar = React.forwardRef<HTMLDivElement, AvatarProps>(
  ({ className, size = 'md-40', src, fallback, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          'relative inline-flex items-center justify-center rounded-full bg-orchid-400 font-medium text-neutral-content',
          sizeClasses[size],
          className
        )}
        {...props}
      >
        {src ? (
          <img
            src={src}
            alt={fallback}
            className="h-full w-full rounded-full object-cover"
          />
        ) : (
          <span className="select-none">{fallback?.slice(0, 2).toUpperCase()}</span>
        )}
      </div>
    );
  }
);

Avatar.displayName = 'Avatar';
