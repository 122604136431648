import React from 'react';
import { cn } from '../../utils/cn';
import { Error } from './icons/Error';
import { Caution2 } from './icons/Caution2';
import { Success } from './icons/Success';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  state?: "default" | "success" | "filled" | "selected" | "focused" | "hovered" | "caution" | "error" | "disabled";
  label?: string;
  showLabel?: boolean;
  helperText?: string;
  showHelperText?: boolean;
  className?: string;
  leftIcon?: React.ReactNode;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ 
    state = "default",
    label = "Label",
    showLabel = true,
    helperText,
    showHelperText = false,
    className,
    leftIcon,
    disabled,
    ...props 
  }, ref) => {
    const [isFocused, setIsFocused] = React.useState(false);
    const [isHovered, setIsHovered] = React.useState(false);

    // If disabled prop is passed, override state
    const computedState = disabled ? "disabled" : (isFocused ? "focused" : (isHovered ? "hovered" : state));

    type StateIconType = {
      [key in NonNullable<InputProps['state']>]: React.ReactNode;
    };

    const stateIcon: StateIconType = {
      error: <Error />,
      caution: <Caution2 />,
      success: <Success />,
      default: null,
      filled: null,
      selected: null,
      focused: null,
      hovered: null,
      disabled: null
    };

    const icon = stateIcon[computedState];

    return (
      <div className={cn("flex flex-col items-start gap-1 relative", className)}>
        {showLabel && (
          <div className={cn(
            "text-xs font-semibold leading-5 text-neutral-600 dark:text-neutral-400",
            {
              "text-red-500 dark:text-red-400": computedState === "error",
              "text-yellow-500 dark:text-yellow-400": computedState === "caution",
              "text-green-500 dark:text-green-400": computedState === "success",
              "text-neutral-400 dark:text-neutral-500": computedState === "disabled",
            }
          )}>
            {label}
          </div>
        )}

        <div className="relative w-full">
          {leftIcon && (
            <div className="absolute left-3 top-1/2 -translate-y-1/2">
              {leftIcon}
            </div>
          )}
          <input
            ref={ref}
            disabled={computedState === "disabled"}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={cn(
              "w-full px-3 py-1.5 rounded-lg text-[15px] leading-6 font-normal",
              leftIcon && "pl-10",
              {
                "border-[1.5px]": ["selected"].includes(computedState),
                "border-[2px]": computedState === "focused",
                "border": !["selected", "focused"].includes(computedState),
                "border-purple-400 dark:border-purple-300": computedState === "selected",
                "border-blue-500 dark:border-blue-300 bg-white dark:bg-neutral-900": computedState === "focused",
                "border-red-500 dark:border-red-400": computedState === "error",
                "border-yellow-500 dark:border-yellow-400": computedState === "caution",
                "border-green-500 dark:border-green-400": computedState === "success",
                "border-neutral-300 dark:border-neutral-600": computedState === "disabled",
                "border-neutral-200 dark:border-neutral-600": computedState === "hovered",
                "border-neutral-200 dark:border-neutral-700": !["selected", "error", "caution", "success", "focused", "disabled", "hovered"].includes(computedState),
                "bg-neutral-50 dark:bg-neutral-700": computedState === "hovered",
                "bg-neutral-100 dark:bg-neutral-600": computedState === "selected",
                "bg-neutral-200 dark:bg-neutral-800": computedState === "disabled",
                "bg-white dark:bg-neutral-900": !["hovered", "selected", "disabled"].includes(computedState),
                "text-neutral-400 dark:text-neutral-500": computedState === "disabled" || computedState === "default",
                "text-neutral-500 dark:text-neutral-400": ["hovered", "focused"].includes(computedState),
                "text-neutral-900 dark:text-neutral-100": computedState === "selected",
                "cursor-not-allowed": computedState === "disabled",
                "hover:bg-neutral-50 dark:hover:bg-neutral-700": computedState !== "disabled",
                "placeholder:text-neutral-400 dark:placeholder:text-neutral-500": true,
                "focus:outline-none": true,
              }
            )}
            {...props}
          />
          {icon && (
            <div className="absolute right-3 top-1/2 -translate-y-1/2">
              {icon}
            </div>
          )}
        </div>

        {showHelperText && (
          <div className={cn(
            "text-xs leading-4 text-neutral-500 dark:text-neutral-400",
            {
              "text-red-500 dark:text-red-400": computedState === "error",
              "text-yellow-500 dark:text-yellow-400": computedState === "caution",
              "text-green-500 dark:text-green-400": computedState === "success",
              "text-neutral-300 dark:text-neutral-600": computedState === "disabled",
            }
          )}>
            {helperText}
          </div>
        )}
      </div>
    );
  }
);

Input.displayName = 'Input';