import React from "react";

interface Props {
  className?: string;
}

export const Caution2 = ({ className = "" }: Props): JSX.Element => {
  return (
    <svg
      className={className}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00016 5.33301V7.99967M8.00016 10.6663H8.00683M14.0002 7.99967C14.0002 11.3134 11.3135 14 8.00016 14C4.68683 14 2.00016 11.3134 2.00016 7.99967C2.00016 4.68634 4.68683 1.99967 8.00016 1.99967C11.3135 1.99967 14.0002 4.68634 14.0002 7.99967Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
