import React from 'react';
import { cn } from '../../utils/cn';

interface SwitchProps {
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  label?: string;
  disabled?: boolean;
}

export const Switch = ({ checked, onCheckedChange, label, disabled }: SwitchProps) => {
  return (
    <label className="inline-flex items-center gap-2">
      <button
        role="switch"
        aria-checked={checked}
        onClick={() => !disabled && onCheckedChange(!checked)}
        disabled={disabled}
        className={cn(
          "relative inline-flex h-7 w-12 items-center rounded-full transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2",
          checked 
            ? "bg-[var(--brand-primary)]" 
            : "bg-[var(--neutral-border)]",
          disabled && checked
            ? "bg-[var(--neutral-border)]"
            : "",
          disabled && !checked
            ? "bg-[var(--neutral-border-weak)]"
            : "",
        )}
      >
        <span
          className={cn(
            "inline-block h-[22px] w-[22px] transform rounded-full bg-[var(--neutral-background)] transition-transform",
            checked ? "translate-x-[22px]" : "translate-x-[3px]",
            disabled ? "opacity-50" : "",
          )}
        />
      </button>
      {label && (
        <span className={cn(
          "text-sm",
          disabled ? "text-[var(--neutral-content-disabled)]" : "text-[var(--neutral-content)]"
        )}>
          {label}
        </span>
      )}
    </label>
  );
};