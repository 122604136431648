import React, { useState } from 'react';
import { ChevronDown } from 'lucide-react';
import { cn } from '../../utils/cn';
import { Avatar } from './Avatar';

interface VerticalExpandableSectionProps {
  title: string;
  subtitle?: string;
  avatar?: {
    src?: string;
    fallback: string;
  };
  children?: React.ReactNode;
  className?: string;
}

export const VerticalExpandableSection: React.FC<VerticalExpandableSectionProps> = ({
  title,
  subtitle,
  avatar,
  children,
  className,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className={cn(
      "w-full border-b border-neutral-subtle overflow-hidden transition-all duration-150 ease-out",
      className
    )}>
      <div className="relative">
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className={cn(
            "flex items-center w-full text-left transition-all duration-150 ease-out h-[72px] px-6",
            !isExpanded && "hover:bg-neutral-subtle/5"
          )}
        >
          {avatar && (
            <div className="absolute left-6 top-[37px]">
              <div 
                className={cn(
                  "absolute -translate-y-1/2",
                  isExpanded ? "translate-x-[-56px] opacity-0" : "translate-x-0 opacity-100",
                  "transition-all duration-150 ease-out"
                )}
              >
                <Avatar 
                  size="md-40" 
                  src={avatar.src} 
                  fallback={avatar.fallback} 
                />
              </div>
            </div>
          )}
          <div className={cn(
            "flex-grow flex flex-col justify-center transition-all duration-150 ease-out",
            isExpanded ? "translate-x-0" : "translate-x-[56px]"
          )}>
            <span className="text-[16px] font-bold text-neutral-content block">
              {title}
            </span>
            <div className={cn(
              "transition-all duration-150 ease-out",
              isExpanded ? "translate-x-0 opacity-0" : "translate-x-0 opacity-100"
            )}>
              {subtitle && (
                <span className="text-sm text-neutral-content-subtle block">
                  {subtitle}
                </span>
              )}
            </div>
          </div>
          <ChevronDown 
            className={cn(
              "w-6 h-6 text-neutral-content transition-transform duration-150 ease-out absolute right-6 top-1/2 -translate-y-1/2",
              isExpanded && "transform rotate-180"
            )}
          />
        </button>
        <div 
          className={cn(
            "grid transition-all duration-150 ease-out",
            isExpanded ? "grid-rows-[1fr] opacity-100" : "grid-rows-[0fr] opacity-0"
          )}
        >
          <div className="overflow-hidden">
            <div className="px-6 pt-2 pb-[32px] text-neutral-content-subtle">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}; 