import React from 'react';
import { cn } from '../../lib/utils';

type SpacerSize = '1x' | '2x' | '3x' | '4x' | '5x' | '6x' | '8x' | '10x' | '12x' | '16x';

interface DividerProps {
  type?: 'vertical' | 'horizontal';
  size?: SpacerSize;
  className?: string;
  decorative?: boolean;
}

export const Divider = React.forwardRef<HTMLDivElement, DividerProps>(
  ({ type = 'horizontal', size = '1x', className, decorative = true }, ref) => {
    const spacing = {
      '1x': 'var(--spacer-h-1x)',
      '2x': 'var(--spacer-h-2x)',
      '3x': 'var(--spacer-h-3x)',
      '4x': 'var(--spacer-h-4x)',
      '5x': 'var(--spacer-h-5x)',
      '6x': 'var(--spacer-h-6x)',
      '8x': 'var(--spacer-h-8x)',
      '10x': 'var(--spacer-h-10x)',
      '12x': 'var(--spacer-h-12x)',
      '16x': 'var(--spacer-h-16x)'
    }[size] || 'var(--spacer-h-1x)';

    return (
      <div
        ref={ref}
        role={decorative ? 'none' : 'separator'}
        aria-orientation={decorative ? undefined : type}
        className={cn(
          'bg-neutral-border',
          type === 'horizontal' ? 'w-full my-4' : 'h-full mx-4',
          className
        )}
        style={{
          height: type === 'horizontal' ? spacing : '100%',
          width: type === 'vertical' ? spacing : '100%',
          backgroundColor: 'var(--neutral-border)',
          display: 'block'
        }}
      />
    );
  }
);

Divider.displayName = 'Divider';
