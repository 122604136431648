import React, { useState, useEffect, useRef, useCallback } from 'react';
import { motion, AnimatePresence, useMotionValue, animate } from 'framer-motion';
import styled from 'styled-components';
import logoSvg from '../assets/logos/productsense-logo.svg';
import { 
  Video, MessageSquare, Figma, GitPullRequest, 
  TicketIcon, HeadphonesIcon, PhoneCall, BarChart4,
  LineChart, PieChart, Table2, FileText, 
  Presentation, Sheet, Mail, Facebook, Linkedin,
  Bell, MessageCircle 
} from 'lucide-react';
import { JoinWaitlistModal } from '../components/JoinWaitlistModal';

// Import character SVGs
import businessTaskList from '../assets/characters/product_conversations/business-task-list.svg';
import confusionEmployees from '../assets/characters/product_conversations/confusion-employees.svg';
import decisionMaking from '../assets/characters/product_conversations/decision-making.svg';
import ideaPresentation from '../assets/characters/product_conversations/idea-presentation.svg';
import knowledgeSharing from '../assets/characters/product_conversations/knowledge-sharing.svg';
import planningPresentation from '../assets/characters/product_conversations/planning-presentation.svg';
import projectLaunch from '../assets/characters/product_conversations/project-launch.svg';
import projectPlanning from '../assets/characters/product_conversations/project-planning.svg';
import teamDiscussion from '../assets/characters/product_conversations/team-discussion.svg';
import videoConference from '../assets/characters/product_conversations/video-conference-with-colleague.svg';
import workersWebcam from '../assets/characters/product_conversations/workers-webcam-group-conference-with-coworkers-on-pc-screens.svg';
import businessPlanning from '../assets/characters/product_conversations/business-planning.svg';

// Import customer SVGs
import customerSupport from '../assets/characters/customer_conversations/customer-support-representative-in-digital-environment.svg';
import talkingOnCall from '../assets/characters/customer_conversations/man-and-woman-talking-on-call.svg';
import submitReview from '../assets/characters/customer_conversations/submit-customer-review.svg';

// Import business SVGs
import businessAgreement from '../assets/characters/business_conversations/business-agreement.svg';
import businessAnalytics from '../assets/characters/business_conversations/business-analytics-graph.svg';
import businessFeedback from '../assets/characters/business_conversations/business-feedback-analysis.svg';
import businessIdea from '../assets/characters/business_conversations/business-idea-analysis.svg';
import businessLoss from '../assets/characters/business_conversations/business-loss.svg';
import businessPresentation from '../assets/characters/business_conversations/business-presentation.svg';
import businessProject from '../assets/characters/business_conversations/business-project-planning.svg';
import financeManagement from '../assets/characters/business_conversations/finance-management.svg';
import financialGoal from '../assets/characters/business_conversations/financial-goal.svg';
import projectManagement from '../assets/characters/business_conversations/project-management.svg';

// Import marketing SVGs
import businessMarketing from '../assets/characters/marketing_conversations/business-marketing.svg';

// Import solo SVGs
import accounting from '../assets/characters/solo_work/accounting.svg';
import businessmanLaptop from '../assets/characters/solo_work/businessman-using-laptop.svg';

// Import market SVGs
import economicAnalysis from '../assets/characters/market_conversations/economic-analysis.svg';

const SVG_MAP = {
  product: [
    businessTaskList,
    confusionEmployees,
    decisionMaking,
    ideaPresentation,
    knowledgeSharing,
    planningPresentation,
    projectLaunch,
    projectPlanning,
    teamDiscussion,
    videoConference,
    workersWebcam,
    businessPlanning
  ],
  customer: [
    customerSupport,
    talkingOnCall,
    submitReview
  ],
  business: [
    businessAgreement,
    businessAnalytics,
    businessFeedback,
    businessIdea,
    businessLoss,
    businessPresentation,
    businessProject,
    financeManagement,
    financialGoal,
    projectManagement
  ],
  marketing: [
    businessMarketing
  ],
  solo: [
    accounting,
    businessmanLaptop
  ],
  market: [
    economicAnalysis
  ]
} as const;

interface Document {
  id: number;
  x: number;
  y: number;
  velocity: { x: number; y: number };
  icon: {
    type: string;
    component: any;
    color: string;
    name: string;
  };
  opacity?: number;
  isDragging?: boolean;
}

type FolderType = 'product' | 'customer' | 'business' | 'marketing' | 'solo' | 'market';

const FOLDER_ICONS: Record<Exclude<FolderType, 'market'>, Array<{
  icon: React.ForwardRefExoticComponent<any>;
  color: string;
  name: string;
}>> = {
  product: [
    { icon: Video, color: '#2D8CFF', name: 'Zoom' },
    { icon: MessageSquare, color: '#4A154B', name: 'Slack' },
    { icon: Figma, color: '#0ACF83', name: 'Figma' },
    { icon: GitPullRequest, color: '#5E6AD2', name: 'Linear' },
    { icon: TicketIcon, color: '#0052CC', name: 'Jira' }
  ],
  customer: [
    { icon: HeadphonesIcon, color: '#03363D', name: 'Zendesk' },
    { icon: MessageSquare, color: '#1292EE', name: 'Helpscout' },
    { icon: PhoneCall, color: '#E42427', name: 'Zoho' },
    { icon: Video, color: '#FA8F38', name: 'Gong' },
    { icon: HeadphonesIcon, color: '#3B7DE0', name: 'Freshdesk' }
  ],
  business: [
    { icon: BarChart4, color: '#29B5E8', name: 'Snowflake' },
    { icon: LineChart, color: '#4B34B5', name: 'Mixpanel' },
    { icon: PieChart, color: '#1E5EFF', name: 'Amplitude' },
    { icon: Table2, color: '#E97627', name: 'Tableau' }
  ],
  solo: [
    { icon: FileText, color: '#000000', name: 'Notion' },
    { icon: FileText, color: '#4285F4', name: 'Google Docs' },
    { icon: Sheet, color: '#0F9D58', name: 'Google Sheets' },
    { icon: Presentation, color: '#F4B400', name: 'Google Slides' },
    { icon: FileText, color: '#2B579A', name: 'Word' },
    { icon: Sheet, color: '#217346', name: 'Excel' },
    { icon: Presentation, color: '#B7472A', name: 'PowerPoint' }
  ],
  marketing: [
    { icon: Facebook, color: '#1877F2', name: 'Facebook' },
    { icon: Linkedin, color: '#0A66C2', name: 'LinkedIn' },
    { icon: BarChart4, color: '#4285F4', name: 'Google' },
    { icon: MessageCircle, color: '#000000', name: 'TikTok' },
    { icon: MessageSquare, color: '#25D366', name: 'SMS' },
    { icon: Mail, color: '#EA4335', name: 'Email' },
    { icon: Bell, color: '#4A90E2', name: 'Push' }
  ]
} as const;

const PageContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: white;
  overflow: hidden;

  @keyframes shimmer {
    0% {
      filter: hue-rotate(0deg) brightness(1.1) saturate(1.2);
    }
    100% {
      filter: hue-rotate(360deg) brightness(1.1) saturate(1.2);
    }
  }
`;

const LogoContainer = styled(motion.div)`
  position: fixed;
  left: 50%;
  top: 50%;
  width: min(50vw, 400px);
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  -webkit-user-drag: none;
  pointer-events: auto;
  animation: shimmer 10s linear infinite;

  &:hover {
    .waitlist-button {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    user-select: none;
    -webkit-user-drag: none;
    pointer-events: none;
    cursor: default;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
`;

const CharacterContainer = styled(motion.div)`
  position: fixed;
  top: 20px;
  left: 20px;
  display: flex;
  gap: 10px;
  z-index: 5;
`;

const Character = styled(motion.div)<{ $x: number; $y: number }>`
  position: absolute;
  width: 150px;
  height: 150px;
  left: ${props => props.$x}px;
  top: ${props => props.$y}px;
  pointer-events: none;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const IconBubble = styled(motion.div)`
  position: absolute;
  background: white;
  border: 2px solid #333;
  border-radius: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconWrapper = styled(motion.div)`
  width: 24px;
  height: 24px;
  color: #333;
`;

const DocumentIcon = styled(motion.div)<{ iconType: string }>`
  width: clamp(30px, 4vw, 40px);
  height: clamp(37.5px, 5vw, 50px);
  background: ${props => props.iconType === 'default' ? '#e0e0e0' : 'white'};
  position: fixed;
  border-radius: 5px;
  cursor: grab;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.iconType === 'default' ? '#999' : props.color};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  
  &:active {
    cursor: grabbing;
  }
`;

const DocumentsContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 100;
`;

const DraggableDocument = React.memo(({ 
  doc, 
  onDragStart, 
  onDragEnd 
}: { 
  doc: Document, 
  onDragStart: () => void,
  onDragEnd: (e: any, info: any) => void
}) => {
  return (
    <motion.div
      key={doc.id}
      data-doc-id={doc.id}
      drag
      dragMomentum={true}
      dragTransition={{
        bounceStiffness: 300,
        bounceDamping: 20,
        power: 0.2,
      }}
      dragElastic={0}
      style={{
        position: 'absolute',
        opacity: doc.opacity,
        cursor: 'grab',
        zIndex: doc.isDragging ? 1000 : 100,
        pointerEvents: 'auto',
        x: doc.x,
        y: doc.y,
      }}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
    >
      <DocumentIcon 
        iconType={doc.icon.type}
        color={doc.icon.color}
      >
        <doc.icon.component size={24} color={doc.icon.color} />
      </DocumentIcon>
    </motion.div>
  );
});

const WaitlistButton = styled(motion.button)`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.9);
  opacity: 0;
  background: #4a90e2;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  font-weight: 600;
  cursor: pointer;
  z-index: 1000;
  white-space: nowrap;
  pointer-events: auto;
  transition: opacity 0.15s ease-out, transform 0.15s ease-out;
  color: white;
  animation: shimmer 10s linear infinite;

  &:hover {
    filter: brightness(1.1);
  }
`;

const Home: React.FC = () => {
  const [characters, setCharacters] = useState<Array<{
    id: number;
    svg: string;
    x: number;
    y: number;
    folder: keyof typeof SVG_MAP;
    icons: typeof FOLDER_ICONS[keyof typeof FOLDER_ICONS];
  }>>([]);
  const [documents, setDocuments] = useState<Array<Document>>([]);
  const [isWaitlistModalOpen, setIsWaitlistModalOpen] = useState(false);
  const [isOnWaitlist, setIsOnWaitlist] = useState(() => {
    return sessionStorage.getItem('waitlist_email') !== null;
  });
  const [showWaitlistButton, setShowWaitlistButton] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const animationFrameRef = useRef<number>();
  const logoScale = useMotionValue(1);
  const dragDocRef = useRef<number | null>(null);
  const nextIdRef = useRef(1);
  const dragStartPosRef = useRef<{ x: number, y: number }>({ x: 0, y: 0 });

  const getRandomSvg = useCallback((folder: keyof typeof SVG_MAP) => {
    const svgs = SVG_MAP[folder];
    return svgs[Math.floor(Math.random() * svgs.length)];
  }, []);

  const getRandomEdgePosition = useCallback(() => {
    const edge = Math.floor(Math.random() * 4);
    const width = window.innerWidth;
    const height = window.innerHeight;
    const CHARACTER_SIZE = 150;
    const VISIBLE_PORTION = 100;
    
    const position = { x: 0, y: 0 };
    
    switch (edge) {
      case 0: // top
        position.x = Math.random() * (width - CHARACTER_SIZE);
        position.y = -CHARACTER_SIZE + VISIBLE_PORTION;
        break;
      case 1: // right
        position.x = width - VISIBLE_PORTION;
        position.y = Math.random() * (height - CHARACTER_SIZE);
        break;
      case 2: // bottom
        position.x = Math.random() * (width - CHARACTER_SIZE);
        position.y = height - VISIBLE_PORTION;
        break;
      case 3: // left
        position.x = -CHARACTER_SIZE + VISIBLE_PORTION;
        position.y = Math.random() * (height - CHARACTER_SIZE);
        break;
    }
    
    return position;
  }, []);

  const getRandomIcons = useCallback((folder: FolderType, count: number) => {
    const iconFolder = folder === 'market' ? 'marketing' : folder === 'solo' ? 'solo' : folder as Exclude<FolderType, 'market'>;
    const icons = FOLDER_ICONS[iconFolder];
    if (!icons) return [];
    
    count = Math.min(count, icons.length);
    const shuffled = [...icons].sort(() => Math.random() - 0.5);
    return shuffled.slice(0, count);
  }, []);

  const getNextId = useCallback(() => {
    const id = nextIdRef.current;
    nextIdRef.current += 1;
    return id;
  }, []);

  const addFloatingIcon = useCallback((icon: typeof FOLDER_ICONS[keyof typeof FOLDER_ICONS][0], startX: number, startY: number) => {
    const angle = Math.random() * Math.PI * 2;
    const speed = 2 + Math.random() * 2;
    
    setDocuments(prev => [...prev, {
      id: getNextId(),
      x: startX,
      y: startY,
      velocity: {
        x: Math.cos(angle) * speed,
        y: Math.sin(angle) * speed
      },
      icon: {
        type: icon.name,
        component: icon.icon,
        color: icon.color,
        name: icon.name
      }
    }]);
  }, [getNextId, setDocuments]);

  const MIN_VELOCITY = 0.5;
  const MAX_VELOCITY = 500;
  const LOGO_COLLISION_SCALE = 0.4; // Adjust this value to match the gradient size

  // Helper function to check logo collision
  const checkLogoCollision = useCallback((docX: number, docY: number, docWidth: number, docHeight: number, logoRect: DOMRect, isDragEnd = false) => {
    if (isDragEnd) {
      // Rectangular collision with small padding for drag end
      const padding = 1;
      return (
        docX < logoRect.right + padding &&
        docX + docWidth > logoRect.left - padding &&
        docY < logoRect.bottom + padding &&
        docY + docHeight > logoRect.top - padding
      );
    }
    
    // Original distance-based collision for floating documents
    const logoCenterX = logoRect.left + logoRect.width / 2;
    const logoCenterY = logoRect.top + logoRect.height / 2;
    
    const docCenterX = docX + docWidth / 2;
    const docCenterY = docY + docHeight / 2;
    
    const dx = docCenterX - logoCenterX;
    const dy = docCenterY - logoCenterY;
    const distance = Math.sqrt(dx * dx + dy * dy);
    
    return distance < logoRect.width * LOGO_COLLISION_SCALE;
  }, []);

  // Initialize floating documents animation
  useEffect(() => {
    const updatePositions = () => {
      const logoElement = document.querySelector('#logo-container');
      if (!logoElement) return;
      
      const logoRect = logoElement.getBoundingClientRect();
      
      setDocuments(prevDocs => 
        prevDocs.map(doc => {
          // Skip if document is being dragged or is fading out
          if (doc.isDragging || doc.opacity === 0) return doc;

          // Apply velocity
          let newX = doc.x + (doc.velocity?.x || 0);
          let newY = doc.y + (doc.velocity?.y || 0);
          
          // Get document dimensions
          const docElement = document.querySelector(`[data-doc-id="${doc.id}"]`);
          const docRect = docElement?.getBoundingClientRect();
          const docWidth = docRect?.width || 40; // Default width if not found
          const docHeight = docRect?.height || 50; // Default height if not found
          
          // Check for collision with logo using document dimensions
          if (checkLogoCollision(newX, newY, docWidth, docHeight, logoRect)) {
            // Trigger logo pulse animation
            animate(logoScale, 1.1, {
              duration: 0.2,
              ease: "easeOut",
              type: "spring",
              stiffness: 300,
              damping: 20,
            }).then(() => {
              animate(logoScale, 1, {
                duration: 0.2,
                ease: "easeIn"
              });
            });

            // Start fade out
            return {
              ...doc,
              opacity: 0,
              velocity: { x: 0, y: 0 }
            };
          }
          
          // Apply drag to slow down movement
          const drag = 0.95;
          
          // Bounce off window edges
          const width = window.innerWidth;
          const height = window.innerHeight;
          let newVelX = doc.velocity?.x || 0;
          let newVelY = doc.velocity?.y || 0;
          
          if (newX < 0 || newX > width - 40) {
            newVelX = -doc.velocity.x * 0.8;
          }
          if (newY < 0 || newY > height - 50) {
            newVelY = -doc.velocity.y * 0.8;
          }

          newVelX *= drag;
          newVelY *= drag;

          if (Math.abs(newVelX) < MIN_VELOCITY) {
            newVelX = newVelX < 0 ? -MIN_VELOCITY : MIN_VELOCITY;
          }
          if (Math.abs(newVelY) < MIN_VELOCITY) {
            newVelY = newVelY < 0 ? -MIN_VELOCITY : MIN_VELOCITY;
          }

          return {
            ...doc,
            x: Math.max(0, Math.min(width - 40, newX)),
            y: Math.max(0, Math.min(height - 50, newY)),
            velocity: {
              x: newVelX,
              y: newVelY
            }
          };
        })
      );
      
      // Remove any fully faded documents
      setDocuments(prev => prev.filter(doc => doc.opacity !== 0));
      
      animationFrameRef.current = requestAnimationFrame(updatePositions);
    };

    animationFrameRef.current = requestAnimationFrame(updatePositions);
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, []);

  // Character spawn effect
  useEffect(() => {
    const spawnCharacter = async () => {
      const folders = Object.keys(SVG_MAP) as Array<keyof typeof SVG_MAP>;
      const folder = folders[Math.floor(Math.random() * folders.length)];
      const svg = getRandomSvg(folder);
      const position = getRandomEdgePosition();
      const iconCount = Math.floor(Math.random() * 2) + 1; // 1-2 icons
      const icons = getRandomIcons(folder, iconCount);
      const characterId = getNextId();

      console.log('Spawning character:', { folder, position, svg });

      // Calculate the actual position where the character will be visible
      const visibleX = position.x + (position.x < 0 ? 100 : position.x > window.innerWidth ? -100 : 0);
      const visibleY = position.y + (position.y < 0 ? 100 : position.y > window.innerHeight ? -100 : 0);

      console.log('Adjusted position:', { visibleX, visibleY });

      setCharacters(prev => [...prev, {
        id: characterId,
        svg,
        x: visibleX,
        y: visibleY,
        folder,
        icons
      }]);

      // Add floating icons after character appears
      setTimeout(() => {
        icons.forEach((icon, index) => {
          setTimeout(() => {
            addFloatingIcon(icon, visibleX, visibleY);
          }, index * 300); // Slower stagger for icons
        });
      }, 800); // Start spawning docs sooner

      // Remove character after animation
      setTimeout(() => {
        setCharacters(prev => prev.filter(char => char.id !== characterId));
      }, 8000); // Increased duration to be more visible
    };

    // Initial spawn
    spawnCharacter();

    // Set up interval for spawning
    const interval = setInterval(() => {
      spawnCharacter();
    }, 7000); // More frequent spawning

    return () => clearInterval(interval);
  }, [getRandomSvg, getRandomEdgePosition, getRandomIcons, getNextId, addFloatingIcon]);

  const handleDragEnd = (e: any, info: any, docId: number) => {
    const logoElement = document.querySelector('#logo-container');
    if (!logoElement) return;

    const logoRect = logoElement.getBoundingClientRect();
    const docX = info.point.x;
    const docY = info.point.y;
    
    // Get document dimensions
    const docElement = document.querySelector(`[data-doc-id="${docId}"]`);
    const docRect = docElement?.getBoundingClientRect();
    const docWidth = docRect?.width || 40;
    const docHeight = docRect?.height || 50;

    // Predict where document is heading based on velocity (increased multiplier)
    const predictedX = docX + (info.velocity.x * 8);
    const predictedY = docY + (info.velocity.y * 8);

    // Check both current position and predicted position for collision
    if (checkLogoCollision(docX, docY, docWidth, docHeight, logoRect, true) ||
        checkLogoCollision(predictedX, predictedY, docWidth, docHeight, logoRect, true)) {
      // Immediately update document position and trigger fade
      setDocuments(docs => docs.map(doc => 
        doc.id === docId 
          ? { 
              ...doc, 
              isDragging: false,
              x: docX, 
              y: docY,
              opacity: 0,
              velocity: { x: 0, y: 0 }
            }
          : doc
      ));

      // Trigger logo pulse
      logoScale.set(1.04);
      animate(logoScale, 1, {
        type: "spring",
        stiffness: 35,
        damping: 15,
        mass: 2,
        restDelta: 0.001
      });
      
      // Remove document after animation
      setTimeout(() => {
        setDocuments(docs => docs.filter(doc => doc.id !== docId));
      }, 400);
    } else {
      setDocuments(docs => docs.map(doc => {
        if (doc.id === docId) {
          return {
            ...doc,
            isDragging: false,
            x: docX,
            y: docY
          };
        }
        return doc;
      }));
    }
  };

  return (
    <PageContainer ref={containerRef}>
      <LogoContainer
        id="logo-container"
        style={{ 
          scale: logoScale,
          x: "-50%",
          y: "-50%"
        }}
      >
        <motion.img 
          src={logoSvg} 
          alt="ProductSense Logo" 
          draggable="false"
          onDragStart={(e) => e.preventDefault()}
        />
        <WaitlistButton
          className="waitlist-button"
          onClick={(e) => {
            e.stopPropagation();
            if (!isOnWaitlist) {
              setIsWaitlistModalOpen(true);
            }
          }}
          disabled={isOnWaitlist}
        >
          {isOnWaitlist ? "You're on the list" : "Join Waitlist"}
        </WaitlistButton>
      </LogoContainer>

      <JoinWaitlistModal 
        isOpen={isWaitlistModalOpen}
        onClose={() => {
          setIsWaitlistModalOpen(false);
          setIsOnWaitlist(sessionStorage.getItem('waitlist_email') !== null);
        }}
      />

      <AnimatePresence>
        {characters.map(character => (
          <Character
            key={character.id}
            $x={character.x}
            $y={character.y}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5 }}
            transition={{ duration: 0.5 }}
          >
            <img src={character.svg} alt="Character" />
          </Character>
        ))}
      </AnimatePresence>

      <DocumentsContainer>
        <AnimatePresence mode="popLayout">
          {documents.map((doc) => (
            <DraggableDocument
              key={doc.id}
              doc={doc}
              onDragStart={() => {
                dragDocRef.current = doc.id;
                setDocuments(docs => docs.map(d => 
                  d.id === doc.id ? { ...d, isDragging: true } : d
                ));
              }}
              onDragEnd={(e, info) => {
                const docId = dragDocRef.current;
                dragDocRef.current = null;
                if (docId !== null) {
                  handleDragEnd(e, info, docId);
                }
              }}
            />
          ))}
        </AnimatePresence>
      </DocumentsContainer>
    </PageContainer>
  );
};

export default Home;
