import { SignedIn, SignedOut, RedirectToSignIn, OrganizationList, useOrganization, SignIn } from '@clerk/clerk-react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Layout from './components/Layout';
import Dashboard from './pages/Dashboard';
import Settings from './pages/Settings';
import Support from './pages/Support';
import Legal from './pages/Legal';
import DesignSystem from './pages/DesignSystem';
import Home from './pages/Home';
import { ThemeProvider } from './components/ThemeProvider';
import { RequireOrganization } from './components/RequireOrganization';
import { useClerkTheme } from './hooks/useClerkTheme';
import { useUser } from '@clerk/clerk-react';
import { useEffect } from 'react';

export default function App() {
  const navigate = useNavigate();
  const { isSignedIn, isLoaded: userLoaded } = useUser();
  const { organization, isLoaded: orgLoaded } = useOrganization();
  const clerkTheme = useClerkTheme();

  useEffect(() => {
    if (userLoaded && !isSignedIn && window.location.pathname !== '/' && !window.location.pathname.includes('/sign-in')) {
      navigate('/sign-in');
    }
  }, [isSignedIn, userLoaded, navigate]);

  if (!userLoaded || !orgLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider>
      <div className={clerkTheme.className}>
        <Toaster />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sign-in/*" element={<SignIn routing="path" path="/sign-in" redirectUrl="/app/dashboard" />} />
          <Route
            path="/app/*"
            element={
              <>
                <SignedIn>
                  <Routes>
                    <Route element={<Layout />}>
                      {!organization ? (
                        <Route
                          path="*"
                          element={
                            <div className="min-h-[60vh] flex flex-col items-center justify-center p-4">
                              <h2 className="text-2xl font-semibold mb-4">Select an Organization</h2>
                              <p className="text-muted-foreground text-center mb-8">
                                Please select or create an organization to continue.
                              </p>
                              <OrganizationList 
                                hidePersonal
                                afterSelectOrganizationUrl="/app/dashboard"
                                afterCreateOrganizationUrl="/app/dashboard"
                              />
                            </div>
                          }
                        />
                      ) : (
                        <>
                          <Route path="/" element={<RequireOrganization><Dashboard /></RequireOrganization>} />
                          <Route path="/dashboard" element={<RequireOrganization><Dashboard /></RequireOrganization>} />
                          <Route path="/settings" element={<RequireOrganization><Settings /></RequireOrganization>} />
                          <Route path="/design-system" element={<RequireOrganization><DesignSystem /></RequireOrganization>} />
                          <Route path="/support" element={<Support />} />
                          <Route path="/legal/*" element={<Legal />} />
                          <Route path="*" element={<Navigate to="/" replace />} />
                        </>
                      )}
                    </Route>
                  </Routes>
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn />
                </SignedOut>
              </>
            }
          />
        </Routes>
      </div>
    </ThemeProvider>
  );
}