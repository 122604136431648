import React, { useState } from 'react';
import { VeryPositiveSentiment, PositiveSentiment, NeutralSentiment, NegativeSentiment, VeryNegativeSentiment } from './ui/icons/Sentiment';

export interface FeedbackTableItem {
  id?: string;
  _id?: string;
  content: string;
  persona?: string;
  theme?: string;
  sentiment: number;
  source?: string;
  source_type: string;
  source_id: string;
  timestamp?: string;
  category?: string;
  metadata: {
    UserID?: string;
    UserName?: string;
    UserTitle?: string;
    SlackUserID?: string;
    SlackMessageLink?: string;
  };
}

interface FeedbackTableProps {
  data: FeedbackTableItem[];
}

// Helper function to parse and render Slack-style markdown links and newlines
const _renderContent = (content: string) => {
  // First split by newlines to handle them separately
  const lines = content.split(/\n/);
  
  return lines.map((line, lineIndex) => {
    // Match Slack-style links: <url|text> or just <url>
    const parts = line.split(/(<(?:(?:https?:\/\/)[^|>]+?\|[^>]+?>|(?:https?:\/\/)[^>]+?>))/g);
    
    const renderedLine = parts.map((part, partIndex) => {
      // Check if this part is a link
      if (part.startsWith('<') && part.endsWith('>')) {
        // Remove < and >
        const linkContent = part.slice(1, -1);
        // Split into URL and text if separator exists
        const [url, text] = linkContent.split('|');
        
        return (
          <a
            key={`${lineIndex}-${partIndex}`}
            href={url.startsWith('http') ? url : `https://${url}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary-plain hover:text-primary-hovered hover:underline"
            onClick={(e) => e.stopPropagation()}
          >
            {text || url}
          </a>
        );
      }
      
      // Return regular text
      return part;
    });

    // Return the line with a break if it's not the last line
    return (
      <React.Fragment key={lineIndex}>
        {renderedLine}
        {lineIndex < lines.length - 1 && <br />}
      </React.Fragment>
    );
  });
};

// Helper function to truncate text while preserving words
const _truncateText = (text: string, maxLength: number) => {
  if (text.length <= maxLength) return text;
  
  // Find the last space within the limit
  const truncated = text.slice(0, maxLength);
  const lastSpace = truncated.lastIndexOf(' ');
  
  // If we found a space, truncate at the space, otherwise use the full length
  return (lastSpace > 0 ? truncated.slice(0, lastSpace) : truncated) + '...';
};

// Helper function to get source icon
function getSourceIcon(sourceType: string): string | undefined {
  if (!sourceType) return undefined;
  
  switch (sourceType.toLowerCase()) {
    case 'slack':
      return '/icons/slack.svg';
    case 'zendesk':
      return '/icons/zendesk.svg';
    case 'call':
      return '/icons/phone.svg';
    default:
      return undefined;
  }
};

// Helper function to capitalize first letter
const _capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

// Helper function to convert text to title case
const _toTitleCase = (str?: string) => {
  if (!str) return 'Unknown';
  return str.split(/[\s-_]+/).map(word => 
    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  ).join(' ');
};

// Helper function to get sentiment icon
const getSentimentIcon = (sentiment: number) => {
  if (sentiment >= 0.6) return { icon: VeryPositiveSentiment, color: 'var(--success-content)' };
  if (sentiment >= 0.2) return { icon: PositiveSentiment, color: 'var(--success-content)' };
  if (sentiment >= -0.2) return { icon: NeutralSentiment, color: 'var(--neutral-content)' };
  if (sentiment >= -0.6) return { icon: NegativeSentiment, color: 'var(--error-content)' };
  return { icon: VeryNegativeSentiment, color: 'var(--error-content)' };
};

export const FeedbackTable: React.FC<FeedbackTableProps> = ({ data }) => {
  const [sortField, setSortField] = useState<keyof FeedbackTableItem>('timestamp');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

  const handleSort = (field: keyof FeedbackTableItem) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('desc');
    }
  };

  const sortedData = [...data].sort((a, b) => {
    const aValue = a[sortField];
    const bValue = b[sortField];

    if (sortField === 'timestamp') {
      return sortDirection === 'asc'
        ? new Date(aValue as string).getTime() - new Date(bValue as string).getTime()
        : new Date(bValue as string).getTime() - new Date(aValue as string).getTime();
    }

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return sortDirection === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    }

    return 0;
  });

  return (
    <div className="w-full">
      <table className="w-full">
        <thead style={{ backgroundColor: 'var(--neutral-surface)' }}>
          <tr style={{ borderBottom: '1px solid var(--neutral-border)' }}>
            <th style={{ 
              textAlign: 'left',
              padding: 'var(--spacing-4)',
              fontSize: 'var(--font-size-small)',
              fontWeight: 'var(--font-weight-medium)',
              color: 'var(--neutral-content-subtle)',
              width: '40%'
            }}>Feedback</th>
            <th style={{ 
              textAlign: 'left',
              padding: 'var(--spacing-4)',
              fontSize: 'var(--font-size-small)',
              fontWeight: 'var(--font-weight-medium)',
              color: 'var(--neutral-content-subtle)',
              width: '20%'
            }}>Persona</th>
            <th style={{ 
              textAlign: 'left',
              padding: 'var(--spacing-4)',
              fontSize: 'var(--font-size-small)',
              fontWeight: 'var(--font-weight-medium)',
              color: 'var(--neutral-content-subtle)',
              width: '20%'
            }}>Theme</th>
            <th style={{ 
              textAlign: 'left',
              padding: 'var(--spacing-4)',
              fontSize: 'var(--font-size-small)',
              fontWeight: 'var(--font-weight-medium)',
              color: 'var(--neutral-content-subtle)',
              width: '20%'
            }}>Source</th>
          </tr>
        </thead>
        <tbody style={{ backgroundColor: 'var(--neutral-background)' }}>
          {sortedData.map((item) => (
            <tr key={item.id || item._id || item.source_id} style={{ borderBottom: '1px solid var(--neutral-border)' }}>
              <td style={{ 
                padding: 'var(--spacing-4)',
                fontSize: 'var(--font-size-small)',
                color: 'var(--neutral-content)',
                width: '40%'
              }}>
                <div className="flex items-center" style={{ marginLeft: '-8px' }}>
                  <span style={{ 
                    color: getSentimentIcon(item.sentiment).color,
                    marginRight: '8px',
                    width: '24px',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    {React.createElement(getSentimentIcon(item.sentiment).icon, {
                      size: 20,
                      className: 'stroke-current'
                    })}
                  </span>
                  <span style={{ color: 'var(--neutral-content-subtle)' }}>{item.content}</span>
                </div>
              </td>
              <td style={{ 
                padding: 'var(--spacing-4)',
                fontSize: 'var(--font-size-small)',
                color: 'var(--neutral-content)',
                width: '20%'
              }}>
                <span style={{
                  padding: 'var(--spacing-1) var(--spacing-2)',
                  borderRadius: 'var(--radius-full)',
                  backgroundColor: 'var(--neutral-surface)',
                  color: 'var(--neutral-content)'
                }}>
                  {item.persona ? _toTitleCase(item.persona) : 'Unknown'}
                </span>
              </td>
              <td style={{ 
                padding: 'var(--spacing-4)',
                fontSize: 'var(--font-size-small)',
                width: '20%'
              }}>
                <span style={{
                  padding: 'var(--spacing-1) var(--spacing-2)',
                  borderRadius: 'var(--radius-full)',
                  backgroundColor: !item.category || item.category === 'uncategorized' 
                    ? 'var(--neutral-surface)' 
                    : `var(--${item.category.toLowerCase()}-surface)`,
                  color: !item.category || item.category === 'uncategorized'
                    ? 'var(--neutral-content)'
                    : `var(--${item.category.toLowerCase()}-content)`
                }}>
                  {item.category ? _toTitleCase(item.category) : 'Uncategorized'}
                </span>
              </td>
              <td style={{ 
                padding: 'var(--spacing-4)',
                fontSize: 'var(--font-size-small)',
                color: 'var(--neutral-content)',
                width: '20%'
              }}>
                <div className="flex items-center space-x-2">
                  {getSourceIcon(item.source_type) && (
                    <img 
                      src={getSourceIcon(item.source_type)} 
                      alt={item.source_type}
                      style={{ 
                        width: 'var(--spacing-4)',
                        height: 'var(--spacing-4)'
                      }}
                    />
                  )}
                  <span>{_capitalizeFirstLetter(item.source_type)}</span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};