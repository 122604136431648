import React from 'react';
import { useTheme } from '../ThemeProvider';

interface LogoProps {
  className?: string;
  onClick?: () => void;
}

export const Logo: React.FC<LogoProps> = ({ className = 'h-8 w-auto', onClick }) => {
  const { theme } = useTheme();
  
  return (
    <div 
      className={className}
      onClick={onClick}
      style={{
        position: 'relative',
        aspectRatio: '1/1',
      }}
    >
      <svg 
        viewBox="0 0 138 138" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: '100%',
          height: '100%',
        }}
        className="bg-neutral-background"
      >
        <rect width="137.921" height="137.921" fill={theme === 'light' ? '#FFFFFF' : 'transparent'}/>
        <path 
          d="M55.1454 78.8046C77.7731 79.8821 96.0908 97.1222 111.176 113.285L110.098 103.587L107.943 93.8897C125.183 82.0371 126.261 74.4946 119.796 64.797M55.1454 78.8046C41.1378 87.4247 12.0451 89.5797 14.2001 61.5645M55.1454 78.8046C60.5329 75.5721 72.0565 68.3829 75.5046 66.6589M14.2001 61.5645C14.8325 53.3433 23.5789 42.7347 36.8278 34.9988M14.2001 61.5645C19.5877 69.107 28.2078 74.4946 63.7655 52.9444M99.3233 29.2393C111.535 33.1901 132.726 47.5569 119.796 64.797M99.3233 29.2393C76.1607 20.3306 53.0989 25.4983 36.8278 34.9988M99.3233 29.2393C83.1606 30.3168 72.3856 46.4794 63.7655 52.9444M119.796 64.797C108.907 56.9613 85.0511 60.139 79.8146 64.5039M75.5046 66.6589C72.9006 64.0549 69.8201 60.269 66.2143 55.8838M75.5046 66.6589C90.2789 78.7121 108.301 73.759 116.482 67.1684M36.8278 34.9988C46.4672 33.2438 55.9977 42.7502 63.7655 52.9444" 
          stroke="url(#paint0_linear_19_23)" 
          strokeWidth="2" 
          strokeLinejoin="round"
        />
        <defs>
          <linearGradient 
            id="paint0_linear_19_23" 
            x1="14.0866" 
            y1="69.5216" 
            x2="123.834" 
            y2="69.5216" 
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F17277"/>
            <stop offset="0.12" stopColor="#F079C6"/>
            <stop offset="0.25" stopColor="#B39BFF"/>
            <stop offset="0.38" stopColor="#00BEFF"/>
            <stop offset="0.53" stopColor="#00D4F3"/>
            <stop offset="0.68" stopColor="#00DEA6"/>
            <stop offset="0.82" stopColor="#97BD5D"/>
            <stop offset="1" stopColor="#FBC94B"/>
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};
