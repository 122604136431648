import React, { useEffect, useState, useRef } from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

interface MetricHistoryData {
  date: string;
  [key: string]: string | number | undefined;  // Allow any string key for dynamic categories
}

interface CategoryColor {
  stroke: string;
  gradientId: string;
}

interface TrendChartProps {
  data: MetricHistoryData[];
  categoryColors?: Record<string, string>; // Map of category to color
}

// Fallback colors if none provided
const DEFAULT_COLORS = {
  Security: { stroke: '#4F46E5', gradientId: 'colorSecurity' },
  Reliability: { stroke: '#EF4444', gradientId: 'colorReliability' },
  Performance: { stroke: '#10B981', gradientId: 'colorPerformance' },
  Usability: { stroke: '#F59E0B', gradientId: 'colorUsability' },
  Value: { stroke: '#8B5CF6', gradientId: 'colorValue' },
} as const;

export const TrendChart: React.FC<TrendChartProps> = ({ data, categoryColors = {} }) => {
  const [mounted, setMounted] = useState(false);
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  useEffect(() => {
    setMounted(true);

    // Create ResizeObserver to monitor container size changes
    resizeObserverRef.current = new ResizeObserver(entries => {
      for (const entry of entries) {
        const { width } = entry.contentRect;
        setContainerWidth(width);
      }
    });

    // Start observing the container
    if (containerRef.current) {
      resizeObserverRef.current.observe(containerRef.current);
    }

    return () => {
      setMounted(false);
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, []);

  // Force a re-render when data changes
  useEffect(() => {
    if (mounted && containerRef.current) {
      window.dispatchEvent(new Event('resize'));
    }
  }, [data, mounted]);

  // Get unique categories from the data
  const categories = React.useMemo(() => {
    if (!data || data.length === 0) return [];
    const allKeys = data.reduce((keys: string[], point) => {
      Object.keys(point).forEach(key => {
        if (key !== 'date' && !keys.includes(key)) {
          keys.push(key);
        }
      });
      return keys;
    }, []);
    return allKeys;
  }, [data]);

  // Create a mapping of category to color based on provided colors or defaults
  const getCategoryColor = (category: string): CategoryColor => {
    // If a custom color is provided, use it
    if (categoryColors[category]) {
      return {
        stroke: categoryColors[category],
        gradientId: `color${category.replace(/\s+/g, '')}`
      };
    }

    // Try to find a base category that matches (ignoring numbers/spaces)
    const baseCategory = Object.keys(DEFAULT_COLORS).find(base => 
      category.toLowerCase().replace(/[0-9\s]/g, '') === base.toLowerCase()
    );
    
    return baseCategory 
      ? DEFAULT_COLORS[baseCategory as keyof typeof DEFAULT_COLORS]
      : { stroke: '#A1A1AA', gradientId: 'colorDefault' }; // Fallback color
  };

  if (!mounted || !data || data.length === 0) {
    return <div ref={containerRef} className="h-[300px]" />;
  }

  return (
    <div className="w-full -mt-2">
      <div 
        ref={containerRef} 
        className="h-[300px] rounded-lg border border-neutral-border hover:border-neutral-border-hovered transition-all p-3 bg-neutral-background"
      >
        {containerWidth > 0 && (
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={data} key={`chart-${containerWidth}`} margin={{ top: 0, right: 8, bottom: 0, left: 0 }}>
              <defs>
                {categories.map(category => {
                  const { stroke, gradientId } = getCategoryColor(category);
                  return (
                    <linearGradient key={gradientId} id={gradientId} x1="0" y1="0" x2="0" y2="1">
                      <stop offset="0%" stopColor={stroke} stopOpacity={0.2} />
                      <stop offset="100%" stopColor={stroke} stopOpacity={0.05} />
                    </linearGradient>
                  );
                })}
              </defs>
              <XAxis 
                dataKey="date" 
                axisLine={false}
                tickLine={false}
                tick={{ fill: 'var(--neutral-content-subtle)', fontSize: 12 }}
                dy={8}
              />
              <YAxis 
                axisLine={false}
                tickLine={false}
                tick={{ fill: 'var(--neutral-content-subtle)', fontSize: 12 }}
                dx={-8}
              />
              <Tooltip 
                contentStyle={{ 
                  backgroundColor: 'var(--neutral-background)',
                  border: '1px solid var(--neutral-border)',
                  borderRadius: '6px',
                  fontSize: '12px'
                }}
              />
              {categories.map(category => {
                const { stroke, gradientId } = getCategoryColor(category);
                return (
                  <Area
                    key={category}
                    type="monotone"
                    dataKey={category}
                    stroke={stroke}
                    strokeWidth={2}
                    fill={`url(#${gradientId})`}
                    dot={false}
                  />
                );
              })}
            </AreaChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};