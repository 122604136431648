import { useTheme } from '../components/ThemeProvider';

export const useClerkTheme = () => {
  const { theme } = useTheme();
  const isDark = theme === 'dark';

  return {
    className: isDark ? 'dark' : 'light',
    variables: {
      colorPrimary: '#0066FF',
      colorText: isDark ? '#FFFFFF' : '#1A1A1A',
      colorTextSecondary: isDark ? '#A1A1AA' : '#666666',
      colorBackground: isDark ? '#1A1A1A' : '#FFFFFF',
      colorInputBackground: isDark ? '#27272A' : '#FFFFFF',
      colorInputText: isDark ? '#FFFFFF' : '#1A1A1A',
      colorSuccess: '#10B981',
      colorDanger: '#EF4444',
    },
    elements: {
      organizationSwitcher: {
        organizationPreviewTextColor: isDark ? '#FFFFFF' : '#1A1A1A',
        organizationPreviewSecondaryTextColor: isDark ? '#A1A1AA' : '#666666',
      }
    }
  };
}
