import React from 'react';
import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <footer className="bg-neutral-surface border-t border-neutral-border">
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
        <nav className="flex justify-center space-x-8 text-sm">
          <Link 
            to="/app/legal/terms" 
            className="text-neutral-content-subtle hover:text-neutral-content transition-colors duration-200"
          >
            Terms
          </Link>
          <Link 
            to="/app/legal/privacy" 
            className="text-neutral-content-subtle hover:text-neutral-content transition-colors duration-200"
          >
            Privacy
          </Link>
          <Link 
            to="/app/legal/dpa" 
            className="text-neutral-content-subtle hover:text-neutral-content transition-colors duration-200"
          >
            DPA
          </Link>
          <Link 
            to="/app/legal/subprocessors" 
            className="text-neutral-content-subtle hover:text-neutral-content transition-colors duration-200"
          >
            Subprocessors
          </Link>
        </nav>
      </div>
    </footer>
  );
};