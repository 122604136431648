import React, { useState, useEffect, useCallback } from 'react';
import { Loader2, AlertCircle, RefreshCw, Hash, Lock } from 'lucide-react';
import { useApi } from '../services/api';
import { Switch } from './ui/Switch';
import { Button } from './ui/Button';

interface Channel {
  id: string;
  name: string;
  type: 'public' | 'private';
  members: number;
  topic?: string;
  purpose?: string;
  synced?: boolean;
  sync_status?: 'pending' | 'in_progress' | 'completed' | 'failed';
  sync_progress?: number;
  sync_error?: string;
}

interface SlackChannelsProps {
  isNewConnection?: boolean;
}

export const SlackChannels: React.FC<SlackChannelsProps> = ({ isNewConnection = false }) => {
  const { fetchWithAuth } = useApi();
  const [channels, setChannels] = useState<Channel[]>([]);
  const [selectedChannels, setSelectedChannels] = useState<Set<string>>(new Set());
  const [syncedChannels, setSyncedChannels] = useState<Set<string>>(new Set());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [saving, setSaving] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const fetchChannels = useCallback(async () => {
    setLoading(true);
    setError(null);
    console.log('Fetching Slack channels...');
    try {
      const response = await fetchWithAuth('/api/slack/channels');
      console.log('Slack channels response:', response);
      
      if (response.success && response.data) {
        const sortedChannels: Channel[] = response.data.sort((a: Channel, b: Channel) => 
          a.name.localeCompare(b.name)
        );
        console.log(`Received ${sortedChannels.length} channels from Slack`);
        
        // Log the sync status of each channel
        sortedChannels.forEach((channel: Channel) => {
          console.log(`Channel ${channel.name}: synced=${channel.synced}, sync_status=${channel.sync_status}`);
        });
        
        setChannels(sortedChannels);

        const syncedSet: Set<string> = new Set(
          sortedChannels
            .filter((channel: Channel) => channel.synced)
            .map((channel: Channel) => channel.id)
        );
        console.log(`Found ${syncedSet.size} synced channels:`, 
          sortedChannels
            .filter((channel: Channel) => channel.synced)
            .map((channel: Channel) => channel.name)
        );
        setSyncedChannels(syncedSet);
        
        if (!isExpanded) {
          setSelectedChannels(new Set(syncedSet));
        }
      } else {
        console.error('Failed to fetch channels:', response.error);
        setError(response.error || 'Failed to fetch channels');
        setChannels([]);
      }
    } catch (err: unknown) {
      console.error('Error fetching channels:', err instanceof Error ? err.message : 'Unknown error');
      setError('Failed to fetch channels');
      setChannels([]);
    } finally {
      setLoading(false);
    }
  }, [fetchWithAuth, isExpanded]);

  // Auto-expand when it's a new connection
  useEffect(() => {
    if (isNewConnection) {
      console.log('New connection detected, expanding and fetching channels');
      setIsExpanded(true);
      fetchChannels();
    }
  }, [isNewConnection, fetchChannels]);

  // Initial channel fetch for non-new connections
  useEffect(() => {
    if (!isNewConnection) {
      console.log('Initial channel fetch');
      fetchChannels();
    }
  }, [fetchChannels, isNewConnection]);

  const handleChannelToggle = useCallback((channelId: string) => {
    setSelectedChannels(prev => {
      const newSet = new Set(prev);
      if (newSet.has(channelId)) {
        newSet.delete(channelId);
      } else {
        newSet.add(channelId);
      }
      return newSet;
    });
  }, []);

  const handleSync = async () => {
    setSaving(true);
    setError(null);
    try {
      const selectedChannelData = Array.from(selectedChannels).map(id => {
        const channel = channels.find((ch: Channel) => ch.id === id);
        if (!channel) return null;
        return {
          id: channel.id,
          name: channel.name,
          type: channel.type
        };
      }).filter((channel): channel is { id: string; name: string; type: 'public' | 'private' } => channel !== null);

      console.log('Syncing channels:', selectedChannelData);
      const response = await fetchWithAuth('/api/slack/channels/sync', {
        method: 'POST',
        body: JSON.stringify({ channels: selectedChannelData })
      });
      
      if (response.success && response.data) {
        console.log('Sync successful, fetching updated channel list');
        // Temporarily store selected channels before fetching
        const newSyncedChannels: Set<string> = new Set(selectedChannels);
        
        // Fetch updated channel list from server
        void fetchChannels();
        
        // Update synced channels with the new selection
        setSyncedChannels(newSyncedChannels);
        setIsExpanded(false);
      } else {
        console.error('Sync failed:', response.error);
        setError(response.error || 'Failed to sync channels');
      }
    } catch (err: unknown) {
      console.error('Error syncing channels:', err instanceof Error ? err.message : 'Unknown error');
      setError('Failed to sync channels');
    } finally {
      setSaving(false);
    }
  };

  const handleExpand = () => {
    setIsExpanded(true);
  };

  const handleRefresh = useCallback(async () => {
    console.log('Manual refresh triggered');
    if (loading) return; // Prevent multiple refreshes
    
    setLoading(true);
    try {
      await fetchChannels();
    } catch (err: unknown) {
      console.error('Error during refresh:', err instanceof Error ? err.message : 'Unknown error');
    }
  }, [fetchChannels, loading]);

  const handleCancel = useCallback(() => {
    setSelectedChannels(new Set());
    setIsExpanded(false);
  }, []);

  if (loading && channels.length === 0) {
    return (
      <div className="flex items-center justify-center p-4">
        <Loader2 className="w-6 h-6 animate-spin text-neutral-content-weak" />
      </div>
    );
  }

  if (!isExpanded) {
    return (
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <div>
            <h3 className="text-base font-medium text-neutral-content-strong">Connected Channels</h3>
            <p className="text-sm text-neutral-content-weak">
              {syncedChannels.size} channel{syncedChannels.size !== 1 ? 's' : ''} connected
            </p>
          </div>
          <button
            onClick={handleExpand}
            className="inline-flex items-center px-3 py-1.5 border border-neutral-border-weak rounded-md text-sm font-medium text-neutral-content-weak hover:bg-neutral-background-hovered"
          >
            Manage Channels
          </button>
        </div>

        <div className="border border-neutral-border-weak rounded-lg divide-y divide-neutral-border-weak">
          {channels
            .filter((channel: Channel) => syncedChannels.has(channel.id))
            .map((channel: Channel) => (
              <div 
                key={channel.id} 
                className="flex items-center space-x-3 p-4 cursor-pointer hover:bg-neutral-background-hovered"
                onClick={handleExpand}
                role="button"
                tabIndex={0}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    handleExpand();
                  }
                }}
              >
                <div className="flex-shrink-0">
                  {channel.type === 'public' ? (
                    <Hash className="h-5 w-5 text-neutral-content-weak" />
                  ) : (
                    <Lock className="h-5 w-5 text-neutral-content-weak" />
                  )}
                </div>
                <div>
                  <p className="text-sm font-medium text-neutral-content-strong">
                    {channel.name}
                  </p>
                  <p className="text-xs text-neutral-content-weak mt-1">
                    {channel.type === 'public' ? 'Public Channel' : 'Private Channel'}
                  </p>
                </div>
              </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <div>
          <h3 className="text-base font-medium text-neutral-content-strong">Slack Channels</h3>
          <p className="text-sm text-neutral-content-weak">
            Select channels to connect with ProductSense
          </p>
        </div>
        <button
          onClick={handleRefresh}
          disabled={loading}
          className={`inline-flex items-center px-3 py-1.5 border border-neutral-border-weak rounded-md text-sm font-medium ${
            loading 
              ? 'text-neutral-content-disabled cursor-not-allowed' 
              : 'text-neutral-content-weak hover:bg-neutral-background-hovered'
          }`}
        >
          <RefreshCw className={`h-4 w-4 mr-1.5 ${loading ? 'animate-spin' : ''}`} />
          Refresh
        </button>
      </div>

      {error && (
        <div className="p-4 bg-danger-plain/10 border border-danger-plain/20 rounded-lg flex items-center gap-3 text-danger-plain">
          <AlertCircle className="w-5 h-5 flex-shrink-0" />
          <p>{error}</p>
        </div>
      )}

      <div className="border border-neutral-border-weak rounded-lg divide-y divide-neutral-border-weak">
        {channels.map((channel: Channel) => (
          <div 
            key={channel.id} 
            className="flex items-center justify-between py-2 px-2 cursor-pointer hover:bg-neutral-background-hovered"
            onClick={handleCancel}
            role="button"
            tabIndex={0}
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                handleCancel();
              }
            }}
          >
            <div className="flex items-center gap-2">
              {channel.type === 'public' ? (
                <Hash className="w-4 h-4 text-neutral-content-subtle" />
              ) : (
                <Lock className="w-4 h-4 text-neutral-content-subtle" />
              )}
              <span className="text-neutral-content">{channel.name}</span>
              {channel.topic && (
                <span className="text-sm text-neutral-content-subtle">
                  - {channel.topic}
                </span>
              )}
            </div>
            <div onClick={e => e.stopPropagation()}>
              <Switch
                checked={selectedChannels.has(channel.id)}
                onCheckedChange={() => handleChannelToggle(channel.id)}
                disabled={saving}
              />
            </div>
          </div>
        ))}
      </div>

      <div className="flex justify-between mt-4">
        <button
          onClick={handleCancel}
          className="text-sm text-neutral-content-subtle hover:text-neutral-content"
        >
          Cancel
        </button>
        <Button
          variant="primary"
          onClick={() => void handleSync()}
          disabled={saving}
          isLoading={saving}
        >
          {saving ? 'Updating...' : 'Update Channels'}
        </Button>
      </div>
    </div>
  );
};