import React from 'react';
import ReactDOM from 'react-dom/client';
import { ClerkProvider } from '@clerk/clerk-react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from './components/ThemeProvider';
import App from './App';
import './index.css';

const clerkPubKey = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

if (!clerkPubKey) {
  throw new Error('Missing Clerk Publishable Key');
}

const rootElement = document.getElementById('root');

if (!rootElement) {
  throw new Error('Root element not found');
}

ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <ThemeProvider>
      <ClerkProvider 
        publishableKey={clerkPubKey}
        appearance={{
          variables: {
            colorPrimary: '#5656FA', // Indigo 500
            colorText: '#1F2937', // Gray 800
            colorTextSecondary: '#6B7280', // Gray 500
            colorBackground: '#FFFFFF',
            colorInputBackground: '#F9FAFB', // Gray 50
            colorInputText: '#1F2937', // Gray 800
            colorSuccess: '#0FB851', // Spring Green 500
            colorDanger: '#DC2C2C', // Crimson 500
            fontFamily: 'Inter, system-ui, sans-serif',
            borderRadius: '0.375rem',
            fontSize: '0.875rem', // 14px
            fontWeight: {
              normal: 400,
              medium: 500,
              bold: 700
            },
          },
          elements: {
            card: "bg-neutral-background border border-neutral-border shadow-md",
            navbar: "bg-neutral-background border-b border-neutral-border",
            navbarButton: "hover:bg-neutral-background-hovered",
            formButtonPrimary: "bg-primary hover:bg-primary/90 text-white",
            formButtonReset: "bg-neutral-background hover:bg-neutral-background-hovered text-neutral-content-strong border border-neutral-border",
            formFieldInput: "bg-neutral-background border border-neutral-border focus:border-primary focus:ring-1 focus:ring-primary",
            formFieldLabel: "text-neutral-content-strong font-medium",
            dividerLine: "bg-neutral-border",
            dividerText: "text-neutral-content-weak",
            avatarImage: "rounded-full",
            menuItem: "hover:bg-neutral-background-hovered text-neutral-content-strong",
            menuItemIcon: "text-neutral-content-weak",
            menuItemText: "text-neutral-content-strong",
            headerTitle: "text-neutral-content-strong font-semibold",
            headerSubtitle: "text-neutral-content-weak",
            modalBackdrop: "bg-black/50",
            modalCloseButton: "hover:bg-neutral-background-hovered rounded-full p-1",
            table: "w-full",
            tableHeader: "bg-neutral-background-strong",
            tableCell: "border-b border-neutral-border",
            badge: "bg-neutral-background-strong text-neutral-content-weak text-xs px-2 py-1 rounded-full",
            footerAction: "hover:bg-neutral-background-hovered text-neutral-content-strong",
            identityPreview: "hover:bg-neutral-background-hovered",
            organizationSwitcherPopoverCard: "bg-neutral-background border border-neutral-border shadow-lg p-2",
            organizationSwitcherTrigger: "rounded-md border border-neutral-border px-3 py-2 text-neutral-content-strong bg-neutral-background hover:bg-neutral-background-hovered",
            organizationPreview: "hover:bg-neutral-background-hovered p-2 rounded-md",
            organizationSwitcherPopoverActions: "p-2",
            userPreview: "hover:bg-neutral-background-hovered p-2 rounded-md",
            userButtonPopoverCard: "bg-neutral-background border border-neutral-border shadow-lg p-2",
            userButtonTrigger: "rounded-full hover:opacity-80"
          }
        }}
      >
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ClerkProvider>
    </ThemeProvider>
  </React.StrictMode>
);