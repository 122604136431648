import { Routes, Route, Link, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '@clerk/clerk-react';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';
import DataProcessingAgreement from './DataProcessingAgreement';
import SubprocessorList from './SubprocessorList';
import { Footer } from '../../components/Footer';
import { Logo } from '../../components/ui/Logo';

const Legal = () => {
  const { isSignedIn } = useAuth();
  const location = useLocation();

  const handleLogoClick = () => {
    if (isSignedIn) {
      window.location.href = '/dashboard';
    } else {
      window.location.href = '/';
    }
  };

  const renderContent = () => {
    // Only show the index page if we're at /app/legal exactly
    if (location.pathname === '/app/legal') {
      return (
        <div className="bg-neutral-background-pinned shadow-sm rounded-lg p-6">
          <h1 className="text-2xl font-bold text-neutral-content-strong mb-6">Legal Documents</h1>
          
          <div className="space-y-4">
            <Link 
              to="/app/legal/terms" 
              className="block p-4 border border-neutral-border-weak rounded-lg hover:bg-neutral-background-hovered"
            >
              <h2 className="text-lg font-medium text-neutral-content-strong">Terms of Service</h2>
              <p className="text-sm text-neutral-content-weak mt-1">
                Our terms of service and conditions of use
              </p>
            </Link>

            <Link 
              to="/app/legal/privacy" 
              className="block p-4 border border-neutral-border-weak rounded-lg hover:bg-neutral-background-hovered"
            >
              <h2 className="text-lg font-medium text-neutral-content-strong">Privacy Policy</h2>
              <p className="text-sm text-neutral-content-weak mt-1">
                How we collect, use, and protect your data
              </p>
            </Link>

            <Link 
              to="/app/legal/dpa" 
              className="block p-4 border border-neutral-border-weak rounded-lg hover:bg-neutral-background-hovered"
            >
              <h2 className="text-lg font-medium text-neutral-content-strong">Data Processing Agreement</h2>
              <p className="text-sm text-neutral-content-weak mt-1">
                Our data processing and GDPR compliance terms
              </p>
            </Link>

            <Link 
              to="/app/legal/subprocessors" 
              className="block p-4 border border-neutral-border-weak rounded-lg hover:bg-neutral-background-hovered"
            >
              <h2 className="text-lg font-medium text-neutral-content-strong">Subprocessors</h2>
              <p className="text-sm text-neutral-content-weak mt-1">
                Third-party services we use to process your data
              </p>
            </Link>
          </div>
        </div>
      );
    }

    // Otherwise, show the appropriate content based on the route
    return (
      <div className="bg-neutral-background-pinned shadow-sm rounded-lg">
        <Routes>
          <Route path="terms" element={<TermsOfService />} />
          <Route path="privacy" element={<PrivacyPolicy />} />
          <Route path="dpa" element={<DataProcessingAgreement />} />
          <Route path="subprocessors" element={<SubprocessorList />} />
        </Routes>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-neutral-background flex flex-col">
      <div className="flex-1">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="flex gap-8">
            {/* Sidebar */}
            <div className="w-64 flex-shrink-0">
              <div className="bg-neutral-background-pinned rounded-lg shadow-sm p-4">
                {!isSignedIn && (
                  <div className="mb-6 flex items-center gap-3 cursor-pointer" onClick={handleLogoClick}>
                    <Logo className="h-8 w-auto" />
                    <span className="text-xl font-bold text-neutral-content-strong">Product Sense</span>
                  </div>
                )}

                <nav className="space-y-1">
                  <Link 
                    to="/app/legal/terms"
                    className={`block px-3 py-2 rounded-md text-sm ${
                      location.pathname === '/app/legal/terms' 
                        ? 'bg-neutral-background-hovered text-neutral-content-strong' 
                        : 'text-neutral-content-weak hover:bg-neutral-background-hovered hover:text-neutral-content-strong'
                    }`}
                  >
                    Terms of Service
                  </Link>
                  <Link 
                    to="/app/legal/privacy"
                    className={`block px-3 py-2 rounded-md text-sm ${
                      location.pathname === '/app/legal/privacy' 
                        ? 'bg-neutral-background-hovered text-neutral-content-strong' 
                        : 'text-neutral-content-weak hover:bg-neutral-background-hovered hover:text-neutral-content-strong'
                    }`}
                  >
                    Privacy Policy
                  </Link>
                  <Link 
                    to="/app/legal/dpa"
                    className={`block px-3 py-2 rounded-md text-sm ${
                      location.pathname === '/app/legal/dpa' 
                        ? 'bg-neutral-background-hovered text-neutral-content-strong' 
                        : 'text-neutral-content-weak hover:bg-neutral-background-hovered hover:text-neutral-content-strong'
                    }`}
                  >
                    Data Processing Agreement
                  </Link>
                  <Link 
                    to="/app/legal/subprocessors"
                    className={`block px-3 py-2 rounded-md text-sm ${
                      location.pathname === '/app/legal/subprocessors' 
                        ? 'bg-neutral-background-hovered text-neutral-content-strong' 
                        : 'text-neutral-content-weak hover:bg-neutral-background-hovered hover:text-neutral-content-strong'
                    }`}
                  >
                    Subprocessors
                  </Link>
                </nav>
              </div>
            </div>

            {/* Content */}
            <div className="flex-1">
              {renderContent()}
            </div>
          </div>
        </div>
      </div>
      {!isSignedIn && <Footer />}
    </div>
  );
}

export default Legal;