import React from 'react';

interface SentimentIconProps {
  className?: string;
  size?: number;
}

export const VeryPositiveSentiment: React.FC<SentimentIconProps> = ({ className, size = 20 }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2"/>
    <path d="M7.5 9.5H9.5M14.5 9.5H16.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
    <path d="M16.5 13.5C16.5 13.5 15 16.5 12 16.5C9 16.5 7.5 13.5 7.5 13.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
  </svg>
);

export const PositiveSentiment: React.FC<SentimentIconProps> = ({ className, size = 20 }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2"/>
    <path d="M7.5 9.5H9.5M14.5 9.5H16.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
    <path d="M16 13.5C16 13.5 14.5 15.5 12 15.5C9.5 15.5 8 13.5 8 13.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
  </svg>
);

export const NeutralSentiment: React.FC<SentimentIconProps> = ({ className, size = 20 }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2"/>
    <path d="M7.5 9.5H9.5M14.5 9.5H16.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
    <path d="M8 14H16" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
  </svg>
);

export const NegativeSentiment: React.FC<SentimentIconProps> = ({ className, size = 20 }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2"/>
    <path d="M7.5 9.5H9.5M14.5 9.5H16.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
    <path d="M16 15.5C16 15.5 14.5 13.5 12 13.5C9.5 13.5 8 15.5 8 15.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
  </svg>
);

export const VeryNegativeSentiment: React.FC<SentimentIconProps> = ({ className, size = 20 }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2"/>
    <path d="M7.5 9.5H9.5M14.5 9.5H16.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
    <path d="M16.5 16C16.5 16 15 13 12 13C9 13 7.5 16 7.5 16" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
  </svg>
);
