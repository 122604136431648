import React, { useEffect, useState } from 'react';
import { Video, MessageSquare, Slack, AlertCircle, Loader2 } from 'lucide-react';
import { useSlackService } from '../services/slack';
import { SlackChannels } from '../components/SlackChannels';
import { useAuth, useUser } from '@clerk/clerk-react';
import { useApi } from '../services/api';
import { Button } from '../components/ui/Button';
import { Banner } from '../components/ui/Banner';
import { Input } from '../components/ui/Input';

interface Integration {
  id: string;
  name: string;
  description: string;
  icon: React.ReactNode;
  connected: boolean;
  onConnect: () => void;
  onDisconnect?: () => void;
}

export default function Settings() {
  const { isLoaded, isSignedIn } = useAuth();
  const { user } = useUser();
  const { fetchWithAuth } = useApi();
  const [integrationStates, setIntegrationStates] = useState({
    slack: false,
    zendesk: true,
    zoom: false
  });
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isDisconnecting, setIsDisconnecting] = useState(false);
  const [isNewConnection, setIsNewConnection] = useState(false);
  
  const { initiateSlackAuth, checkSlackConnection } = useSlackService();

  // Check initial connection status
  useEffect(() => {
    if (!isLoaded || !isSignedIn) return;

    let mounted = true;

    const checkConnections = async () => {
      if (!mounted) return;

      try {
        setError(null);
        const connected = await checkSlackConnection();
        
        if (mounted) {
          setIntegrationStates(prev => ({ ...prev, slack: connected }));
          setLoading(false);
        }
      } catch (err) {
        console.error('Connection check error:', err);
        if (mounted) {
          setError('Failed to check integration status. Please try again later.');
          setLoading(false);
        }
      }
    };

    void checkConnections();

    return () => {
      mounted = false;
    };
  }, [isLoaded, isSignedIn, checkSlackConnection]);

  const handleSlackConnect = async () => {
    if (isConnecting || integrationStates.slack) return;

    try {
      setError(null);
      setIsConnecting(true);
      
      const result = await initiateSlackAuth();
      
      if (result.success) {
        setIntegrationStates(prev => ({ ...prev, slack: true }));
        setIsNewConnection(true);
        const timer = setTimeout(() => {
          setIsNewConnection(false);
        }, 5000);
        return () => clearTimeout(timer);
      }
    } catch (err) {
      console.error('Slack auth error:', err);
      setError(err instanceof Error ? err.message : 'Failed to connect to Slack');
    } finally {
      setIsConnecting(false);
    }
  };

  const handleSlackDisconnect = async () => {
    if (!integrationStates.slack || isDisconnecting) return;

    try {
      setError(null);
      setIsDisconnecting(true);
      setIsNewConnection(false);  // Reset isNewConnection on disconnect

      const data = await fetchWithAuth('/api/slack/disconnect', {
        method: 'POST'
      });

      if (data.success) {
        setIntegrationStates(prev => ({ ...prev, slack: false }));
      } else {
        throw new Error(data.error || 'Failed to disconnect from Slack');
      }
    } catch (err) {
      console.error('Slack disconnect error:', err);
      setError(err instanceof Error ? err.message : 'Failed to disconnect from Slack');
    } finally {
      setIsDisconnecting(false);
    }
  };

  const integrations: Integration[] = [
    {
      id: 'zoom',
      name: 'Zoom',
      description: 'Analyze customer calls and meetings automatically',
      icon: <Video className="w-8 h-8 text-blue-500" />,
      connected: integrationStates.zoom,
      onConnect: () => {}
    },
    {
      id: 'zendesk',
      name: 'Zendesk',
      description: 'Import and analyze support ticket conversations',
      icon: <MessageSquare className="w-8 h-8 text-green-500" />,
      connected: integrationStates.zendesk,
      onConnect: () => {}
    },
    {
      id: 'slack',
      name: 'Slack',
      description: 'Monitor customer feedback channels in real-time',
      icon: <Slack className="w-8 h-8 text-purple-500" />,
      connected: integrationStates.slack,
      onConnect: () => void handleSlackConnect(),
      onDisconnect: () => void handleSlackDisconnect()
    }
  ];

  if (!isLoaded || !isSignedIn) {
    return (
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex items-center justify-center min-h-[400px]">
          <Loader2 className="w-8 h-8 animate-spin text-neutral-content-weak" />
        </div>
      </main>
    );
  }

  return (
    <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="space-y-6">
        {error && (
          <Banner 
            variant="error"
            title="Error"
            description={error}
            className="mb-6"
          />
        )}

        {isNewConnection && (
          <Banner
            variant="success"
            title="Connected Successfully"
            description="Your Slack workspace has been connected successfully."
            className="mb-6"
          />
        )}

        <div style={{ 
          backgroundColor: 'var(--neutral-background)',
          borderRadius: 'var(--radius-lg)',
          padding: 'var(--spacing-6)',
          border: '1px solid var(--neutral-border)',
          boxShadow: 'var(--shadow-sm)'
        }}>
          <h2 style={{ 
            fontSize: 'var(--font-size-h3)',
            fontWeight: 'var(--font-weight-medium)',
            color: 'var(--neutral-content)',
            marginBottom: 'var(--spacing-6)'
          }}>Account Settings</h2>
          <div className="space-y-4">
            <Input
              label="Email"
              showLabel
              defaultValue={user?.primaryEmailAddress?.emailAddress || ''}
              disabled
              className="max-w-md"
              readOnly
            />
            <Input
              label="Name"
              showLabel
              defaultValue={user?.fullName || ''}
              disabled
              className="max-w-md"
              readOnly
            />
          </div>
        </div>

        <div style={{ 
          backgroundColor: 'var(--neutral-background)',
          borderRadius: 'var(--radius-lg)',
          padding: 'var(--spacing-6)',
          border: '1px solid var(--neutral-border)',
          boxShadow: 'var(--shadow-sm)'
        }}>
          <h2 style={{ 
            fontSize: 'var(--font-size-h3)',
            fontWeight: 'var(--font-weight-medium)',
            color: 'var(--neutral-content)',
            marginBottom: 'var(--spacing-6)'
          }}>Integrations</h2>
          
          <div className="space-y-6">
            {integrations.map((integration) => (
              <div key={integration.id}>
                <div style={{
                  padding: 'var(--spacing-4)',
                  borderRadius: 'var(--radius-md)',
                  border: '1px solid var(--neutral-border)',
                  backgroundColor: 'var(--neutral-background)'
                }}>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-3">
                      <div className="flex-shrink-0">
                        {integration.icon}
                      </div>
                      <div>
                        <h3 style={{ 
                          fontSize: 'var(--font-size-h5)',
                          fontWeight: 'var(--font-weight-medium)',
                          color: 'var(--neutral-content)'
                        }}>{integration.name}</h3>
                        <p style={{ 
                          fontSize: 'var(--font-size-small)',
                          color: 'var(--neutral-content-subtle)',
                          marginTop: 'var(--spacing-1)'
                        }}>{integration.description}</p>
                      </div>
                    </div>
                    <Button
                      variant={integration.connected ? "bordered" : "primary"}
                      onClick={integration.connected ? integration.onDisconnect : integration.onConnect}
                      disabled={
                        (integration.id === 'slack' && (isConnecting || isDisconnecting)) ||
                        (integration.id !== 'slack' && integration.connected)
                      }
                      isLoading={integration.id === 'slack' && (isConnecting || isDisconnecting)}
                    >
                      {integration.connected ? 'Disconnect' : 'Connect'}
                    </Button>
                  </div>
                </div>
                {integration.id === 'slack' && integrationStates.slack && (
                  <div style={{ 
                    marginTop: 'var(--spacing-4)',
                    marginLeft: 'var(--spacing-8)'
                  }}>
                    <SlackChannels isNewConnection={isNewConnection} />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </main>
  );
}