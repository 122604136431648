import { useApi } from '../services/api';

interface CustomerIoFormData {
  email: string;
  [key: string]: string;
}

const FORM_ID = '61f77080bb0f47f';

export function useCustomerIo() {
  const { fetchWithAuth } = useApi();

  const submitToCustomerIo = async (data: CustomerIoFormData): Promise<any> => {
    const response = await fetchWithAuth('/api/customerio/forms/submit', {
      method: 'POST',
      body: JSON.stringify({
        form_id: FORM_ID,
        data: {
          email: data.email,
          // Add any additional form fields here
        }
      })
    });

    if (!response.success) {
      throw new Error(response.error || 'Failed to submit form');
    }

    return response;
  };

  return { submitToCustomerIo };
}
