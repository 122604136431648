import React from 'react';
import { cn } from '../../utils/cn';
import { Check } from 'lucide-react';

interface CheckboxProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  label?: string;
  error?: boolean;
  indeterminate?: boolean;
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, error, indeterminate, className, disabled, checked, onChange, ...props }, ref) => {
    React.useEffect(() => {
      if (ref && 'current' in ref && ref.current) {
        ref.current.indeterminate = indeterminate || false;
      }
    }, [ref, indeterminate]);

    return (
      <label 
        className={cn(
          "flex items-center gap-2 relative",
          "cursor-pointer select-none",
          disabled && "cursor-not-allowed",
          className
        )}
      >
        <div className="relative">
          <input
            ref={ref}
            type="checkbox"
            disabled={disabled}
            checked={checked}
            onChange={onChange}
            className="sr-only"
            {...props}
          />
          <div 
            className={cn(
              "w-4 h-4 rounded border transition-colors duration-200",
              "flex items-center justify-center",
              // Default state
              !disabled && !checked && !error && "border-neutral-border bg-neutral-background hover:bg-neutral-background-hovered",
              // Checked state
              checked && !disabled && !error && "border-neutral-content bg-primary",
              // Error states
              error && !disabled && "border-error-content bg-error-background",
              // Disabled states
              disabled && !checked && "border-neutral-border bg-neutral-200",
              disabled && checked && "border-neutral-300 bg-neutral-200"
            )}
          >
            {(checked || indeterminate) && !disabled && (
              <Check className="w-3 h-3 text-white" />
            )}
            {(checked || indeterminate) && disabled && (
              <Check className="w-3 h-3 text-neutral-400" />
            )}
            {!checked && indeterminate && !disabled && (
              <div className="w-2 h-0.5 bg-white rounded-full" />
            )}
          </div>
        </div>
        {label && (
          <span 
            className={cn(
              "text-sm leading-5",
              !disabled && "text-neutral-content",
              disabled && "text-neutral-400"
            )}
          >
            {label}
          </span>
        )}
      </label>
    );
  }
);

Checkbox.displayName = 'Checkbox';
