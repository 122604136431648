import { useOrganization } from '@clerk/clerk-react';
import { ReactNode } from 'react';

interface RequireOrganizationProps {
  children: ReactNode;
}

export const RequireOrganization = ({ children }: RequireOrganizationProps) => {
  const { organization, isLoaded } = useOrganization();

  if (!isLoaded) {
    return (
      <div className="flex items-center justify-center h-[60vh]">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (!organization) {
    return null;
  }

  return <>{children}</>;
}
