import React from 'react';
import { Info, X, ChevronDown, ChevronUp } from 'lucide-react';
import { cn } from '../../lib/utils';

export interface BannerProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: 'info' | 'success' | 'warning' | 'error' | 'neutral' | 'brand';
  title: string;
  description?: string;
  icon?: React.ReactNode;
  link?: {
    text: string;
    onClick: () => void;
  };
  dismissible?: boolean;
  onDismiss?: () => void;
  expandable?: boolean;
  expandedContent?: React.ReactNode;
}

const variantStyles = {
  info: {
    background: 'bg-banners-information-background',
    border: 'border-banners-information-border',
    text: 'text-banners-information-onbackground',
    hover: 'hover:bg-banners-information-background/90'
  },
  success: {
    background: 'bg-banners-success-background',
    border: 'border-banners-success-border',
    text: 'text-banners-success-onbackground',
    hover: 'hover:bg-banners-success-background/90'
  },
  warning: {
    background: 'bg-banners-caution-background',
    border: 'border-banners-caution-border',
    text: 'text-banners-caution-onbackground',
    hover: 'hover:bg-banners-caution-background/90'
  },
  error: {
    background: 'bg-banners-danger-background',
    border: 'border-banners-danger-border',
    text: 'text-banners-danger-onbackground',
    hover: 'hover:bg-banners-danger-background/90'
  },
  neutral: {
    background: 'bg-neutral-surface',
    border: 'border-neutral-border',
    text: 'text-neutral-content',
    hover: 'hover:bg-neutral-surface/90'
  },
  brand: {
    background: 'bg-banners-brand-background',
    border: 'border-banners-brand-border',
    text: 'text-banners-brand-onbackground',
    hover: 'hover:bg-banners-brand-background/90'
  }
};

export const Banner = React.forwardRef<HTMLDivElement, BannerProps>(
  ({ 
    className, 
    variant = 'info', 
    title,
    description,
    icon,
    link,
    dismissible,
    onDismiss,
    expandable,
    expandedContent,
    ...props 
  }, ref) => {
    const [isExpanded, setIsExpanded] = React.useState(false);
    const [isDismissed, setIsDismissed] = React.useState(false);

    if (isDismissed) return null;

    const handleDismiss = () => {
      setIsDismissed(true);
      onDismiss?.();
    };

    const styles = variantStyles[variant];

    return (
      <div
        ref={ref}
        className={cn(
          'flex flex-col gap-2 rounded-lg border p-4',
          styles.background,
          styles.border,
          styles.text,
          className
        )}
        {...props}
      >
        <div className="flex items-start gap-3">
          <div className="flex-shrink-0 pt-0.5">
            {icon || <Info className="h-5 w-5" />}
          </div>
          
          <div className="flex-grow space-y-1">
            <div className="flex items-start justify-between gap-2">
              <span className="font-medium">{title}</span>
              <div className="flex items-center gap-2">
                {expandable && (
                  <button
                    onClick={() => setIsExpanded(!isExpanded)}
                    className={cn(
                      'p-1 rounded-md transition-colors',
                      styles.hover
                    )}
                  >
                    {isExpanded ? (
                      <ChevronUp className="h-4 w-4" />
                    ) : (
                      <ChevronDown className="h-4 w-4" />
                    )}
                  </button>
                )}
                {dismissible && (
                  <button
                    onClick={handleDismiss}
                    className={cn(
                      'p-1 rounded-md transition-colors',
                      styles.hover
                    )}
                  >
                    <X className="h-4 w-4" />
                  </button>
                )}
              </div>
            </div>
            
            {description && (
              <p className="text-sm opacity-90">{description}</p>
            )}
            
            {link && (
              <button
                onClick={link.onClick}
                className={cn(
                  'text-sm font-medium underline-offset-4 hover:underline',
                  styles.text
                )}
              >
                {link.text}
              </button>
            )}
          </div>
        </div>

        {expandable && isExpanded && (
          <div className="pl-8 text-sm">
            {expandedContent}
          </div>
        )}
      </div>
    );
  }
);

Banner.displayName = 'Banner';
