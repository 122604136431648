import React from 'react';
import { cn } from '../../utils/cn';

interface MenuProps {
  children: React.ReactNode;
  title?: string;
  className?: string;
}

export const MenuDivider = () => {
  return <div className="h-px bg-neutral-border mx-1 my-1" />;
};

export const Menu = React.forwardRef<HTMLDivElement, MenuProps>(
  ({ children, title, className }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          "w-[206px] rounded overflow-hidden border border-neutral-border bg-neutral-background shadow-m-elevation",
          "animate-in fade-in duration-75",
          className
        )}
      >
        {title && (
          <>
            <div className="px-4 py-2">
              <span className="text-sm font-bold text-neutral-content">
                {title}
              </span>
            </div>
            <div className="h-px bg-neutral-border" />
          </>
        )}
        <div className="p-1">
          {children}
        </div>
      </div>
    );
  }
);

Menu.displayName = 'Menu'; 