import React from 'react';
import { MessageSquare, TrendingUp, AlertCircle } from 'lucide-react';
import type { ActivityItem } from '../types';

interface ActivityFeedProps {
  activities: ActivityItem[];
  hideHeader?: boolean;
}

export const ActivityFeed: React.FC<ActivityFeedProps> = ({ activities, hideHeader = false }) => {
  return (
    <div className="mt-6">
      {!hideHeader && (
        <h3 className="text-lg font-semibold text-neutral-content-strong mb-4">Recent Activity</h3>
      )}
      <div className="flow-root">
        <ul role="list" className="-mb-8">
          {activities.map((activity, activityIdx) => (
            <li key={activity.id}>
              <div className="relative pb-8">
                {activityIdx !== activities.length - 1 ? (
                  <span className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-neutral-border-weak" aria-hidden="true" />
                ) : null}
                <div className="relative flex space-x-3">
                  <div className={`relative flex h-8 w-8 items-center justify-center rounded-full ${
                    activity.type === 'new_feedback' ? 'bg-primary-background text-primary-onbackground' :
                    activity.type === 'score_change' ? 'bg-success-background text-success-onbackground' :
                    'bg-danger-background text-danger-onbackground'
                  }`}>
                    {activity.type === 'new_feedback' && <MessageSquare className="h-5 w-5" />}
                    {activity.type === 'score_change' && <TrendingUp className="h-5 w-5" />}
                    {activity.type === 'service_disconnected' && <AlertCircle className="h-5 w-5" />}
                  </div>
                  <div className="flex min-w-0 flex-1 justify-between space-x-4">
                    <div>
                      <p className="text-sm text-neutral-content-strong">
                        {activity.type === 'new_feedback' && 'New feedback received'}
                        {activity.type === 'score_change' && 'Score updated'}
                        {activity.type === 'service_disconnected' && 'Service disconnected'}
                        <span className="ml-2 text-neutral-content-weak">from {activity.source}</span>
                      </p>
                      {activity.details && (
                        <p className="mt-1 text-sm text-neutral-content-weak">
                          {activity.details.category && `Category: ${activity.details.category}`}
                          {activity.details.score && `, Score: ${activity.details.score}`}
                        </p>
                      )}
                    </div>
                    <div className="whitespace-nowrap text-sm text-neutral-content-weak">
                      {new Date(activity.timestamp).toLocaleDateString()}
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};