import { useCallback } from 'react';
import { useApi } from './api';

interface SlackAuthResponse {
  success: boolean;
  team?: string;
  user?: string;
  error?: string;
}

interface SlackStatusResponse {
  success: boolean;
  connected: boolean;
  error?: string;
}

export const useSlackService = () => {
  const { fetchWithAuth } = useApi();

  const initiateSlackAuth = async (): Promise<SlackAuthResponse> => {
    const width = 600;
    const height = 800;
    const left = (window.screen.width / 2) - (width / 2);
    const top = (window.screen.height / 2) - (height / 2);

    const state = Math.random().toString(36).substring(7);
    sessionStorage.setItem('slackOAuthState', state);
    
    const redirectUri = `${window.location.protocol}//${window.location.host}/slack/callback.html`;

    return new Promise((resolve, reject) => {
      let messageReceived = false;
      let popup: Window | null = null;
      let pollTimer: NodeJS.Timeout | null = null;
      let timeoutTimer: NodeJS.Timeout | null = null;
      
      const cleanup = () => {
        window.removeEventListener('message', handleCallback);
        if (pollTimer) clearInterval(pollTimer);
        if (timeoutTimer) clearTimeout(timeoutTimer);
        if (popup && !popup.closed) popup.close();
      };
      
      const handleCallback = async (event: MessageEvent) => {
        // Only process messages from our popup window
        if (event.source !== popup) {
          return;
        }

        try {
          if (!event.data || typeof event.data !== 'object' || !('slackOAuth' in event.data)) {
            return;
          }

          const { code, state: returnedState } = event.data.slackOAuth;
          
          if (!code || !returnedState) {
            throw new Error('Missing code or state in response');
          }

          if (returnedState !== state) {
            throw new Error('Invalid state parameter');
          }

          messageReceived = true;
          cleanup(); // Close the window immediately

          // Then handle the OAuth response
          const response = await fetchWithAuth('/api/slack/oauth', {
            method: 'POST',
            body: JSON.stringify({ 
              code,
              redirect_uri: redirectUri
            }),
          });

          resolve(response);
        } catch (error) {
          cleanup();
          reject(error);
        }
      };

      window.addEventListener('message', handleCallback);

      const authUrl = new URL('https://slack.com/oauth/v2/authorize');
      authUrl.searchParams.append('client_id', '7454949507506.7864240382710');
      authUrl.searchParams.append('user_scope', 'channels:read,channels:history,groups:read,groups:history');
      authUrl.searchParams.append('redirect_uri', redirectUri);
      authUrl.searchParams.append('state', state);
      
      popup = window.open(
        authUrl.toString(),
        'SlackAuth',
        `width=${width},height=${height},left=${left},top=${top}`
      );

      if (!popup) {
        cleanup();
        reject(new Error('Failed to open popup. Please allow popups for this site.'));
        return;
      }

      // Poll for popup closure
      pollTimer = setInterval(() => {
        if (popup?.closed && !messageReceived) {
          cleanup();
          reject(new Error('Authentication cancelled or popup closed'));
        }
      }, 1000);

      // Set timeout
      timeoutTimer = setTimeout(() => {
        if (!messageReceived) {
          cleanup();
          reject(new Error('Authentication timed out'));
        }
      }, 300000); // 5 minutes
    });
  };

  const checkSlackConnection = useCallback(async (): Promise<boolean> => {
    try {
      const data = await fetchWithAuth<SlackStatusResponse>('/api/slack/status');
      return data.connected;
    } catch (error) {
      console.error('Error checking connection:', error);
      throw error;
    }
  }, [fetchWithAuth]);

  return {
    initiateSlackAuth,
    checkSlackConnection
  };
};