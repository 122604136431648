import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Button } from './ui/Button';
import { X } from 'lucide-react';
import { useCustomerIo } from '../utils/customerio';

interface JoinWaitlistModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: var(--neutral-background);
  border-radius: var(--border-radius-lg);
  padding: var(--spacing-6);
  width: 100%;
  max-width: 480px;
  box-shadow: var(--shadow-lg);
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: var(--spacing-4);
  right: var(--spacing-4);
  background: none;
  border: none;
  cursor: pointer;
  color: var(--neutral-content-weak);
  padding: var(--spacing-2);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s;

  &:hover {
    color: var(--neutral-content);
  }
`;

const Title = styled.h2`
  font-size: var(--font-size-h3);
  font-weight: var(--font-weight-semibold);
  color: var(--neutral-content);
  margin-bottom: var(--spacing-6);
  max-width: 400px;
  line-height: 1.3;
`;

const TitleHighlight = styled.div`
  color: var(--primary);
  margin-top: var(--spacing-2);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
`;

const Input = styled.input`
  width: 100%;
  padding: var(--spacing-3);
  border: 1px solid var(--neutral-border);
  border-radius: var(--border-radius-base);
  font-size: var(--font-size-base);
  color: var(--neutral-content);
  background: var(--neutral-background);
  
  &:focus {
    outline: none;
    border-color: var(--primary-border);
    box-shadow: 0 0 0 2px var(--primary-border-subtle);
  }
`;

const SubmitButton = styled(Button)`
  width: 100%;
  margin-top: var(--spacing-4);
  background: #4a90e2;
  border: none;
  animation: shimmer 10s linear infinite;

  @keyframes shimmer {
    0% {
      filter: hue-rotate(0deg) brightness(1.1) saturate(1.2);
    }
    100% {
      filter: hue-rotate(360deg) brightness(1.1) saturate(1.2);
    }
  }

  &:hover {
    filter: brightness(1.1);
  }
`;

export const JoinWaitlistModal: React.FC<JoinWaitlistModalProps> = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const { submitToCustomerIo } = useCustomerIo();

  const handleSubmit = useCallback(async (e?: React.FormEvent) => {
    if (e) e.preventDefault();
    if (!isValidEmail || isSubmitting) return;

    setIsSubmitting(true);
    try {
      // Submit to Customer.io
      await submitToCustomerIo({ email });
      
      // Store email in sessionStorage
      sessionStorage.setItem('waitlist_email', email);
      
      onClose();
    } catch (error) {
      console.error('Failed to submit email:', error);
      alert('Failed to join waitlist. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  }, [email, isValidEmail, isSubmitting, onClose]);

  useEffect(() => {
    if (!isOpen) return;

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      } else if (event.key === 'Enter' && isValidEmail && !event.isComposing && !isSubmitting) {
        event.preventDefault();
        void handleSubmit();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isOpen, onClose, isValidEmail, isSubmitting, handleSubmit]);

  if (!isOpen) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={e => e.stopPropagation()}>
        <CloseButton onClick={onClose} aria-label="Close modal">
          <X size={20} />
        </CloseButton>
        
        <Title>
          Product Management has changed.
          <TitleHighlight>Don't get left behind.</TitleHighlight>
        </Title>
        
        <Form onSubmit={(e) => { e.preventDefault(); void handleSubmit(e); }}>
          <div>
            <Input
              id="email_input"
              name="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
          </div>
          
          <SubmitButton
            type="submit"
            disabled={!isValidEmail || isSubmitting}
            style={{
              opacity: isValidEmail && !isSubmitting ? 1 : 0.5,
              cursor: isValidEmail && !isSubmitting ? 'pointer' : 'not-allowed'
            }}
          >
            {isSubmitting ? 'Joining...' : 'Join Waitlist'}
          </SubmitButton>
        </Form>
      </ModalContent>
    </ModalOverlay>
  );
};
