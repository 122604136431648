import * as Popover from '@radix-ui/react-popover';
import { DayPicker } from 'react-day-picker';
import type { DateRange } from 'react-day-picker';
import React, { useState } from "react";
import { Caretdown1 } from "./icons/Caretdown1";
import { cn } from "../../lib/utils";
import { format } from 'date-fns';

// Define custom styles to override react-day-picker defaults
const dayPickerStyles = `
  /* Base calendar styles */
  .rdp {
    --rdp-cell-size: 40px;
    --rdp-accent-color: var(--primary-content);
    --rdp-background-color: var(--primary-surface);
    margin: 0;
  }
  .rdp-months {
    background: var(--neutral-background);
    padding: var(--spacing-4);
  }
  .rdp-month {
    background: var(--neutral-background);
  }
  .rdp-table {
    width: auto;
    margin: 0;
  }
  .rdp-cell {
    padding: 0;
    text-align: center;
  }
  .rdp-head_cell {
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-small);
    color: var(--neutral-content-subtle);
    padding-bottom: var(--spacing-3);
  }
  .rdp-day {
    width: var(--rdp-cell-size);
    height: 36px;
    font-size: var(--font-size-small);
    color: var(--neutral-content);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: all 150ms ease;
    border-radius: var(--radius-full);
  }
  .rdp-day_today {
    font-weight: var(--font-weight-medium);
    color: var(--primary-content);
  }
  .rdp-day_selected {
    color: var(--neutral-background);
    background-color: var(--primary-content);
  }
  .rdp-day_range_start,
  .rdp-day_range_end {
    color: var(--neutral-background) !important;
    background-color: var(--primary-content) !important;
  }
  .rdp-day_range_middle {
    color: var(--neutral-content) !important;
    background-color: var(--primary-surface) !important;
  }
  .rdp-day_range_start {
    border-top-left-radius: var(--radius-full);
    border-bottom-left-radius: var(--radius-full);
  }
  .rdp-day_range_end {
    border-top-right-radius: var(--radius-full);
    border-bottom-right-radius: var(--radius-full);
  }
  .rdp-day:not(.rdp-day_range_start):not(.rdp-day_range_end):not(.rdp-day_range_middle):hover:not([disabled]) {
    background-color: var(--neutral-surface);
    color: var(--neutral-content);
  }
  .rdp-day[disabled] {
    color: var(--neutral-content-subtle);
    opacity: 0.4;
  }
  .rdp-day:not([disabled]):hover {
    background-color: var(--neutral-surface);
  }
  .rdp-nav_button {
    color: var(--neutral-content);
    border: 1px solid var(--neutral-border);
    border-radius: var(--radius-md);
    padding: var(--spacing-1);
  }
  .rdp-nav_button:hover {
    background-color: var(--neutral-surface);
  }
  .rdp-caption {
    padding: var(--spacing-2) 0;
  }
  .rdp-caption_label {
    font-size: var(--font-size-body);
    font-weight: var(--font-weight-medium);
    color: var(--neutral-content);
  }
`;

interface Props {
  label?: string;
  showLabel?: boolean;
  state?: "default" | "success" | "filled" | "selected" | "focused" | "hovered" | "caution" | "error" | "disabled";
  className?: string;
  value: DateRange;
  onChange: (range: DateRange | undefined) => void;
  onComplete: (range: DateRange) => void;
  presets?: { label: string; range: DateRange }[];
}

const defaultPresets = [
  {
    label: 'Last 7 days',
    range: {
      from: new Date(new Date().setDate(new Date().getDate() - 7)),
      to: new Date()
    }
  },
  {
    label: 'Last 14 days',
    range: {
      from: new Date(new Date().setDate(new Date().getDate() - 14)),
      to: new Date()
    }
  },
  {
    label: 'Last 30 days',
    range: {
      from: new Date(new Date().setDate(new Date().getDate() - 30)),
      to: new Date()
    }
  },
  {
    label: 'Last 90 days',
    range: {
      from: new Date(new Date().setDate(new Date().getDate() - 90)),
      to: new Date()
    }
  }
];

const formatRange = (range: DateRange): string => {
  if (!range.from || !range.to) return 'Select a date range';
  return `${format(range.from, 'MMM d, yyyy')} - ${format(range.to, 'MMM d, yyyy')}`;
};

export const DateRangeDropdown = React.forwardRef<HTMLButtonElement, Props>(({
  label = "Date Range",
  showLabel = true,
  state = "default",
  className,
  value,
  onChange,
  onComplete,
  presets = defaultPresets,
}: Props, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDateRangeChange = (range: DateRange | undefined) => {
    onChange(range);
    
    // Only call onComplete when we have a complete valid range
    if (range?.from && range?.to && range.from <= range.to) {
      onComplete(range);
      setIsOpen(false);
    }
  };

  const handlePresetSelect = (preset: { label: string; range: DateRange }) => {
    const today = new Date();
    today.setHours(23, 59, 59, 999);
    
    // Ensure the preset's end date is today
    const range = {
      from: preset.range.from,
      to: today
    };
    
    handleDateRangeChange(range);
  };

  return (
    <Popover.Root 
      open={isOpen} 
      onOpenChange={(open) => {
        if (!open && value.from && value.to) {
          setIsOpen(false);
        } else if (open) {
          setIsOpen(true);
        }
      }}
      modal
    >
      <div className={cn("flex flex-col gap-1", className)}>
        {showLabel && (
          <div className="text-xs font-semibold leading-5 text-neutral-600">
            {label}
          </div>
        )}
        
        <Popover.Trigger
          ref={ref}
          className={cn(
            "flex items-center justify-between w-full px-3 py-2 text-sm rounded",
            "bg-neutral-background border border-neutral-border",
            "hover:border-neutral-border-hovered",
            "focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary",
            {
              "opacity-50 cursor-not-allowed": state === "disabled",
              "border-error": state === "error",
              "border-caution": state === "caution",
            }
          )}
          disabled={state === "disabled"}
        >
          <span className="text-neutral-content">
            {formatRange(value)}
          </span>
          <Caretdown1 className="ml-2 h-4 w-4 text-neutral-icon" />
        </Popover.Trigger>

        <Popover.Portal>
          <Popover.Content
            className="z-50 min-w-[320px] bg-neutral-background rounded-md shadow-lg border border-neutral-border"
            align="start"
            sideOffset={4}
          >
            <style>{dayPickerStyles}</style>
            <div className="p-4">
              <div className="flex gap-4">
                <div className="border-r border-neutral-border pr-4">
                  <div className="font-semibold mb-2 text-sm">Presets</div>
                  <div className="flex flex-col gap-1">
                    {presets.map((preset) => (
                      <button
                        key={preset.label}
                        onClick={() => handlePresetSelect(preset)}
                        className={cn(
                          "px-2 py-1 text-sm rounded text-left",
                          "hover:bg-neutral-surface",
                          "focus:outline-none focus:ring-2 focus:ring-primary"
                        )}
                      >
                        {preset.label}
                      </button>
                    ))}
                  </div>
                </div>
                <div onClick={(e) => e.stopPropagation()}>
                  <DayPicker
                    mode="range"
                    selected={value}
                    onSelect={handleDateRangeChange}
                    numberOfMonths={2}
                    className="border-0"
                    showOutsideDays={false}
                    defaultMonth={value.from}
                    fromDate={new Date(new Date().setFullYear(new Date().getFullYear() - 1))}
                    toDate={new Date()}
                    classNames={{
                      months: "flex gap-8",
                      month: "space-y-4",
                      caption: "flex justify-center pt-1 relative items-center",
                      caption_label: "text-sm font-medium",
                      nav: "space-x-1 flex items-center",
                      nav_button: cn(
                        "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100",
                        "text-neutral-content hover:bg-neutral-surface rounded-full",
                        "flex items-center justify-center"
                      ),
                      nav_button_previous: "absolute left-1",
                      nav_button_next: "absolute right-1",
                      table: "w-full border-collapse space-y-1",
                      head_row: "flex",
                      head_cell: "text-neutral-content w-10 font-normal text-sm",
                      row: "flex w-full mt-2",
                      cell: "text-center text-sm p-0",
                      day: cn(
                        "h-9 w-9 p-0 font-normal",
                        "transition-colors duration-200",
                        "focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                      ),
                      day_selected: "bg-primary text-white hover:opacity-90",
                      day_range_start: "day-range-start",
                      day_range_end: "day-range-end",
                      day_range_middle: "day-range-middle",
                    }}
                  />
                </div>
              </div>
            </div>
            <Popover.Arrow className="fill-neutral-border" />
          </Popover.Content>
        </Popover.Portal>
      </div>
    </Popover.Root>
  );
});

DateRangeDropdown.displayName = "DateRangeDropdown";