import React from 'react';
import { cn } from '../../utils/cn';

interface TabProps {
  children: React.ReactNode;
  value: string;
  defaultValue?: string;
  onValueChange?: (value: string) => void;
  className?: string;
}

interface TabItemProps {
  value: string;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
}

const TabsContext = React.createContext<{
  value: string;
  onValueChange: (value: string) => void;
} | null>(null);

export const Tabs = React.forwardRef<HTMLDivElement, TabProps>(
  ({ children, value, defaultValue, onValueChange, className, ...props }, ref) => {
    const [selectedValue, setSelectedValue] = React.useState(value || defaultValue || '');

    const handleValueChange = React.useCallback((newValue: string) => {
      setSelectedValue(newValue);
      onValueChange?.(newValue);
    }, [onValueChange]);

    return (
      <TabsContext.Provider value={{ value: selectedValue, onValueChange: handleValueChange }}>
        <div
          ref={ref}
          className={cn(
            "flex items-center gap-4",
            className
          )}
          {...props}
        >
          {children}
        </div>
      </TabsContext.Provider>
    );
  }
);

export const TabItem = React.forwardRef<HTMLDivElement, TabItemProps>(
  ({ value, children, className, disabled, ...props }, ref) => {
    const context = React.useContext(TabsContext);
    const [isHovered, setIsHovered] = React.useState(false);
    
    if (!context) {
      throw new Error('TabItem must be used within a Tabs component');
    }

    const isSelected = context.value === value;

    return (
      <div
        ref={ref}
        role="tab"
        aria-selected={isSelected}
        aria-disabled={disabled}
        onClick={() => !disabled && context.onValueChange(value)}
        onMouseEnter={() => !disabled && setIsHovered(true)}
        onMouseLeave={() => !disabled && setIsHovered(false)}
        className={cn(
          "flex items-center justify-center px-4 py-2 rounded-md text-sm font-medium transition-colors min-w-[100px]",
          !disabled && "cursor-pointer",
          disabled && "cursor-not-allowed opacity-50",
          isSelected && !disabled && "bg-[#570df8] text-white",
          !isSelected && !isHovered && !disabled && "text-neutral-content hover:bg-gray-100",
          !isSelected && isHovered && !disabled && "bg-gray-100",
          className
        )}
        {...props}
      >
        {children}
      </div>
    );
  }
);

Tabs.displayName = 'Tabs';
TabItem.displayName = 'TabItem';