import React, { useState, useEffect } from 'react';
import { useTheme } from '../../components/ThemeProvider';
import { Checkbox } from '../../components/ui/Checkbox';
import { Button } from '../../components/ui/Button';
import { Moon, Sun, Copy, LogOut, Settings, User } from 'lucide-react';
import { Avatar } from '../../components/ui/Avatar';
import { Banner } from '../../components/ui/Banner';
import { Command, CommandGroup, CommandItem } from '../../components/ui/Command';
import { Dialog, DialogTrigger, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from '../../components/ui/Dialog';
import { Divider } from '../../components/ui/Divider';
import { Dropdown } from '../../components/ui/Dropdown';
import { DateRangeDropdown } from '../../components/ui/DateRangeDropdown';
import { ExpandableSection } from '../../components/ui/ExpandableSection';
import { VerticalExpandableSection } from '../../components/ui/VerticalExpandableSection';
import { Menu, MenuDivider } from '../../components/ui/Menu';
import { MenuItem } from '../../components/ui/MenuItem';
import { Modal } from '../../components/ui/Modal';
import { Radio } from '../../components/ui/Radio';
import { Switch } from '../../components/ui/Switch';
import { Tabs, TabItem } from '../../components/ui/Tab';
import { Input } from '../../components/ui/Input';
import { cn } from '../../lib/utils';
import type { DateRange } from 'react-day-picker';

interface ComponentShowcaseProps {
  title: string;
  description?: string;
  children: React.ReactNode;
  id?: string;
}

const ComponentShowcase: React.FC<ComponentShowcaseProps> = ({ title, description, children, id }) => (
  <section id={id} className="space-y-6 p-8 rounded-lg bg-neutral-surface/50">
    <div className="space-y-2">
      <h2 className="text-2xl font-semibold text-neutral-content">{title}</h2>
      {description && <p className="text-neutral-content/80">{description}</p>}
    </div>
    {children}
  </section>
);

interface ComponentGroupProps {
  title: string;
  children: React.ReactNode;
  id?: string;
}

const ComponentGroup: React.FC<ComponentGroupProps> = ({ title, children, id }) => (
  <div id={id} className="space-y-4">
    <h3 className="text-lg font-medium text-neutral-content">{title}</h3>
    <div className="space-y-4">
      {children}
    </div>
  </div>
);

interface ColorPaletteProps {
  title: string;
  colors: { name: string; value: string; }[];
  id?: string;
}

const ColorPalette = ({ title, colors, id }: ColorPaletteProps) => (
  <div id={id} className="space-y-4">
    <h3 className="text-lg font-medium text-neutral-content">{title}</h3>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {colors.map((color) => (
        <div key={color.name} className="flex items-center space-x-4">
          <div 
            className="w-16 h-16 rounded-lg border border-neutral-border"
            style={{ backgroundColor: color.value }}
          />
          <div className="space-y-1">
            <p className="font-medium text-neutral-content">{color.name}</p>
            <p className="text-sm text-neutral-content/80">{color.value}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
);

interface TypographyShowcaseProps {
  title: string;
  items: { name: string; size: string; lineHeight: string; letterSpacing: string; }[];
  id?: string;
}

const TypographyShowcase = ({ title, items, id }: TypographyShowcaseProps) => (
  <div id={id} className="space-y-4">
    <h3 className="text-lg font-medium text-neutral-content">{title}</h3>
    <div className="space-y-8">
      {items.map((item) => (
        <div key={item.name} className="space-y-2">
          <div className="flex items-baseline justify-between border-b border-neutral-border pb-2">
            <span className="text-sm font-medium text-neutral-content">{item.name}</span>
            <div className="flex items-center gap-4 text-sm text-neutral-content/80">
              <div>
                <span className="text-orchid-400">size</span> / {item.size}
              </div>
              <div>
                <span className="text-orchid-400">line-height</span> / {item.lineHeight}
              </div>
              <div>
                <span className="text-orchid-400">letter-spacing</span> / {item.letterSpacing}
              </div>
            </div>
          </div>
          <div className={cn(
            "text-neutral-content",
            item.name === 'h1' && 'text-[64px] leading-[64px]',
            item.name === 'h2' && 'text-[48px] leading-[48px]',
            item.name === 'h3' && 'text-[32px] leading-[36px]',
            item.name === 'h4' && 'text-[24px] leading-[28px]',
            item.name === 'h5' && 'text-[18px] leading-[24px]',
            item.name === 'h6' && 'text-[16px] leading-[20px]',
            item.name === 'BodyReg-default' && 'text-[16px] leading-[20px]',
            item.name === 'BodyReg-sm' && 'text-[14px] leading-[20px]',
            item.name === 'BodyReg-xs' && 'text-[12px] leading-[16px]',
            item.name === 'BodyBold-default' && 'text-[16px] leading-[20px] font-bold',
            item.name === 'BodyBold-sm' && 'text-[14px] leading-[20px] font-bold',
            item.name === 'BodyBold-xs' && 'text-[12px] leading-[16px] font-bold'
          )}>
            {item.name === 'h1' && 'Heading 1'}
            {item.name === 'h2' && 'Heading 2'}
            {item.name === 'h3' && 'Heading 3'}
            {item.name === 'h4' && 'Heading 4'}
            {item.name === 'h5' && 'Heading 5'}
            {item.name === 'h6' && 'Heading 6'}
            {item.name.startsWith('Body') && 'The quick brown fox jumps over the lazy dog'}
          </div>
        </div>
      ))}
    </div>
  </div>
);

const MenuExample = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<string | null>('settings');

  return (
    <div className="space-y-8">
      <div>
        <h4 className="text-sm font-medium text-neutral-content/80 mb-4">Default Menu</h4>
        <div className="relative inline-block">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="px-4 py-2 text-sm text-neutral-content bg-neutral-background border border-neutral-border rounded hover:bg-neutral-background-hovered"
          >
            Click to {isOpen ? 'close' : 'open'} menu
          </button>
          {isOpen && (
            <div className="absolute left-0 mt-2">
              <Menu title="My Account">
                <MenuItem 
                  icon={<User />} 
                  label="Profile" 
                  selected={selectedItem === 'profile'}
                  onClick={() => setSelectedItem('profile')}
                />
                <MenuItem 
                  icon={<Settings />} 
                  label="Settings" 
                  selected={selectedItem === 'settings'}
                  onClick={() => setSelectedItem('settings')}
                />
                <MenuItem 
                  label="Help Center" 
                  selected={selectedItem === 'help'}
                  onClick={() => setSelectedItem('help')}
                />
                <MenuItem 
                  label="Keyboard Shortcuts" 
                  shortcut="⌘K"
                  selected={selectedItem === 'shortcuts'}
                  onClick={() => setSelectedItem('shortcuts')}
                />
                <MenuDivider />
                <MenuItem 
                  icon={<LogOut />} 
                  label="Log Out" 
                  disabled
                />
              </Menu>
            </div>
          )}
        </div>
      </div>

      <div>
        <h4 className="text-sm font-medium text-neutral-content/80 mb-4">Menu without Title</h4>
        <Menu>
          <MenuItem label="Copy" shortcut="⌘C" />
          <MenuItem label="Cut" shortcut="⌘X" />
          <MenuItem label="Paste" shortcut="⌘V" />
          <MenuItem label="Delete" disabled />
        </Menu>
      </div>
    </div>
  );
};

const ModalExamples = () => {
  const [basicOpen, setBasicOpen] = useState(false);
  const [footerOpen, setFooterOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const dropdownOptions = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
  ];

  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-semibold mb-4">Modal</h2>
      
      <div className="flex gap-4">
        <Button onClick={() => setBasicOpen(true)}>Basic Modal</Button>
        <Button onClick={() => setFooterOpen(true)}>Footer Actions</Button>
        <Button onClick={() => setDropdownOpen(true)}>With Dropdown</Button>
      </div>

      {/* Basic Modal */}
      <Modal
        open={basicOpen}
        onClose={() => setBasicOpen(false)}
        title="Basic Modal"
      >
        <p>This is a basic modal with just content.</p>
      </Modal>

      {/* Footer Actions Modal */}
      <Modal
        open={footerOpen}
        onClose={() => setFooterOpen(false)}
        title="Footer Actions"
        footer={
          <>
            <Button variant="plain" onClick={() => setFooterOpen(false)}>
              Cancel
            </Button>
            <Button onClick={() => setFooterOpen(false)}>
              Confirm
            </Button>
          </>
        }
      >
        <p>This modal has footer actions.</p>
      </Modal>

      {/* Dropdown Modal */}
      <Modal
        open={dropdownOpen}
        onClose={() => setDropdownOpen(false)}
        title="With Dropdown"
        footer={
          <>
            <Button variant="plain" onClick={() => setDropdownOpen(false)}>
              Cancel
            </Button>
            <Button onClick={() => setDropdownOpen(false)}>
              Save Changes
            </Button>
          </>
        }
      >
        <div className="space-y-4">
          <Dropdown
            label="Label"
            helperText="Helper text goes here."
            value={selectedOption}
            onChange={setSelectedOption}
            options={dropdownOptions}
            placeholderText="Select an option"
            showHelperText
            showLabel
          />
        </div>
      </Modal>
    </div>
  );
};

const RadioExamples = () => {
  const [selected, setSelected] = useState('option1');

  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-semibold mb-4">Radio Button</h2>
      
      <div className="flex flex-col gap-4 bg-neutral-background p-8 rounded-lg border border-neutral-border">
        <div>
          <h3 className="text-sm font-medium text-neutral-content mb-4">Default</h3>
          <Radio
            name="example1"
            label="Label"
            value="option1"
            checked={false}
            onChange={(e) => setSelected(e.target.value)}
          />
        </div>

        <div>
          <h3 className="text-sm font-medium text-neutral-content mb-4">Checked</h3>
          <Radio
            name="example1"
            label="Label"
            value="option2"
            checked={true}
            onChange={(e) => setSelected(e.target.value)}
          />
        </div>

        <div>
          <h3 className="text-sm font-medium text-neutral-content mb-4">Disabled</h3>
          <Radio
            name="example2"
            label="Label"
            disabled
            checked={false}
            value="disabled"
          />
        </div>

        <div>
          <h3 className="text-sm font-medium text-neutral-content mb-4">Disabled Checked</h3>
          <Radio
            name="example2"
            label="Label"
            disabled
            checked={true}
            value="disabled-checked"
          />
        </div>
      </div>

      {/* Interactive Example */}
      <div className="mt-8 bg-neutral-background p-8 rounded-lg border border-neutral-border">
        <h3 className="text-sm font-medium text-neutral-content mb-4">Interactive Example</h3>
        <div className="space-y-2">
          <Radio
            name="interactive"
            label="Option 1"
            value="option1"
            checked={selected === 'option1'}
            onChange={(e) => setSelected(e.target.value)}
          />
          <Radio
            name="interactive"
            label="Option 2"
            value="option2"
            checked={selected === 'option2'}
            onChange={(e) => setSelected(e.target.value)}
          />
          <Radio
            name="interactive"
            label="Option 3"
            value="option3"
            checked={selected === 'option3'}
            onChange={(e) => setSelected(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

const SwitchExamples = () => {
  const [checked, setChecked] = useState(false);

  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-semibold mb-4">Switch</h2>
      
      <div className="flex flex-col gap-4 bg-neutral-background p-8 rounded-lg border border-neutral-border">
        <div>
          <h3 className="text-sm font-medium text-neutral-content mb-4">Default</h3>
          <Switch
            checked={checked}
            onCheckedChange={setChecked}
            label="Label"
          />
        </div>

        <div>
          <h3 className="text-sm font-medium text-neutral-content mb-4">Selected</h3>
          <Switch
            checked={true}
            onCheckedChange={() => {}}
          />
        </div>

        <div>
          <h3 className="text-sm font-medium text-neutral-content mb-4">Disabled</h3>
          <Switch
            checked={false}
            onCheckedChange={() => {}}
            disabled={true}
          />
        </div>
      </div>

      {/* Interactive Example */}
      <div className="mt-8 bg-neutral-background p-8 rounded-lg border border-neutral-border">
        <h3 className="text-sm font-medium text-neutral-content mb-4">Interactive Example</h3>
        <div className="flex items-center gap-2">
          <Switch
            checked={checked}
            onCheckedChange={setChecked}
            label="Toggle me"
          />
          <span className="text-sm text-neutral-content">
            {checked ? 'On' : 'Off'}
          </span>
        </div>
      </div>
    </div>
  );
};

const ElevationShowcase = () => {
  const { theme } = useTheme();
  
  return (
    <div className="space-y-4">
      <div className="space-y-6">
        <div className="grid grid-cols-3 gap-8">
          {[
            { token: 'none', height: '0 dp' },
            { token: '$elevation-lightmode-xs', height: '1 dp' },
            { token: '$elevation-lightmode-s', height: '4 dp' },
            { token: '$elevation-lightmode-m', height: '8 dp' },
            { token: '$elevation-lightmode-l', height: '16 dp' }
          ].map(({ token, height }) => (
            <div key={token} className="space-y-2">
              <div className="flex justify-between text-sm">
                <span className="text-[#FF00FF]">{token}</span>
                <span className="text-[#FF00FF]">{height}</span>
              </div>
              <div 
                className={cn(
                  "w-full aspect-square rounded-2xl bg-neutral-background",
                  theme === 'light' ? [
                    token === 'none' && 'shadow-none',
                    token === '$elevation-lightmode-xs' && 'shadow-[0_1px_3px_0px_rgba(0,0,0,0.10)]',
                    token === '$elevation-lightmode-s' && 'shadow-[0_4px_6px_0px_rgba(0,0,0,0.10)]',
                    token === '$elevation-lightmode-m' && 'shadow-[0_8px_8px_0px_rgba(0,0,0,0.10)]',
                    token === '$elevation-lightmode-l' && 'shadow-[0_16px_24px_0px_rgba(0,0,0,0.10)]'
                  ] : [
                    token === 'none' && 'shadow-none bg-[#27272A]',
                    token === '$elevation-lightmode-xs' && 'shadow-[0_1px_3px_0px_rgba(0,0,0,0.50)] bg-[#27272A]',
                    token === '$elevation-lightmode-s' && 'shadow-[0_4px_6px_0px_rgba(0,0,0,0.50)] bg-[#27272A]',
                    token === '$elevation-lightmode-m' && 'shadow-[0_8px_8px_0px_rgba(0,0,0,0.50)] bg-[#27272A]',
                    token === '$elevation-lightmode-l' && 'shadow-[0_16px_24px_0px_rgba(0,0,0,0.50)] bg-[#27272A]'
                  ]
                )}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const TableOfContents = () => {
  const sections = [
    {
      title: 'Colors',
      id: 'colors',
      subsections: [
        { title: 'Gray', id: 'gray' },
        { title: 'Crimson', id: 'crimson' },
        { title: 'Aqua', id: 'aqua' },
        { title: 'Indigo', id: 'indigo' },
        { title: 'Steel Blue', id: 'steel-blue' },
        { title: 'Spring Green', id: 'spring-green' },
        { title: 'Lemon', id: 'lemon' },
        { title: 'Amber', id: 'amber' },
        { title: 'Orchid', id: 'orchid' }
      ]
    },
    {
      title: 'Typography',
      id: 'typography',
      subsections: [
        { title: 'Headings', id: 'headings' },
        { title: 'Body', id: 'body' }
      ]
    },
    {
      title: 'Dimension',
      id: 'dimension',
      subsections: [
        { title: 'Spacers', id: 'spacers' },
        { title: 'Radius', id: 'radius' },
        { title: 'Line', id: 'line' },
        { title: 'Layouts', id: 'layouts' }
      ]
    },
    {
      title: 'Elevation',
      id: 'elevation',
      subsections: []
    },
    {
      title: 'Components',
      id: 'components',
      subsections: [
        { title: 'Avatars', id: 'avatars' },
        { title: 'Banners', id: 'banners' },
        { title: 'Button', id: 'button' },
        { title: 'Checkbox', id: 'checkbox' },
        { title: 'Dividers', id: 'dividers' },
        { title: 'Dropdown', id: 'dropdown' },
        { title: 'Expandable Section', id: 'expandable-section' },
        { title: 'Menu', id: 'menu' },
        { title: 'Modal', id: 'modal' },
        { title: 'Radio', id: 'radio' },
        { title: 'Switch', id: 'switch' },
        { title: 'Tabs', id: 'tabs' },
        { title: 'Text Input', id: 'text-input' }
      ]
    }
  ];

  const scrollToSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="w-64 h-[calc(100vh-2rem)] overflow-y-auto sticky top-4">
      <div className="bg-neutral-surface/50 rounded-lg p-4">
        <h2 className="text-lg font-semibold text-neutral-content mb-4">Table of Contents</h2>
        <nav className="space-y-4">
          {sections.map((section) => (
            <div key={section.title} className="space-y-2">
              <button
                onClick={() => scrollToSection(section.id)}
                className="text-sm font-medium text-neutral-content hover:text-primary transition-colors"
              >
                {section.title}
              </button>
              {section.subsections.length > 0 && (
                <div className="pl-4 space-y-2 border-l border-neutral-border">
                  {section.subsections.map((subsection) => (
                    <button
                      key={subsection.title}
                      onClick={() => scrollToSection(subsection.id)}
                      className="block text-sm text-neutral-content/80 hover:text-primary transition-colors"
                    >
                      {subsection.title}
                    </button>
                  ))}
                </div>
              )}
            </div>
          ))}
        </nav>
      </div>
    </div>
  );
};

const DividerExamples = () => {
  return (
    <div className="space-y-4">
      {/* Interactive Example */}
      <div>
        <h4 className="text-sm font-medium text-neutral-content/80 mb-3">Interactive Example</h4>
        <div className="p-4 border border-neutral-border rounded-lg bg-neutral-background">
          <div className="space-y-4">
            <div>
              <p className="text-sm text-neutral-muted mb-2">Default (1x)</p>
              <div className="bg-neutral-surface p-4">
                <Divider />
              </div>
            </div>
            <div>
              <p className="text-sm text-neutral-muted mb-2">Medium (2x)</p>
              <div className="bg-neutral-surface p-4">
                <Divider size="2x" />
              </div>
            </div>
            <div>
              <p className="text-sm text-neutral-muted mb-2">Large (4x)</p>
              <div className="bg-neutral-surface p-4">
                <Divider size="4x" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* States */}
      <div>
        <h4 className="text-sm font-medium text-neutral-content/80 mb-3">States</h4>
        <div className="p-4 border border-neutral-border rounded-lg bg-neutral-background">
          <div className="space-y-4">
            <div>
              <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Horizontal</h3>
              <div className="space-y-8">
                <div>
                  <p className="text-sm text-neutral-muted mb-2">Default</p>
                  <Divider />
                </div>
                <div>
                  <p className="text-sm text-neutral-muted mb-2">With margin</p>
                  <Divider className="my-4" />
                </div>
              </div>
            </div>
            <div>
              <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Vertical</h3>
              <div className="flex items-center h-16 space-x-8">
                <div className="flex items-center h-full">
                  <p className="text-sm text-neutral-muted mr-2">Default</p>
                  <Divider type="vertical" />
                </div>
                <div className="flex items-center h-full">
                  <p className="text-sm text-neutral-muted mr-2">With margin</p>
                  <Divider type="vertical" className="mx-4" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function DesignSystem() {
  const { theme } = useTheme();
  const [selected, setSelected] = useState('option1');
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuSelected, setMenuSelected] = useState<string | null>('settings');
  const [modalOpen, setModalOpen] = useState(false);
  const [basicModalOpen, setBasicModalOpen] = useState(false);
  const [footerModalOpen, setFooterModalOpen] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const [bannerOpen, setBannerOpen] = useState(true);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  
  const dropdownOptions = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
  ];

  interface DateRange {
    from: Date;
    to: Date;
  }

  const [dateRange, setDateRange] = useState<DateRange>({
    from: new Date(new Date().setDate(new Date().getDate() - 7)),
    to: new Date()
  });

  const colors = {
    gray: [
      { name: 'Gray 50', value: '#F9FAFB' },
      { name: 'Gray 100', value: '#F3F4F6' },
      { name: 'Gray 200', value: '#E5E7EB' },
      { name: 'Gray 300', value: '#D1D5DB' },
      { name: 'Gray 400', value: '#9CA3AF' },
      { name: 'Gray 500', value: '#6B7280' },
      { name: 'Gray 600', value: '#4B5563' },
      { name: 'Gray 700', value: '#374151' },
      { name: 'Gray 800', value: '#1F2937' },
      { name: 'Gray 900', value: '#111827' }
    ],
    crimson: [
      { name: 'Crimson 25', value: '#FBE2E3' },
      { name: 'Crimson 50', value: '#FAD7D8' },
      { name: 'Crimson 75', value: '#F7CBCC' },
      { name: 'Crimson 100', value: '#F5B8BA' },
      { name: 'Crimson 200', value: '#F49496' },
      { name: 'Crimson 300', value: '#F37377' },
      { name: 'Crimson 400', value: '#EB5154' },
      { name: 'Crimson 500', value: '#DC2C2C' },
      { name: 'Crimson 600', value: '#B91C1C' },
      { name: 'Crimson 700', value: '#991B1B' },
      { name: 'Crimson 800', value: '#7F1D1D' },
      { name: 'Crimson 900', value: '#450A0A' }
    ],
    aqua: [
      { name: 'Aqua 25', value: '#DFFFF8' },
      { name: 'Aqua 50', value: '#C7FFEC' },
      { name: 'Aqua 75', value: '#B0FFF8' },
      { name: 'Aqua 100', value: '#98FFF5' },
      { name: 'Aqua 200', value: '#31D5DB' },
      { name: 'Aqua 300', value: '#22D1D6' },
      { name: 'Aqua 400', value: '#1AB9C1' },
      { name: 'Aqua 500', value: '#0B92C1' },
      { name: 'Aqua 600', value: '#0772A1' },
      { name: 'Aqua 700', value: '#035C81' },
      { name: 'Aqua 800', value: '#014161' },
      { name: 'Aqua 900', value: '#00253B' }
    ],
    indigo: [
      { name: 'Indigo 25', value: '#F8F8FE' },
      { name: 'Indigo 50', value: '#ECECFE' },
      { name: 'Indigo 75', value: '#E0E0FE' },
      { name: 'Indigo 100', value: '#D6D6FE' },
      { name: 'Indigo 200', value: '#C0C0FE' },
      { name: 'Indigo 300', value: '#9898FD' },
      { name: 'Indigo 400', value: '#7B7BFC' },
      { name: 'Indigo 500', value: '#5656FA' },
      { name: 'Indigo 600', value: '#3535D2' },
      { name: 'Indigo 700', value: '#1717B3' },
      { name: 'Indigo 800', value: '#12129E' },
      { name: 'Indigo 900', value: '#0E0E83' }
    ],
    steelBlue: [
      { name: 'Steel Blue 25', value: '#E5F9FC' },
      { name: 'Steel Blue 50', value: '#D8F7FC' },
      { name: 'Steel Blue 75', value: '#CFEEF2' },
      { name: 'Steel Blue 100', value: '#C7D8EC' },
      { name: 'Steel Blue 200', value: '#7BDBEB' },
      { name: 'Steel Blue 300', value: '#5BD7EB' },
      { name: 'Steel Blue 400', value: '#37D7EB' },
      { name: 'Steel Blue 500', value: '#1AB9C1' },
      { name: 'Steel Blue 600', value: '#0772A1' },
      { name: 'Steel Blue 700', value: '#035C81' },
      { name: 'Steel Blue 800', value: '#014161' },
      { name: 'Steel Blue 900', value: '#00253B' }
    ],
    springGreen: [
      { name: 'Spring Green 25', value: '#E8FFA4' },
      { name: 'Spring Green 50', value: '#CAFF89' },
      { name: 'Spring Green 75', value: '#A7FCA4' },
      { name: 'Spring Green 100', value: '#94FF8F' },
      { name: 'Spring Green 200', value: '#80DC7B' },
      { name: 'Spring Green 300', value: '#25D865' },
      { name: 'Spring Green 400', value: '#15D865' },
      { name: 'Spring Green 500', value: '#0FB851' },
      { name: 'Spring Green 600', value: '#0A9B32' },
      { name: 'Spring Green 700', value: '#0A8132' },
      { name: 'Spring Green 800', value: '#0A6532' },
      { name: 'Spring Green 900', value: '#0A4B32' }
    ],
    lemon: [
      { name: 'Lemon 25', value: '#FFFBD0' },
      { name: 'Lemon 50', value: '#FCF472' },
      { name: 'Lemon 75', value: '#FAF2A4' },
      { name: 'Lemon 100', value: '#FAF410' },
      { name: 'Lemon 200', value: '#C1C22C' },
      { name: 'Lemon 300', value: '#A1A821' },
      { name: 'Lemon 400', value: '#717517' },
      { name: 'Lemon 500', value: '#505200' },
      { name: 'Lemon 600', value: '#3D3C00' },
      { name: 'Lemon 700', value: '#313300' },
      { name: 'Lemon 800', value: '#252800' },
      { name: 'Lemon 900', value: '#1A1A00' }
    ],
    amber: [
      { name: 'Amber 25', value: '#FBEAD7' },
      { name: 'Amber 50', value: '#FBE1B0' },
      { name: 'Amber 75', value: '#FBD880' },
      { name: 'Amber 100', value: '#FBD248' },
      { name: 'Amber 200', value: '#FBE023' },
      { name: 'Amber 300', value: '#FBDE1A' },
      { name: 'Amber 400', value: '#D7B512' },
      { name: 'Amber 500', value: '#715B00' },
      { name: 'Amber 600', value: '#715500' },
      { name: 'Amber 700', value: '#523C00' },
      { name: 'Amber 800', value: '#3A2800' },
      { name: 'Amber 900', value: '#1A1200' }
    ],
    orchid: [
      { name: 'Orchid 25', value: '#F9E8FA' },
      { name: 'Orchid 50', value: '#F8E0FA' },
      { name: 'Orchid 75', value: '#F8E1FA' },
      { name: 'Orchid 100', value: '#F7D7FA' },
      { name: 'Orchid 200', value: '#F7D8FA' },
      { name: 'Orchid 300', value: '#F4A4FA' },
      { name: 'Orchid 400', value: '#D331E1' },
      { name: 'Orchid 500', value: '#9D21A5' },
      { name: 'Orchid 600', value: '#66126D' },
      { name: 'Orchid 700', value: '#3A0A3A' },
      { name: 'Orchid 800', value: '#2A082A' },
      { name: 'Orchid 900', value: '#1D051D' }
    ]
  };

  const typography = {
    headings: [
      { name: 'h1', size: '64', lineHeight: '64', letterSpacing: '0%' },
      { name: 'h2', size: '48', lineHeight: '48', letterSpacing: '0%' },
      { name: 'h3', size: '32', lineHeight: '36', letterSpacing: '0%' },
      { name: 'h4', size: '24', lineHeight: '28', letterSpacing: '0%' },
      { name: 'h5', size: '18', lineHeight: '24', letterSpacing: '0%' },
      { name: 'h6', size: '16', lineHeight: '20', letterSpacing: '0%' }
    ],
    body: [
      { name: 'BodyReg-default', size: '16', lineHeight: '20', letterSpacing: '0%' },
      { name: 'BodyReg-sm', size: '14', lineHeight: '20', letterSpacing: '0%' },
      { name: 'BodyReg-xs', size: '12', lineHeight: '16', letterSpacing: '0%' },
      { name: 'BodyBold-default', size: '16', lineHeight: '20', letterSpacing: '0%' },
      { name: 'BodyBold-sm', size: '14', lineHeight: '20', letterSpacing: '0%' },
      { name: 'BodyBold-xs', size: '12', lineHeight: '16', letterSpacing: '0%' }
    ]
  };

  const [selectedValue, setSelectedValue] = useState('');

  return (
    <div className="min-h-screen bg-neutral-background">
      <div className="max-w-[1440px] mx-auto px-8 py-12">
        <div className="mb-12">
          <div className="space-y-2">
            <h1 className="text-4xl font-bold text-neutral-content">Design System</h1>
            <p className="text-lg text-neutral-content/80">A comprehensive guide to our design language</p>
          </div>
        </div>

        <div className="flex gap-8">
          <TableOfContents />
          
          <div className="flex-1 space-y-12">
            <ComponentShowcase title="Colors" id="colors">
              <div className="space-y-8">
                <ColorPalette title="Gray" id="gray" colors={colors.gray} />
                <ColorPalette title="Crimson" id="crimson" colors={colors.crimson} />
                <ColorPalette title="Aqua" id="aqua" colors={colors.aqua} />
                <ColorPalette title="Indigo" id="indigo" colors={colors.indigo} />
                <ColorPalette title="Steel Blue" id="steel-blue" colors={colors.steelBlue} />
                <ColorPalette title="Spring Green" id="spring-green" colors={colors.springGreen} />
                <ColorPalette title="Lemon" id="lemon" colors={colors.lemon} />
                <ColorPalette title="Amber" id="amber" colors={colors.amber} />
                <ColorPalette title="Orchid" id="orchid" colors={colors.orchid} />
              </div>
            </ComponentShowcase>

            <ComponentShowcase 
              title="Typography" 
              id="typography"
              description="At ProductSense.ai, we use Inter — a variable open font family carefully crafted and designed for computer screens."
            >
              <div className="space-y-8">
                <TypographyShowcase title="Headings" id="headings" items={typography.headings} />
                <TypographyShowcase title="Body" id="body" items={typography.body} />
              </div>
            </ComponentShowcase>

            <ComponentShowcase title="Dimension" id="dimension">
              <div className="space-y-8">
                {/* Spacers */}
                <div className="space-y-4" id="spacers">
                  <h3 className="text-lg font-medium text-neutral-content">Spacers</h3>
                  <div className="space-y-8">
                    {/* Horizontal Spacers */}
                    <div className="space-y-4">
                      <h4 className="text-sm font-medium text-neutral-content/80">Horizontal</h4>
                      <div className="space-y-6">
                        {[1, 2, 3, 4, 5, 6, 8, 10, 12, 16].map((size) => (
                          <div key={size} className="flex items-center gap-4">
                            <div className="w-32 text-sm text-neutral-content/80">${`spacerH-${size}x`}</div>
                            <div className="w-16 text-sm text-neutral-content/80">{size * 4}dp</div>
                            <div className={`h-1 bg-neutral-content`} style={{ width: size * 4 }} />
                          </div>
                        ))}
                      </div>
                    </div>
                    
                    {/* Vertical Spacers */}
                    <div className="space-y-4">
                      <h4 className="text-sm font-medium text-neutral-content/80">Vertical</h4>
                      <div className="flex gap-12">
                        {[1, 2, 3, 4, 5, 6, 8, 10, 12, 16].map((size) => (
                          <div key={size} className="flex flex-col items-center">
                            <div className="text-sm text-neutral-content/80">${`spacerV-${size}x`}</div>
                            <div className="text-sm text-neutral-content/80">{size * 4}dp</div>
                            <div className={`w-1 bg-neutral-content`} style={{ height: size * 4 }} />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Radius */}
                <div className="space-y-4" id="radius">
                  <h3 className="text-lg font-medium text-neutral-content">Radius</h3>
                  <div className="grid grid-cols-2 gap-8">
                    {[
                      { name: 'radius-xs', size: 4 },
                      { name: 'radius-s', size: 8 },
                      { name: 'radius-md', size: 16 },
                      { name: 'radius-lg', size: 32 },
                      { name: 'radius-xl', size: 64 }
                    ].map(({ name, size }) => (
                      <div key={name} className="flex items-center gap-4">
                        <div className="w-32 text-sm text-neutral-content/80">${name}</div>
                        <div className="w-16 text-sm text-neutral-content/80">{size}</div>
                        <div 
                          className="w-32 h-32 border border-neutral-content" 
                          style={{ borderRadius: size }}
                        />
                      </div>
                    ))}
                  </div>
                </div>

                {/* Line */}
                <div className="space-y-4" id="line">
                  <h3 className="text-lg font-medium text-neutral-content">Line</h3>
                  <div className="space-y-6">
                    {[
                      { name: 'line-s', size: 1 },
                      { name: 'line-m', size: 2 },
                      { name: 'line-l', size: 4 }
                    ].map(({ name, size }) => (
                      <div key={name} className="flex items-center gap-4">
                        <div className="w-32 text-sm text-neutral-content/80">${name}</div>
                        <div className="w-16 text-sm text-neutral-content/80">{size}dp</div>
                        <div className="w-32 bg-neutral-content" style={{ height: size }} />
                      </div>
                    ))}
                  </div>
                </div>

                {/* Layouts */}
                <div className="space-y-4" id="layouts">
                  <h3 className="text-lg font-medium text-neutral-content">Layouts</h3>
                  <div className="space-y-4">
                    <div>
                      <h4 className="text-lg font-medium text-neutral-content mb-4">Spacing system</h4>
                      <p className="text-neutral-content/80">Linear: y = x + 8</p>
                      <h5 className="text-base font-medium text-neutral-content mt-4 mb-2">Best practices</h5>
                      <ul className="list-disc pl-6 space-y-2 text-neutral-content/80">
                        <li>Use multiples of 8px when defining measurements, spacing, and positioning elements.</li>
                        <li>When necessary use 4px to make more fine-tuned adjustments.</li>
                        <li>Whenever possible, make sure that objects line up, both vertically and horizontally.</li>
                        <li>Align your bounding box to the grid, not the baseline of your text.</li>
                      </ul>
                    </div>

                    <div>
                      <h4 className="text-lg font-medium text-neutral-content mb-4">Responsive grid</h4>
                      <p className="text-neutral-content/80">12 columns</p>
                      <p className="text-neutral-content/80 mt-2">A 12 columns responsive grid is applied to the entire interface. Use fixed margin of 48px and fixed Gutters of 16px to ensure consistencies in our alignment. The column width varies depending on screen sizes, and adjusts to fill the space up to 1440px screen size. Any screen above 1440px, we'll fix the 12-columns to a width of 1344px and center the alignment.</p>
                    </div>

                    <div>
                      <h4 className="text-lg font-medium text-neutral-content mb-4">Layouts defined</h4>
                      <p className="text-neutral-content/80 mb-4">Includes breakpoints for desktop, laptop, tablet, and mobile.</p>
                      
                      {/* Desktop, Large */}
                      <div className="space-y-2 mb-6">
                        <h5 className="text-base font-medium text-neutral-content">Desktop, Large</h5>
                        <p className="text-neutral-content/80">1440px+</p>
                        <div className="space-y-1 text-neutral-content/80">
                          <div className="flex items-center gap-2">
                            <div className="w-3 h-3 rounded-full bg-neutral-content" />
                            <span>Margin: 48px</span>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="w-3 h-3 rounded-full bg-neutral-content" />
                            <span>Columns: 12</span>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="w-3 h-3 rounded-full bg-neutral-content" />
                            <span>Gutters: 16px</span>
                          </div>
                        </div>
                      </div>

                      {/* Laptop, M */}
                      <div className="space-y-2">
                        <h5 className="text-base font-medium text-neutral-content">Laptop, M</h5>
                        <p className="text-neutral-content/80">1240 - 1439px</p>
                        <div className="space-y-1 text-neutral-content/80">
                          <div className="flex items-center gap-2">
                            <div className="w-3 h-3 rounded-full bg-neutral-content" />
                            <span>Margin: 48px</span>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="w-3 h-3 rounded-full bg-neutral-content" />
                            <span>Columns: 12</span>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="w-3 h-3 rounded-full bg-neutral-content" />
                            <span>Gutters: 16px</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ComponentShowcase>

            <ComponentShowcase title="Elevation" id="elevation">
              <ElevationShowcase />
            </ComponentShowcase>

            <ComponentShowcase title="Components" id="components">
              <div className="space-y-8">
                <ComponentGroup title="Avatars" id="avatars">
                  <div className="space-y-4">
                    {/* Interactive Example */}
                    <div>
                      <h4 className="text-sm font-medium text-neutral-content/80 mb-3">Interactive Example</h4>
                      <div className="p-4 border border-neutral-border rounded-lg bg-neutral-background">
                        <div className="flex items-center gap-4">
                          <Avatar size="md-40" src="/assets/avatar.svg" fallback="JD" />
                          <Avatar size="md-40" fallback="RF" />
                        </div>
                      </div>
                    </div>

                    {/* States */}
                    <div>
                      <h4 className="text-sm font-medium text-neutral-content/80 mb-3">States</h4>
                      <div className="p-4 border border-neutral-border rounded-lg bg-neutral-background">
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Image Avatars</h3>
                            <div className="flex items-center gap-4">
                              <Avatar size="xl-64" src="/assets/avatar.svg" fallback="RF" />
                              <Avatar size="lg-48" src="/assets/avatar.svg" fallback="RF" />
                              <Avatar size="md-40" src="/assets/avatar.svg" fallback="RF" />
                              <Avatar size="sm-24" src="/assets/avatar.svg" fallback="RF" />
                              <Avatar size="xs-16" src="/assets/avatar.svg" fallback="RF" />
                            </div>
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Fallback Avatars</h3>
                            <div className="flex items-center gap-4">
                              <Avatar size="xl-64" fallback="RF" />
                              <Avatar size="lg-48" fallback="RF" />
                              <Avatar size="md-40" fallback="RF" />
                              <Avatar size="sm-24" fallback="RF" />
                              <Avatar size="xs-16" fallback="RF" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ComponentGroup>

                <ComponentGroup title="Banners" id="banners">
                  <div className="space-y-4">
                    {/* Interactive Example */}
                    <div>
                      <h4 className="text-sm font-medium text-neutral-content/80 mb-3">Interactive Example</h4>
                      <div className="p-4 border border-neutral-border rounded-lg bg-neutral-background">
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Info</h3>
                            <Banner
                              variant="info"
                              title="Information Banner"
                              description="This is an informational message."
                            />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Success</h3>
                            <Banner
                              variant="success"
                              title="Success Banner"
                              description="Action completed successfully."
                            />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Warning</h3>
                            <Banner
                              variant="warning"
                              title="Warning Banner"
                              description="Please review this warning."
                            />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Error</h3>
                            <Banner
                              variant="error"
                              title="Error Banner"
                              description="An error occurred."
                            />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Neutral</h3>
                            <Banner
                              variant="neutral"
                              title="Neutral Banner"
                              description="A neutral message."
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* States */}
                    <div>
                      <h4 className="text-sm font-medium text-neutral-content/80 mb-3">States</h4>
                      <div className="p-4 border border-neutral-border rounded-lg bg-neutral-background">
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Default</h3>
                            <MenuItem label="Default Item" />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Selected</h3>
                            <MenuItem label="Selected Item" selected />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">With Icon</h3>
                            <MenuItem icon={<Settings />} label="Settings" />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">With Shortcut</h3>
                            <MenuItem label="Copy" shortcut="⌘C" />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Disabled</h3>
                            <MenuItem label="Disabled Item" disabled />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ComponentGroup>

                <ComponentGroup title="Button" id="button">
                  <div className="space-y-4">
                    {/* Interactive Example */}
                    <div>
                      <h4 className="text-sm font-medium text-neutral-content/80 mb-3">Interactive Example</h4>
                      <div className="p-4 border border-neutral-border rounded-lg bg-neutral-background">
                        <div className="flex flex-wrap items-center gap-4">
                          <Button onClick={() => alert('Button clicked!')}>Click me</Button>
                          <Button variant="secondary" isLoading>Loading</Button>
                        </div>
                      </div>
                    </div>

                    {/* States */}
                    <div>
                      <h4 className="text-sm font-medium text-neutral-content/80 mb-3">States</h4>
                      <div className="p-4 border border-neutral-border rounded-lg bg-neutral-background">
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Primary</h3>
                            <Button variant="primary">Primary</Button>
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Secondary</h3>
                            <Button variant="secondary">Secondary</Button>
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Plain</h3>
                            <Button variant="plain">Plain</Button>
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Bordered</h3>
                            <Button variant="bordered">Bordered</Button>
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Destructive</h3>
                            <Button variant="destructive">Destructive</Button>
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Disabled</h3>
                            <Button disabled>Disabled</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ComponentGroup>

                <ComponentGroup title="Checkbox" id="checkbox">
                  <div className="space-y-4">
                    {/* Interactive Example */}
                    <div>
                      <h4 className="text-sm font-medium text-neutral-content/80 mb-3">Interactive Example</h4>
                      <div className="p-4 border border-neutral-border rounded-lg bg-neutral-background">
                        <Checkbox
                          label="Toggle me"
                          checked={checkboxChecked}
                          onChange={(e) => setCheckboxChecked(e.target.checked)}
                        />
                      </div>
                    </div>

                    {/* States */}
                    <div>
                      <h4 className="text-sm font-medium text-neutral-content/80 mb-3">States</h4>
                      <div className="p-4 border border-neutral-border rounded-lg bg-neutral-background">
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Default</h3>
                            <Checkbox label="Label" checked={false} />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Checked</h3>
                            <Checkbox label="Label" checked={true} />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Disabled</h3>
                            <Checkbox label="Label" disabled checked={false} />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Disabled Checked</h3>
                            <Checkbox label="Label" disabled checked={true} />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Error</h3>
                            <Checkbox label="Label" error />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Indeterminate</h3>
                            <Checkbox label="Label" indeterminate />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ComponentGroup>

                <ComponentGroup title="Divider" id="divider">
                  <div className="space-y-4">
                    <DividerExamples />
                  </div>
                </ComponentGroup>

                <ComponentGroup title="Dropdown" id="dropdown">
                  <div className="space-y-4">
                    {/* Interactive Example */}
                    <div>
                      <h4 className="text-sm font-medium text-neutral-content/80 mb-3">Interactive Example</h4>
                      <div className="p-4 border border-neutral-border rounded-lg bg-neutral-background space-y-6">
                        <div>
                          <h5 className="text-sm font-medium text-neutral-content/80 mb-2">Standard Dropdown</h5>
                          <Dropdown 
                            value={selectedValue}
                            onChange={setSelectedValue}
                            options={[
                              { value: 'option1', label: 'Option 1' },
                              { value: 'option2', label: 'Option 2' },
                              { value: 'option3', label: 'Option 3' },
                            ]}
                            placeholderText="Select an option..."
                          />
                        </div>
                        <div>
                          <h5 className="text-sm font-medium text-neutral-content/80 mb-2">Date Range Dropdown</h5>
                          <DateRangeDropdown
                            value={dateRange}
                            onChange={(range) => {
                              if (range?.from && range?.to) {
                                setDateRange({ from: range.from, to: range.to });
                              }
                            }}
                            onComplete={(range) => {
                              if (range.from && range.to) {
                                setDateRange({ from: range.from, to: range.to });
                              }
                            }}
                            label="Select Date Range"
                          />
                        </div>
                      </div>
                    </div>

                    {/* States */}
                    <div>
                      <h4 className="text-sm font-medium text-neutral-content/80 mb-3">States</h4>
                      <div className="p-4 border border-neutral-border rounded-lg bg-neutral-background">
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Default</h3>
                            <Dropdown
                              showLabel
                              label="Label"
                              placeholderText="Select an option"
                              state="default"
                              className="w-full"
                              options={dropdownOptions}
                            />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Hovered</h3>
                            <Dropdown
                              showLabel
                              label="Label"
                              placeholderText="Select an option"
                              state="hovered"
                              className="w-full"
                              options={dropdownOptions}
                            />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Selected</h3>
                            <Dropdown
                              showLabel
                              label="Label"
                              selectedText="Selected option"
                              state="selected"
                              className="w-full"
                              options={dropdownOptions}
                              value="option1"
                            />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Focused</h3>
                            <Dropdown
                              showLabel
                              label="Label"
                              placeholderText="Select an option"
                              state="focused"
                              className="w-full"
                              options={dropdownOptions}
                            />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Error</h3>
                            <Dropdown
                              showLabel
                              label="Label"
                              placeholderText="Select an option"
                              state="error"
                              showErrorIcon
                              helperText="Error message"
                              showHelperText
                              className="w-full"
                              options={dropdownOptions}
                            />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Success</h3>
                            <Dropdown
                              showLabel
                              label="Label"
                              selectedText="Selected option"
                              state="success"
                              className="w-full"
                              options={dropdownOptions}
                              value="option1"
                            />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Caution</h3>
                            <Dropdown
                              showLabel
                              label="Label"
                              placeholderText="Select an option"
                              state="caution"
                              showCautionIcon
                              helperText="Warning message"
                              showHelperText
                              className="w-full"
                              options={dropdownOptions}
                            />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Disabled</h3>
                            <Dropdown
                              showLabel
                              label="Label"
                              placeholderText="Select an option"
                              state="disabled"
                              className="w-full"
                              options={dropdownOptions}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ComponentGroup>

                <ComponentGroup title="Expandable Section" id="expandable-section">
                  <div className="space-y-4">
                    {/* Interactive Example */}
                    <div>
                      <h4 className="text-sm font-medium text-neutral-content/80 mb-3">Interactive Example</h4>
                      <div className="p-4 border border-neutral-border rounded-lg bg-neutral-background space-y-4">
                        <div>
                          <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Horizontal</h3>
                          <ExpandableSection
                            title="Click to expand"
                            subtitle="Try both variants"
                          >
                            This is the expanded content
                          </ExpandableSection>
                        </div>
                        
                        <div className="border-t border-neutral-subtle">
                          <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Vertical</h3>
                          <VerticalExpandableSection
                            title="Click to expand"
                            subtitle="With avatar"
                            avatar={{
                              src: "/assets/avatar.svg",
                              fallback: "RF"
                            }}
                          >
                            This is the expanded content
                          </VerticalExpandableSection>
                        </div>
                      </div>
                    </div>
                  </div>
                </ComponentGroup>

                <ComponentGroup title="Menu" id="menu">
                  <div className="space-y-4">
                    {/* Interactive Example */}
                    <div>
                      <h4 className="text-sm font-medium text-neutral-content/80 mb-3">Interactive Example</h4>
                      <div className="p-4 border border-neutral-border rounded-lg bg-neutral-background">
                        <div className="relative inline-block">
                          <button
                            onClick={() => setMenuOpen(!menuOpen)}
                            className="px-4 py-2 text-sm text-neutral-content bg-neutral-background border border-neutral-border rounded hover:bg-neutral-background-hovered"
                          >
                            Click to {menuOpen ? 'close' : 'open'} menu
                          </button>
                          {menuOpen && (
                            <div className="absolute left-0 mt-2">
                              <Menu title="My Account">
                                <MenuItem 
                                  icon={<User />} 
                                  label="Profile" 
                                  selected={menuSelected === 'profile'}
                                  onClick={() => setMenuSelected('profile')}
                                />
                                <MenuItem 
                                  icon={<Settings />} 
                                  label="Settings" 
                                  selected={menuSelected === 'settings'}
                                  onClick={() => setMenuSelected('settings')}
                                />
                                <MenuDivider />
                                <MenuItem 
                                  icon={<LogOut />} 
                                  label="Log Out" 
                                  disabled
                                />
                              </Menu>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* States */}
                    <div>
                      <h4 className="text-sm font-medium text-neutral-content/80 mb-3">States</h4>
                      <div className="p-4 border border-neutral-border rounded-lg bg-neutral-background">
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Default</h3>
                            <MenuItem label="Default Item" />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Selected</h3>
                            <MenuItem label="Selected Item" selected />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">With Icon</h3>
                            <MenuItem icon={<Settings />} label="Settings" />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">With Shortcut</h3>
                            <MenuItem label="Copy" shortcut="⌘C" />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Disabled</h3>
                            <MenuItem label="Disabled Item" disabled />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ComponentGroup>

                <ComponentGroup title="Modal" id="modal">
                  <div className="space-y-4">
                    {/* Interactive Example */}
                    <div>
                      <h4 className="text-sm font-medium text-neutral-content/80 mb-3">Interactive Example</h4>
                      <div className="p-4 border border-neutral-border rounded-lg bg-neutral-background">
                        <Button onClick={() => setModalOpen(true)}>Open Modal</Button>
                        <Modal
                          open={modalOpen}
                          onClose={() => setModalOpen(false)}
                          title="Interactive Modal"
                          footer={
                            <>
                              <Button variant="plain" onClick={() => setModalOpen(false)}>
                                Cancel
                              </Button>
                              <Button onClick={() => setModalOpen(false)}>
                                Confirm
                              </Button>
                            </>
                          }
                        >
                          <p>This is an interactive modal example. Try the buttons below.</p>
                        </Modal>
                      </div>
                    </div>

                    {/* States */}
                    <div>
                      <h4 className="text-sm font-medium text-neutral-content/80 mb-3">States</h4>
                      <div className="p-4 border border-neutral-border rounded-lg bg-neutral-background">
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Basic</h3>
                            <Button onClick={() => setBasicModalOpen(true)}>Basic Modal</Button>
                            <Modal
                              open={basicModalOpen}
                              onClose={() => setBasicModalOpen(false)}
                              title="Basic Modal"
                            >
                              <p>Basic modal with just content.</p>
                            </Modal>
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">With Footer</h3>
                            <Button onClick={() => setFooterModalOpen(true)}>Footer Actions</Button>
                            <Modal
                              open={footerModalOpen}
                              onClose={() => setFooterModalOpen(false)}
                              title="With Footer"
                              footer={
                                <>
                                  <Button variant="plain" onClick={() => setFooterModalOpen(false)}>Cancel</Button>
                                  <Button onClick={() => setFooterModalOpen(false)}>Confirm</Button>
                                </>
                              }
                            >
                              <p>Modal with footer actions.</p>
                            </Modal>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ComponentGroup>

                <ComponentGroup title="Radio" id="radio">
                  <div className="space-y-4">
                    {/* Interactive Example */}
                    <div>
                      <h4 className="text-sm font-medium text-neutral-content/80 mb-3">Interactive Example</h4>
                      <div className="p-4 border border-neutral-border rounded-lg bg-neutral-background">
                        <div className="space-y-2">
                          <Radio
                            name="interactive"
                            label="Option 1"
                            value="option1"
                            checked={selected === 'option1'}
                            onChange={(e) => setSelected(e.target.value)}
                          />
                          <Radio
                            name="interactive"
                            label="Option 2"
                            value="option2"
                            checked={selected === 'option2'}
                            onChange={(e) => setSelected(e.target.value)}
                          />
                          <Radio
                            name="interactive"
                            label="Option 3"
                            value="option3"
                            checked={selected === 'option3'}
                            onChange={(e) => setSelected(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    {/* States */}
                    <div>
                      <h4 className="text-sm font-medium text-neutral-content/80 mb-3">States</h4>
                      <div className="p-4 border border-neutral-border rounded-lg bg-neutral-background">
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Default</h3>
                            <Radio name="example1" label="Label" checked={false} />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Checked</h3>
                            <Radio name="example1" label="Label" checked={true} />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Disabled</h3>
                            <Radio name="example2" label="Label" disabled checked={false} />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Disabled Checked</h3>
                            <Radio name="example2" label="Label" disabled checked={true} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ComponentGroup>

                <ComponentGroup title="Switch" id="switch">
                  <div className="space-y-4">
                    {/* Interactive Example */}
                    <div>
                      <h4 className="text-sm font-medium text-neutral-content/80 mb-3">Interactive Example</h4>
                      <div className="p-4 border border-neutral-border rounded-lg bg-neutral-background">
                        <Switch
                          checked={switchChecked}
                          onCheckedChange={setSwitchChecked}
                          label="Toggle me"
                        />
                      </div>
                    </div>

                    {/* States */}
                    <div>
                      <h4 className="text-sm font-medium text-neutral-content/80 mb-3">States</h4>
                      <div className="p-4 border border-neutral-border rounded-lg bg-neutral-background">
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Default</h3>
                            <Switch
                              checked={false}
                              onCheckedChange={() => {}}
                              label="Label"
                            />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Selected</h3>
                            <Switch
                              checked={true}
                              onCheckedChange={() => {}}
                            />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Disabled</h3>
                            <Switch
                              checked={false}
                              onCheckedChange={() => {}}
                              disabled={true}
                            />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Disabled Checked</h3>
                            <Switch
                              checked={true}
                              onCheckedChange={() => {}}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ComponentGroup>

                <ComponentGroup title="Tabs" id="tabs">
                  <div className="space-y-4">
                    {/* Interactive Example */}
                    <div>
                      <h4 className="text-sm font-medium text-neutral-content/80 mb-3">Interactive Example</h4>
                      <div className="p-4 border border-neutral-border rounded-lg bg-neutral-background">
                        <Tabs value="tab1" className="inline-flex w-auto">
                          <TabItem value="tab1">First Tab</TabItem>
                          <TabItem value="tab2">Second Tab</TabItem>
                          <TabItem value="tab3">Third Tab</TabItem>
                        </Tabs>
                      </div>
                    </div>

                    {/* States */}
                    <div>
                      <h4 className="text-sm font-medium text-neutral-content/80 mb-3">States</h4>
                      <div className="p-4 border border-neutral-border rounded-lg bg-neutral-background">
                        <div className="space-y-4">
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Default</h3>
                            <Tabs value="tab1" className="inline-flex w-auto">
                              <TabItem value="tab1">Selected</TabItem>
                              <TabItem value="tab2">Default</TabItem>
                            </Tabs>
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Disabled</h3>
                            <Tabs value="tab1" className="inline-flex w-auto">
                              <TabItem value="tab1">Active</TabItem>
                              <TabItem value="tab2" disabled>Disabled</TabItem>
                            </Tabs>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ComponentGroup>

                <ComponentGroup title="Text Input" id="text-input">
                  <div className="space-y-4">
                    {/* Interactive Example */}
                    <div>
                      <h4 className="text-sm font-medium text-neutral-content/80 mb-3">Interactive Example</h4>
                      <div className="p-4 border border-neutral-border rounded-lg bg-neutral-background">
                        <div className="max-w-sm">
                          <Input
                            label="Interactive Input"
                            showLabel
                            placeholder="Type something..."
                            helperText="Try typing and focusing this input"
                            showHelperText
                          />
                        </div>
                      </div>
                    </div>

                    {/* States */}
                    <div>
                      <h4 className="text-sm font-medium text-neutral-content/80 mb-3">States</h4>
                      <div className="p-4 border border-neutral-border rounded-lg bg-neutral-background">
                        <div className="grid grid-cols-2 gap-4">
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Default</h3>
                            <Input placeholder="Placeholder text" />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Hovered</h3>
                            <Input placeholder="Placeholder text" state="hovered" />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Focused</h3>
                            <Input placeholder="Placeholder text" state="focused" />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Filled</h3>
                            <Input placeholder="Placeholder text" state="filled" value="Input text" />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Error</h3>
                            <Input 
                              placeholder="Placeholder text" 
                              state="error"
                              label="Label"
                              showLabel
                              helperText="Error message"
                              showHelperText
                            />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Success</h3>
                            <Input 
                              placeholder="Placeholder text" 
                              state="success"
                              label="Label"
                              showLabel
                              helperText="Success message"
                              showHelperText
                            />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Caution</h3>
                            <Input 
                              placeholder="Placeholder text" 
                              state="caution"
                              label="Label"
                              showLabel
                              helperText="Warning message"
                              showHelperText
                            />
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-neutral-content/80 mb-2">Disabled</h3>
                            <Input placeholder="Placeholder text" disabled />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ComponentGroup>
              </div>
            </ComponentShowcase>
          </div>
        </div>
      </div>
    </div>
  );
}
