import React from "react";

interface Props {
  className?: string;
  color?: string;
}

export const Caretdown1 = ({ className = "", color = "currentColor" }: Props): JSX.Element => {
  return (
    <svg
      className={className}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1465 5.35352L8.35359 10.1464C8.15833 10.3417 7.84175 10.3417 7.64648 10.1464L2.85359 5.35352"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </svg>
  );
};
