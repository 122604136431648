const PrivacyPolicy = () => {
  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-6">Privacy Policy</h1>
      <div className="prose max-w-none">
        <div className="mb-8">
          <p><strong>Effective Date:</strong> October 1, 2024</p>
          <p><strong>Last Updated:</strong> November 17, 2024</p>
        </div>

        <div className="space-y-8">
          <section>
            <h2 className="text-xl font-semibold mb-4">Introduction</h2>
            <p>Welcome to Product Sense (&quot;we&quot;, &quot;our&quot;, or &quot;us&quot;). Your privacy is of utmost importance to us. This Privacy Policy explains how we collect, use, and share information about you when you use our services, as well as your rights and choices regarding this information.</p>
          </section>
          
          <section>
            <h2 className="text-xl font-semibold mb-4">1. Information We Collect</h2>
            <div className="pl-4 space-y-4">
              <div>
                <h3 className="font-medium mb-2">1.1 Personal Identifiable Information (PII):</h3>
                <ul className="list-disc pl-6 space-y-2">
                  <li>Contact details such as name, email address, and phone number.</li>
                  <li>Customer data provided via integrations.</li>
                </ul>
              </div>
              
              <div>
                <h3 className="font-medium mb-2">1.2 Non-PII:</h3>
                <ul className="list-disc pl-6 space-y-2">
                  <li>IP addresses, browser types, and operating systems.</li>
                  <li>Aggregated analytics data for product improvement.</li>
                </ul>
              </div>
            </div>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">2. How We Use Your Information</h2>
            <ul className="list-disc pl-6 space-y-2">
              <li>To provide, operate, and improve our services.</li>
              <li>To personalize user experience and optimize our platform.</li>
              <li>To communicate about product updates and respond to support inquiries.</li>
              <li>To comply with legal obligations.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">3. Legal Basis for Processing</h2>
            <p className="mb-2">We process your information under the following legal bases:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Consent: When you provide your explicit consent for data processing.</li>
              <li>Contractual Obligations: To perform our agreement with you.</li>
              <li>Legitimate Interests: To improve our services and protect against misuse.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">4. Cookies and Tracking Technologies</h2>
            <p>We use cookies and similar technologies to collect data about user activity. You can control cookie settings in your browser to limit data collection.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">5. Sharing Your Information</h2>
            <p className="mb-2">We only share your information with:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li><strong>Subprocessors:</strong> Listed in our Subprocessor List.</li>
              <li><strong>Legal Authorities:</strong> As required by law or legal process.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">6. International Data Transfers</h2>
            <p>Your data may be transferred outside the EU or EEA. We ensure that these transfers comply with GDPR using Standard Contractual Clauses or other approved mechanisms.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">7. Data Retention</h2>
            <p>We retain your data only for as long as necessary to fulfill our purposes. Once your account is deleted, data is securely erased within 30 days.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">8. Security Measures</h2>
            <p>We implement industry-standard security measures, including encryption, regular audits, and secure infrastructure, to protect your information.</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">9. Your Rights</h2>
            <p className="mb-2">If you are a resident of the EU or EEA, you have the right to:</p>
            <ul className="list-disc pl-6 space-y-2">
              <li>Access, correct, or delete your personal data.</li>
              <li>Restrict or object to data processing.</li>
              <li>Request data portability.</li>
              <li>Lodge complaints with a Data Protection Authority.</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold mb-4">10. Contact Information</h2>
            <p>If you have questions or concerns, contact us at:</p>
            <div className="mt-2">
              <p><strong>Email:</strong> <a href="mailto:privacy@product-sense.io" className="text-indigo-600 hover:text-indigo-800">privacy@product-sense.io</a></p>
              <p className="mt-2">
                <strong>Address:</strong><br />
                221 W 9th St, Ste 734<br />
                Wilmington, DE 19801
              </p>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;