import * as Select from '@radix-ui/react-select';
import React from "react";
import { Error } from "./icons/Error";
import { Caution2 } from "./icons/Caution2";
import { Success } from "./icons/Success";
import { Caretdown1 } from "./icons/Caretdown1";
import { cn } from "../../lib/utils";

interface Props {
  showErrorIcon?: boolean;
  showHelperText?: boolean;
  showCautionIcon?: boolean;
  selectedText?: string;
  label?: string;
  showLabel?: boolean;
  helperText?: string;
  placeholderText?: string;
  state?: "default" | "success" | "filled" | "selected" | "focused" | "hovered" | "caution" | "error" | "disabled";
  className?: string;
  value?: string;
  onChange?: (value: string) => void;
  options: { value: string; label: string }[];
}

export const Dropdown = React.forwardRef<HTMLButtonElement, Props>(({
  showErrorIcon = true,
  showHelperText = true,
  showCautionIcon = true,
  selectedText = "Selected option",
  label = "Label",
  showLabel = true,
  helperText = "Helper text goes here.",
  placeholderText = "Select an option",
  state = "default",
  className,
  value,
  onChange,
  options,
}: Props, ref) => {
  return (
    <Select.Root value={value} onValueChange={onChange} disabled={state === "disabled"}>
      <div className={cn(
        "flex flex-col items-start gap-1 relative",
        className
      )}>
        {showLabel && (
          <div className={cn(
            "text-xs font-semibold leading-5 text-neutral-600 dark:text-neutral-400",
            {
              "text-red-500 dark:text-red-400": state === "error",
              "text-yellow-500 dark:text-yellow-400": state === "caution",
              "text-green-500 dark:text-green-400": state === "success",
              "text-neutral-400 dark:text-neutral-500": state === "disabled",
            }
          )}>
            {label}
          </div>
        )}

        <Select.Trigger
          ref={ref}
          className={cn(
            "w-full flex items-center justify-between px-3 py-1.5 relative",
            "text-[15px] leading-6 font-normal rounded-lg transition-colors duration-200",
            {
              "border-[1.5px]": ["selected"].includes(state),
              "border-[2px]": state === "focused",
              "border": !["selected", "focused"].includes(state),
              "border-purple-400 dark:border-purple-300": state === "selected",
              "border-blue-500 dark:border-blue-300 bg-white dark:bg-neutral-900": state === "focused",
              "border-red-500 dark:border-red-400": state === "error",
              "border-yellow-500 dark:border-yellow-400": state === "caution",
              "border-green-500 dark:border-green-400": state === "success",
              "border-neutral-300 dark:border-neutral-600": state === "disabled",
              "border-neutral-200 dark:border-neutral-600": state === "hovered",
              "border-neutral-200 dark:border-neutral-700": !["selected", "error", "caution", "success", "focused", "disabled", "hovered"].includes(state),
              "bg-neutral-50 dark:bg-neutral-700": state === "hovered",
              "bg-neutral-100 dark:bg-neutral-600": state === "selected",
              "bg-neutral-200 dark:bg-neutral-800": state === "disabled",
              "bg-white dark:bg-neutral-900": !["hovered", "selected", "disabled"].includes(state),
              "text-neutral-400 dark:text-neutral-500": state === "disabled" || state === "default",
              "text-neutral-500 dark:text-neutral-400": ["hovered", "focused"].includes(state),
              "text-neutral-900 dark:text-neutral-100": state === "selected",
              "cursor-not-allowed": state === "disabled",
              "hover:bg-neutral-50 dark:hover:bg-neutral-700": state !== "disabled",
            }
          )}
        >
          <Select.Value
            className={cn("block truncate", {
              "text-neutral-400": !value,
            })}
            placeholder={placeholderText}
          >
            {["caution", "error", "filled", "success"].includes(state) ? selectedText : undefined}
          </Select.Value>

          <div className="flex gap-2 items-center absolute right-3">
            {state === "error" && showErrorIcon && <Error className="w-[18px] h-[18px] text-red-500 dark:text-red-400" />}
            {state === "caution" && showCautionIcon && <Caution2 className="w-[18px] h-[18px] text-yellow-500 dark:text-yellow-400" />}
            {state === "success" && <Success className="w-[18px] h-[18px] text-green-500 dark:text-green-400" />}
            <Caretdown1
              className={cn(
                "w-[18px] h-[18px]",
                {
                  "text-neutral-900 dark:text-neutral-100": state !== "disabled",
                  "text-neutral-400 dark:text-neutral-500": state === "disabled"
                }
              )}
            />
          </div>
        </Select.Trigger>

        <Select.Portal>
          <Select.Content
            className={cn(
              "overflow-hidden border border-neutral-200 dark:border-neutral-700",
              "rounded-lg shadow-lg bg-white dark:bg-neutral-900 w-[var(--radix-select-trigger-width)]"
            )}
            position="popper"
            sideOffset={4}
          >
            <Select.Viewport className="p-1">
              {options.map((option) => (
                <Select.Item
                  key={option.value}
                  value={option.value}
                  className={cn(
                    "flex items-center gap-2 px-3 py-2 text-base rounded-md cursor-pointer outline-none",
                    "text-neutral-900 dark:text-neutral-100",
                    "hover:bg-neutral-50 dark:hover:bg-neutral-800",
                    "focus:bg-neutral-100 dark:focus:bg-neutral-700"
                  )}
                >
                  <Select.ItemText>{option.label}</Select.ItemText>
                  <Select.ItemIndicator className="ml-auto">
                    <Success className="w-4 h-4" />
                  </Select.ItemIndicator>
                </Select.Item>
              ))}
            </Select.Viewport>
          </Select.Content>
        </Select.Portal>

        {showHelperText && (
          <div className={cn(
            "text-xs leading-4 text-neutral-500 dark:text-neutral-400",
            {
              "text-red-500 dark:text-red-400": state === "error",
              "text-yellow-500 dark:text-yellow-400": state === "caution",
              "text-green-500 dark:text-green-400": state === "success",
              "text-neutral-300 dark:text-neutral-600": state === "disabled",
            }
          )}>
            {helperText}
          </div>
        )}
      </div>
    </Select.Root>
  );
});

Dropdown.displayName = "Dropdown";
