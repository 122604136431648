import { useState, useEffect, useMemo } from 'react';
import { useOrganization, OrganizationList } from '@clerk/clerk-react';
import { Loader2, AlertCircle, ChevronDown, Plus } from 'lucide-react';
import { useApi } from '../services/api';
import { MetricCard } from '../components/MetricCard';
import { TrendChart } from '../components/TrendChart';
import { ActivityFeed } from '../components/ActivityFeed';
import { Banner } from '../components/ui/Banner';
import { Tabs, TabItem } from '../components/ui/Tab';
import { DateRangeDropdown } from '../components/ui/DateRangeDropdown';
import { Divider } from '../components/ui/Divider';
import { Tooltip } from '../components/Tooltip';
import Tippy from '@tippyjs/react';
import { Button } from '../components/ui/Button';
import { Footer } from '../components/Footer';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, ResponsiveContainer, Area, AreaChart } from 'recharts';
import { FeedbackTable } from '../components/FeedbackTable';
import type { MetricCard as MetricCardType, ActivityItem, Feedback } from '../types';
import type { DateRange } from 'react-day-picker';
import { useLocation } from 'react-router-dom';

type FeedbackSource = 'zendesk' | 'slack' | 'call';

interface ProcessedFeedback {
  id: string;
  content: string;
  source: FeedbackSource;
  category: string;
  timestamp: string;
  persona: string;
  score: number;
  source_type: string;
  source_id: string;
  sentiment: number;
  metadata: {
    UserID: string;
    UserName: string;
    UserTitle: string;
    CompanyID: string;
    CompanyName: string;
    CompanyIndustry: string;
  };
  highlight_span: {
    start: number;
    end: number;
  };
  created_at: string;
  updated_at: string;
}

interface SourceMessage {
  id: string;
  text: string;
  timestamp: string;
  permalink: string;
}

const getSourceIcon = (sourceType: string) => {
  switch (sourceType.toLowerCase()) {
    case 'slack':
      return '/icons/slack.png';
    default:
      return null;
  }
};

const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const getSentimentEmoji = (sentiment: number) => {
  // Map -1 to 1 range to emojis
  if (sentiment >= 0.6) return { emoji: '😃', color: 'var(--success-content)' };
  if (sentiment >= 0.2) return { emoji: '🙂', color: 'var(--success-content)' };
  if (sentiment >= -0.2) return { emoji: '😐', color: 'var(--neutral-content)' };
  if (sentiment >= -0.6) return { emoji: '🙁', color: 'var(--error-content)' };
  return { emoji: '😡', color: 'var(--error-content)' };
};

const formatSentiment = (sentiment: number) => {
  // Convert to percentage without + sign
  return `${Math.round(sentiment * 100)}%`;
};

const mapSentimentToEnum = (sentiment: number): 'positive' | 'negative' | 'neutral' => {
  if (sentiment > 0.3) return 'positive';
  if (sentiment < -0.3) return 'negative';
  return 'neutral';
};

const mapCategoryToEnum = (category: string): 'security' | 'reliability' | 'performance' | 'usability' | 'value' => {
  const validCategories = ['security', 'reliability', 'performance', 'usability', 'value'];
  return validCategories.includes(category) 
    ? category as 'security' | 'reliability' | 'performance' | 'usability' | 'value'
    : 'usability'; // default fallback
};

export default function Dashboard() {
  const { fetchWithAuth } = useApi();
  const { organization } = useOrganization();
  const location = useLocation();
  const [timeRange] = useState('Last 7 days');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [feedback, setFeedback] = useState<ProcessedFeedback[]>([]);
  const [sourceMessages, setSourceMessages] = useState<Record<string, SourceMessage>>({});
  const [activities] = useState<ActivityItem[]>([]);
  const [hasMore] = useState(true);
  const [cursor] = useState<string | undefined>(undefined);
  const [activeTab, setActiveTab] = useState('all');
  const [metricCards, setMetricCards] = useState<MetricCardType[]>([]);
  const [metricHistory, setMetricHistory] = useState<any[]>([]);
  const [selectedCards, setSelectedCards] = useState<Set<string>>(new Set());
  const [dateRange, setDateRange] = useState<DateRange>(() => {
    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);
    return {
      from: sevenDaysAgo,
      to: today
    };
  });

  // Handler for date range changes (visual updates only)
  const handleDateRangeChange = (range: DateRange | undefined) => {
    if (!range) return;
    
    // Ensure we maintain the existing range for undefined values
    setDateRange(prevRange => ({
      from: range.from || prevRange.from,
      to: range.to || prevRange.to
    }));
  };

  // Handler for completed date range selections
  const handleDateRangeComplete = async (range: DateRange) => {
    if (!range.from || !range.to) return;
    
    setDateRange(range);

    try {
      await fetchMetricHistory({
        startDate: range.from,
        endDate: range.to,
        updatedCards: metricCards
      });
    } catch (error) {
      console.error('Error updating date range:', error);
      setError('Failed to update metric history with new date range');
    }
  };

  // Function to fetch metric history
  const fetchMetricHistory = async (options: {
    startDate: Date;
    endDate: Date;
    updatedCards?: MetricCardType[];
  }) => {
    if (!organization?.id || !options.startDate || !options.endDate) {
      console.error('Missing required parameters for fetching metric history');
      return;
    }

    try {
      // Always fetch fresh metric cards first
      const metricCardsResponse = await fetchWithAuth('/api/metric-cards');
      if (!metricCardsResponse?.data) {
        throw new Error('Failed to fetch metric cards');
      }
      
      // Transform and update metric cards
      const transformedMetricCards = metricCardsResponse.data.map((card: any) => ({
        id: card.ID,
        category: card.Category,
        description: card.Description,
        color: card.Color,
        score: card.Score,
        change: card.Change,
        created_at: card.CreatedAt,
        updated_at: card.UpdatedAt,
        org_id: card.OrgID
      }));
      
      setMetricCards(transformedMetricCards);

      // Ensure dates are in UTC for API request
      const startDateUTC = new Date(options.startDate);
      startDateUTC.setUTCHours(0, 0, 0, 0);
      
      const endDateUTC = new Date(options.endDate);
      endDateUTC.setUTCHours(23, 59, 59, 999);

      const historyResponse = await fetchWithAuth(
        `/api/metric-cards/history?orgId=${organization.id}&startDate=${startDateUTC.toISOString()}&endDate=${endDateUTC.toISOString()}`
      );

      if (!historyResponse?.data) {
        throw new Error('Failed to fetch metric history');
      }

      // Create a map of metric IDs to categories
      const metricIdToCategory = transformedMetricCards.reduce((acc: Record<string, string>, card: MetricCardType) => {
        acc[card.id ?? ''] = card.category;
        return acc;
      }, {});

      // Create an array of all dates in the range
      const dates: string[] = [];
      const currentDate = new Date(startDateUTC);
      
      while (currentDate <= endDateUTC) {
        dates.push(currentDate.toISOString().split('T')[0]);
        currentDate.setDate(currentDate.getDate() + 1);
      }

      // Initialize groupedData with empty objects for all dates
      const groupedData = dates.reduce((acc: Record<string, any>, date) => {
        acc[date] = {};
        return acc;
      }, {});

      // Fill in the actual data points
      historyResponse.data.forEach((item: any) => {
        const category = metricIdToCategory[item.MetricID];
        if (!category) return; // Skip if we can't find the category

        const timestamp = new Date(item.Timestamp);
        const date = timestamp.toISOString().split('T')[0];
        
        if (groupedData[date]) {
          if (!groupedData[date][category] || new Date(item.Timestamp) > new Date(groupedData[date].timestamp)) {
            groupedData[date][category] = item.Score;
            groupedData[date].timestamp = item.Timestamp;
          }
        }
      });

      // Convert to chart data format and sort by date
      const chartData = Object.entries(groupedData)
        .sort(([dateA], [dateB]) => dateA.localeCompare(dateB))
        .map(([date, scores]: [string, any]) => {
          const { timestamp, ...scoreData } = scores;
          return {
            date: new Date(date).toLocaleDateString(),
            ...scoreData
          };
        });

      setMetricHistory(chartData);
    } catch (error) {
      console.error('Error fetching metric history:', error);
      setError('Failed to fetch metric history');
    }
  };

  // Function to toggle card selection
  const handleCardSelect = (cardId: string) => {
    setSelectedCards(prev => {
      const newSet = new Set(prev);
      if (newSet.has(cardId)) {
        newSet.delete(cardId);
      } else {
        newSet.add(cardId);
      }
      return newSet;
    });
  };

  // Create a mapping of categories to their colors for the chart
  const categoryColors = useMemo(() => {
    return metricCards.reduce((acc: Record<string, string>, card) => {
      if ((selectedCards.size === 0 || (card.id && selectedCards.has(card.id)))) {
        acc[card.category] = card.color;
      }
      return acc;
    }, {});
  }, [metricCards, selectedCards]);

  // Function to filter chart data based on selected cards
  const getFilteredChartData = () => {
    if (!metricHistory || metricHistory.length === 0) return [];
    if (selectedCards.size === 0) return metricHistory;
    
    return metricHistory.map(point => {
      const filteredPoint: any = { date: point.date };
      Object.keys(point).forEach(key => {
        if (key === 'date') return;
        
        // Find the card that corresponds to this data series
        const card = metricCards.find(c => c.category === key);
        if (card?.id && selectedCards.has(card.id)) {
          filteredPoint[key] = point[key];
        }
      });
      return filteredPoint;
    });
  };

  // Memoize the filtered chart data
  const filteredChartData = useMemo(() => {
    return getFilteredChartData();
  }, [metricHistory, selectedCards, metricCards]);

  // Handle metric card update
  const handleMetricCardUpdate = async (updatedCard: MetricCardType) => {
    try {
      // Create the updated cards array
      const updatedCards = metricCards.map(card => 
        card.id === updatedCard.id ? updatedCard : card
      );
      
      // Update state and fetch history with the new cards
      setMetricCards(updatedCards);
      await fetchMetricHistory({
        startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
        endDate: new Date(),
        updatedCards
      });
    } catch (error) {
      console.error('Error updating metric card:', error);
    }
  };

  useEffect(() => {
    console.log("[Dashboard] Organization:", organization);
    if (!organization) {
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);
        setMetricHistory([]); // Reset metric history before fetching new data
        
        console.log("[Dashboard] Starting to fetch data for org:", organization.id);
        
        // Fetch metric cards first
        const metricCardsResponse = await fetchWithAuth('/api/metric-cards');
        if (!metricCardsResponse || !metricCardsResponse.data) {
          throw new Error('Failed to fetch metric cards');
        }
        
        // Transform the API response to match our frontend types
        const transformedMetricCards = metricCardsResponse.data.map((card: any) => ({
          id: card.ID,
          category: card.Category,
          description: card.Description,
          color: card.Color,
          score: card.Score,
          change: card.Change,
          created_at: card.CreatedAt,
          updated_at: card.UpdatedAt,
          org_id: card.OrgID
        }));
        
        setMetricCards(transformedMetricCards);

        // Ensure metric history is fetched after metric cards are set
        await fetchMetricHistory({
          startDate: dateRange.from || new Date(new Date().setDate(new Date().getDate() - 7)),
          endDate: dateRange.to || new Date()
        });

        // Fetch processed feedback
        const response = await fetchWithAuth('/api/feedback/list');
        console.log("[Dashboard] Feedback response:", response);
        
        if (!response) {
          throw new Error('Failed to fetch feedback data - no response received');
        }

        // Initialize empty array if no feedback data yet
        const processedFeedback = response.data ? response.data.map((item: any) => ({
          id: item._id,
          content: item.content,
          source_type: item.source_type || 'slack',
          source_id: item.source_id,
          sentiment: item.sentiment || 0,
          metadata: item.metadata || {},
          persona: item.persona || 'unknown',
          category: item.category || 'uncategorized',
          theme: item.theme || 'unknown',
          timestamp: item.created_at,
          source: item.source_type || 'slack',
          highlight_span: item.highlight_span || { start: 0, end: 0 }
        })) : [];

        setFeedback(processedFeedback);
        setLoading(false);
      } catch (err) {
        console.error('[Dashboard] Error fetching data:', err);
        setError(err instanceof Error ? err.message : 'An error occurred while fetching data');
        setLoading(false);
      }
    };

    fetchData();
  }, [fetchWithAuth, organization, location.pathname]); // Removed dateRange from dependencies

  const renderTooltipContent = (feedback: ProcessedFeedback) => {
    const sourceMessage = sourceMessages[feedback.source_id];
    if (!sourceMessage) return feedback.content;

    const beforeHighlight = sourceMessage.text.slice(0, feedback.highlight_span.start);
    const highlight = sourceMessage.text.slice(feedback.highlight_span.start, feedback.highlight_span.end);
    const afterHighlight = sourceMessage.text.slice(feedback.highlight_span.end);

    return (
      <div className="max-w-lg" style={{ padding: 'var(--spacing-4)' }}>
        <div className="flex items-center justify-between" style={{ 
          marginBottom: 'var(--spacing-2)',
          fontSize: 'var(--font-size-small)',
          fontWeight: 'var(--font-weight-medium)',
          color: 'var(--neutral-content-subtle)'
        }}>
          <span>Source Message</span>
          {feedback.metadata?.UserName && (
            <span style={{ color: 'var(--neutral-content)' }}>
              {feedback.metadata.UserName}
              {feedback.metadata.UserTitle && ` • ${feedback.metadata.UserTitle}`}
            </span>
          )}
        </div>
        <div style={{ 
          fontSize: 'var(--font-size-small)',
          color: 'var(--neutral-content)',
          marginBottom: 'var(--spacing-4)',
          lineHeight: '1.5'
        }}>
          <span>{beforeHighlight}</span>
          <span style={{ backgroundColor: 'var(--warning-background)', borderRadius: 'var(--radius-sm)' }}>{highlight}</span>
          <span>{afterHighlight}</span>
        </div>
        {sourceMessage.permalink && (
          <div style={{ 
            marginTop: 'var(--spacing-3)',
            paddingTop: 'var(--spacing-3)',
            borderTop: '1px solid var(--neutral-border)'
          }}>
            <a 
              href={sourceMessage.permalink}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center space-x-2"
              style={{ 
                fontSize: 'var(--font-size-small)',
                color: 'var(--primary-content)',
                textDecoration: 'none'
              }}
            >
              <img src="/icons/slack.png" alt="Slack" style={{ width: 'var(--spacing-4)', height: 'var(--spacing-4)' }} />
              <span>View in Slack</span>
            </a>
          </div>
        )}
      </div>
    );
  };

  const loadMore = () => {
    if (!loading && hasMore) {
      setLoading(true);
    }
  };

  if (!organization) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[60vh] p-4">
        <AlertCircle className="w-12 h-12 text-warning mb-4" />
        <h2 className="text-2xl font-semibold mb-2">Select an Organization</h2>
        <p className="text-muted-foreground text-center mb-4">
          Please select or create an organization using the organization switcher in the top right corner.
        </p>
        <OrganizationList 
          hidePersonal
          afterSelectOrganizationUrl="/dashboard"
          afterCreateOrganizationUrl="/dashboard"
        />
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[60vh]">
        <Loader2 className="w-8 h-8 animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4">
        <Banner variant="error" title="Error" description={error} />
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col" style={{ backgroundColor: 'var(--neutral-surface)' }}>
      <div className="flex-1">
        <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 space-y-8">
          {/* Overview Section */}
          <div className="bg-neutral-background shadow-sm rounded-lg overflow-hidden p-6">
            <div className="flex items-center justify-between mb-4">
              <h1 style={{ 
                fontSize: 'var(--font-size-h3)',
                fontWeight: 'var(--font-weight-semibold)',
                color: 'var(--neutral-content)',
                margin: 0
              }}>
                Overview
              </h1>
            </div>
            
            <div className="flex items-center justify-between mb-6">
              <Tabs 
                value={activeTab}
                onValueChange={setActiveTab}
                className="bg-transparent p-0 relative flex items-center"
              >
                <TabItem key="all" value="all">All</TabItem>
                <div className="h-4">
                  <Divider type="vertical" size="1x" className="mx-2" />
                </div>
                <TabItem key="sentiment" value="sentiment">Sentiment</TabItem>
                <TabItem key="revenue" value="revenue">Revenue</TabItem>
                <TabItem key="customers" value="customers">Customers</TabItem>
                <TabItem key="personas" value="personas">Personas</TabItem>
              </Tabs>
              <DateRangeDropdown
                value={dateRange}
                onChange={handleDateRangeChange}
                onComplete={handleDateRangeComplete}
                showLabel={false}
              />
            </div>

            <div className="-mx-6">
              <Divider className="bg-neutral-border" />
            </div>

            {/* Metrics Grid */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4 mt-6 mb-8">
              {metricCards.map((card) => (
                <MetricCard
                  key={card.id ?? ''}
                  {...card}
                  onUpdate={handleMetricCardUpdate}
                  isSelected={card.id ? selectedCards.has(card.id) : false}
                  onToggleSelect={handleCardSelect}
                />
              ))}
            </div>

            {/* Trend Chart */}
            <TrendChart 
              data={filteredChartData} 
              categoryColors={categoryColors}
            />
          </div>

          {/* Feedback and Activity Sections */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {/* Feedback Section */}
            <div className="lg:col-span-2 bg-neutral-background shadow-sm rounded-lg overflow-hidden">
              <div className="p-6 pb-0">
                <div className="flex items-center justify-between mb-4">
                  <div>
                    <h2 style={{ 
                      fontSize: 'var(--font-size-h3)',
                      fontWeight: 'var(--font-weight-semibold)',
                      color: 'var(--neutral-content)',
                      marginBottom: 'var(--spacing-2)'
                    }}>
                      Feedback
                    </h2>
                    <div style={{ 
                      fontSize: 'var(--font-size-small)',
                      color: 'var(--neutral-content-subtle)',
                      marginBottom: 'var(--spacing-4)'
                    }}>
                      Collected from {feedback.length} sources
                    </div>
                  </div>
                  <Button
                    variant="bordered"
                    onClick={() => {}}
                    style={{ 
                      borderColor: 'var(--neutral-border)',
                      color: 'var(--primary-content)',
                      backgroundColor: 'var(--neutral-background)'
                    }}
                  >
                    Export
                  </Button>
                </div>
              </div>
              <Divider className="w-full" />
              <FeedbackTable data={feedback} />
            </div>

            {/* Activity Feed */}
            <div className="bg-neutral-background shadow-sm rounded-lg overflow-hidden p-6">
              <h2 style={{ 
                fontSize: 'var(--font-size-h3)',
                fontWeight: 'var(--font-weight-semibold)',
                color: 'var(--neutral-content)',
                marginBottom: 'var(--spacing-2)'
              }}>
                Activity
              </h2>
              <div style={{ 
                fontSize: 'var(--font-size-small)',
                color: 'var(--neutral-content-subtle)',
                marginBottom: 'var(--spacing-4)'
              }}>
                Recent changes and updates
              </div>
              <ActivityFeed activities={activities} hideHeader={true} />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}