import React, { useState } from 'react';
import { Edit2 } from 'lucide-react';
import { Modal } from './ui/Modal';
import { Button } from './ui/Button';
import { Input } from './ui/Input';
import { ColorPicker } from './ui/ColorPicker';
import { useApi } from '../services/api';
import type { MetricCard as MetricCardType } from '../types';
import { cn } from '../lib/utils';

interface MetricCardProps extends MetricCardType {
  onUpdate?: (card: MetricCardType) => void;
  isSelected?: boolean;
  onToggleSelect?: (id: string) => void;
}

interface MetricCardResponse {
  success: boolean;
  data: {
    id: string;
    category: string;
    color: string;
    description: string | null;
    score: number;
    change: number;
    created_at: string;
    updated_at: string;
  };
}

export const MetricCard: React.FC<MetricCardProps> = ({ 
  id, 
  category, 
  score, 
  change, 
  color, 
  description, 
  onUpdate,
  isSelected,
  onToggleSelect 
}) => {
  const { fetchWithAuth } = useApi();
  const [isEditing, setIsEditing] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [editedCategory, setEditedCategory] = useState(category);
  const [editedColor, setEditedColor] = useState(color);
  const [editedDescription, setEditedDescription] = useState(description || '');
  const [isSaving, setIsSaving] = useState(false);

  const getChangeDisplay = () => {
    if (change === 0) return 'No change';
    return `${change > 0 ? '+' : ''}${change}%`;
  };

  const getChangeStyle = () => {
    if (change > 0) return {
      backgroundColor: 'var(--success-background)',
      color: 'var(--success-content)'
    };
    if (change < 0) return {
      backgroundColor: 'var(--error-background)',
      color: 'var(--error-content)'
    };
    return {
      backgroundColor: 'var(--neutral-surface)',
      color: 'var(--neutral-content)'
    };
  };

  const handleSave = async () => {
    try {
      setIsSaving(true);
      const response = await fetchWithAuth<MetricCardResponse>(`/api/metric-cards/${id}`, {
        method: 'PATCH',
        body: JSON.stringify({
          category: editedCategory,
          color: editedColor,
          description: editedDescription || null,  
          score: score,
        }),
      });

      if (response.success && response.data) {
        const updatedCard: MetricCardType = {
          id,
          category: response.data.category,
          color: response.data.color,
          description: response.data.description || undefined,  
          score: response.data.score,
          change: response.data.change,
          created_at: response.data.created_at,
          updated_at: response.data.updated_at,
        };
        onUpdate?.(updatedCard);
        setIsEditing(false);
      }
    } catch (error) {
      console.error('Failed to save metric card:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const colorOptions = ['#FF0000', '#00FF00', '#0000FF'];

  return (
    <>
      <div 
        className={cn(
          "rounded-lg px-3 py-2.5 border relative transition-all cursor-pointer",
          isSelected 
            ? "bg-primary-surface border-primary-border text-primary-content" 
            : "bg-neutral-background border-neutral-border hover:border-neutral-border-hovered text-neutral-content"
        )}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          const target = e.target as HTMLElement;
          // Check if the target exists and is not a button
          if (target && !target.closest('button') && id) {
            onToggleSelect?.(id);
          }
        }}
      >
        <div className="absolute top-3 right-3 w-4 h-4 flex items-center justify-center">
          {isHovered ? (
            <button
              onClick={() => setIsEditing(true)}
              className="w-6 h-6 -m-1 rounded-full hover:bg-neutral-surface transition-colors flex items-center justify-center"
              aria-label="Edit metric card"
            >
              <Edit2 className="w-3 h-3 text-neutral-content-weak" />
            </button>
          ) : (
            <div className="w-2 h-2 rounded-full" style={{ backgroundColor: color }} />
          )}
        </div>
        
        <div className="mb-3">
          <span className="text-[16px] leading-[20px] font-medium text-neutral-content">
            {category}
          </span>
        </div>
        
        <div className="flex items-center gap-2">
          <span className="text-[2.5rem] leading-[1] font-bold text-neutral-content">
            {score}
          </span>
          <span className="px-2 py-1 rounded-full text-sm font-medium" style={getChangeStyle()}>
            {getChangeDisplay()}
          </span>
        </div>
      </div>

      <Modal
        open={isEditing}
        onClose={() => setIsEditing(false)}
        onPrimaryAction={handleSave}
        title="Edit Metric Card"
        footer={
          <div className="flex justify-end gap-2">
            <Button variant="secondary" onClick={() => setIsEditing(false)}>
              Cancel
            </Button>
            <Button onClick={handleSave} disabled={isSaving}>
              {isSaving ? 'Saving...' : 'Save Changes'}
            </Button>
          </div>
        }
      >
        <form className="space-y-4">
          <div className="flex items-start">
            <div className="grow mr-4" style={{ maxWidth: 'calc(100% - 48px)' }}>
              <label htmlFor="category" className="block text-sm font-medium text-neutral-content mb-2">
                Title
              </label>
              <Input
                id="category"
                value={editedCategory}
                onChange={(e) => setEditedCategory(e.target.value)}
                placeholder="Enter metric title"
                showLabel={false}
              />
            </div>
            <div className="pt-[28px]">
              <ColorPicker
                color={editedColor}
                onChange={(color) => setEditedColor(color)}
              />
            </div>
          </div>

          <div>
            <label htmlFor="description" className="block text-sm font-medium text-neutral-content mb-2">
              Description
            </label>
            <textarea
              id="description"
              value={editedDescription}
              onChange={(e) => setEditedDescription(e.target.value)}
              maxLength={500}
              rows={4}
              className="w-full px-3 py-2 bg-neutral-background text-neutral-content border border-neutral-border rounded-md focus:outline-none focus:ring-2 focus:ring-primary resize-none placeholder:text-neutral-content-weak"
              placeholder="Enter card description (optional)"
            />
            <div className="mt-1.5 text-sm text-neutral-content-weak">
              {editedDescription.length}/500 characters
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};