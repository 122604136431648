import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth, useUser } from '@clerk/clerk-react';
import { Loader2 } from 'lucide-react';
import { Footer } from '../components/Footer';
import { Logo } from '../components/ui/Logo';

export default function Support() {
  const navigate = useNavigate();
  const { isSignedIn } = useAuth();
  const { user } = useUser();
  const [email, setEmail] = useState(user?.primaryEmailAddress?.emailAddress || '');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await fetch('/api/support', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, message }),
      });

      if (!response.ok) {
        throw new Error('Failed to send support request');
      }

      setSuccess(true);
      setMessage('');
    } catch (err) {
      setError('Failed to send support request. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleLogoClick = () => {
    if (isSignedIn) {
      navigate('/dashboard');
    } else {
      navigate('/');
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <div className="flex-1">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="max-w-md mx-auto">
            <div className="mb-8 text-center">
              <div 
                className="flex items-center justify-center gap-3 mb-6 cursor-pointer"
                onClick={handleLogoClick}
              >
                <div onClick={handleLogoClick} className="cursor-pointer">
                  <Logo />
                </div>
                <span className="text-xl font-bold text-gray-900">Product Sense</span>
              </div>
              <h1 className="text-2xl font-bold text-gray-900">Support</h1>
              <p className="mt-2 text-gray-600">How can we help you today?</p>
            </div>

            {success ? (
              <div className="bg-green-50 p-4 rounded-lg text-center">
                <p className="text-green-800 font-medium">Thank you for reaching out!</p>
                <p className="text-green-600 mt-1"><span>We will get back to you shortly.</span></p>
                <button
                  onClick={() => setSuccess(false)}
                  className="mt-4 btn btn-primary"
                >
                  Send another message
                </button>
              </div>
            ) : (
              <form onSubmit={(e: React.FormEvent) => { void handleSubmit(e); }} className="bg-white p-6 rounded-lg shadow-sm">
                {error && (
                  <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded text-red-700 text-sm">
                    {error}
                  </div>
                )}

                <div className="mb-4">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                    Email address
                  </label>
                  <input
                    type="email"
                    id="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    placeholder="you@example.com"
                  />
                </div>

                <div className="mb-4">
                  <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
                    How can we help?
                  </label>
                  <textarea
                    id="message"
                    required
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    rows={4}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    placeholder="Tell us what you need help with..."
                  />
                </div>

                <button
                  type="submit"
                  disabled={loading}
                  className="w-full btn btn-primary flex items-center justify-center"
                >
                  {loading ? (
                    <>
                      <Loader2 className="w-4 h-4 animate-spin mr-2" />
                      <span>Sending...</span>
                    </>
                  ) : (
                    <span>Send message</span>
                  )}
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}