import React, { useState, useCallback, useRef, useEffect } from 'react';
import { HexColorPicker } from 'react-colorful';
import { cn } from '../../utils/cn';
import { Input } from './Input';

interface ColorPickerProps {
  color: string;
  onChange: (color: string) => void;
  className?: string;
}

export const ColorPicker: React.FC<ColorPickerProps> = ({ color, onChange, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState(color);
  const popover = useRef<HTMLDivElement>(null);

  const close = useCallback(() => setIsOpen(false), []);

  useEffect(() => {
    setInputValue(color);
  }, [color]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popover.current && !popover.current.contains(event.target as Node)) {
        close();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [close]);

  const handleInputChange = (value: string) => {
    setInputValue(value);
    // Only update the actual color if it's a valid hex code
    if (/^#[0-9A-F]{6}$/i.test(value)) {
      onChange(value);
    }
  };

  const handleInputBlur = () => {
    // Reset the input value to the current color if invalid
    if (!/^#[0-9A-F]{6}$/i.test(inputValue)) {
      setInputValue(color);
    }
  };

  return (
    <div className={cn("relative", className)}>
      <div
        className="w-8 h-[38px] rounded-md cursor-pointer border border-neutral-border hover:border-neutral-border-hovered transition-colors"
        style={{ backgroundColor: color }}
        onClick={() => setIsOpen(true)}
      />
      
      {isOpen && (
        <div
          ref={popover}
          className="absolute left-0 top-[calc(100%+4px)] z-50 shadow-lg rounded-lg bg-neutral-background border border-neutral-border px-4"
        >
          <div className="py-4">
            <HexColorPicker
              color={color}
              onChange={onChange}
              className="!w-[200px] !h-[200px]"
            />
            <div className="mt-3 pb-2">
              <Input
                value={inputValue}
                onChange={(e) => handleInputChange(e.target.value)}
                onBlur={handleInputBlur}
                className="w-[100px] h-8 text-sm"
                placeholder="#000000"
                showLabel={false}
                leftIcon={
                  <div
                    className="w-4 h-4 rounded border border-neutral-border"
                    style={{ backgroundColor: color }}
                  />
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
