import React, { useEffect } from 'react';
import { X } from 'lucide-react';
import { cn } from '../../utils/cn';
import { Button } from './Button';

interface ModalProps {
  title: string;
  children: React.ReactNode;
  open?: boolean;
  onClose?: () => void;
  onPrimaryAction?: () => void | Promise<void>;
  className?: string;
  contentClassName?: string;
  footer?: React.ReactNode;
}

export const Modal = React.forwardRef<HTMLDivElement, ModalProps>(
  ({ title, children, open = false, onClose, onPrimaryAction, className, contentClassName, footer }, ref) => {
    useEffect(() => {
      if (!open) return;

      const handleKeyDown = async (event: KeyboardEvent) => {
        if (event.key === 'Escape' && onClose) {
          onClose();
        } else if (event.key === 'Enter' && onPrimaryAction && !event.isComposing) {
          event.preventDefault();
          try {
            await onPrimaryAction();
          } catch (error) {
            console.error('Error in primary action:', error);
          }
        }
      };

      window.addEventListener('keydown', handleKeyDown);
      return () => window.removeEventListener('keydown', handleKeyDown);
    }, [open, onClose, onPrimaryAction]);

    if (!open) return null;

    return (
      <>
        {/* Backdrop */}
        <div 
          className={cn(
            "fixed inset-0 z-50 bg-[var(--neutral-content)] opacity-40",
            "animate-in fade-in duration-300 ease-in-out"
          )}
          aria-hidden="true"
          onClick={onClose} 
        />
        
        {/* Modal */}
        <div
          ref={ref}
          role="dialog"
          aria-modal="true"
          className={cn(
            "fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-50",
            "w-[534px] bg-neutral-background rounded-lg",
            "shadow-lg",
            "animate-in fade-in zoom-in-95 duration-300 ease-in-out",
            className
          )}
        >
          {/* Header */}
          <div className="flex items-center justify-between px-4 py-3 border-b border-neutral-border">
            <h2 className="text-lg font-semibold text-neutral-content">{title}</h2>
            <button
              onClick={onClose}
              className="text-neutral-content-weak hover:text-neutral-content transition-colors"
              aria-label="Close modal"
            >
              <X className="w-5 h-5" />
            </button>
          </div>

          {/* Content */}
          <div className={cn("px-4 py-4", contentClassName)}>
            {children}
          </div>

          {/* Footer */}
          {footer && (
            <div className="flex justify-end gap-3 px-4 py-4 border-t border-neutral-border bg-neutral-background-weak">
              {footer}
            </div>
          )}
        </div>
      </>
    );
  }
);

Modal.displayName = 'Modal';