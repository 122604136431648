import React, { useState } from 'react';
import { ChevronDown } from 'lucide-react';
import { cn } from '../../utils/cn';

interface ExpandableSectionProps {
  title: string;
  subtitle?: string;
  children?: React.ReactNode;
  className?: string;
}

export const ExpandableSection: React.FC<ExpandableSectionProps> = ({
  title,
  subtitle,
  children,
  className,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className={cn("w-full overflow-hidden transition-all duration-150 ease-out", className)}>
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="flex items-center justify-between w-full px-6 py-4 text-left transition-all duration-150 ease-out"
      >
        <div className="space-y-1">
          <span className="text-[16px] font-bold text-neutral-content block transition-all duration-150 ease-out">
            {title}
          </span>
          <span className={cn(
            "text-sm text-neutral-content-subtle block transition-opacity duration-150 ease-out",
            isExpanded ? "opacity-100 h-auto" : "opacity-0 h-0"
          )}>
            {subtitle}
          </span>
        </div>
        <ChevronDown 
          className={cn(
            "w-6 h-6 text-neutral-content transition-transform duration-150 ease-out",
            isExpanded && "transform rotate-180"
          )}
        />
      </button>
      
      <div 
        className={cn(
          "grid transition-all duration-150 ease-out",
          isExpanded ? "grid-rows-[1fr]" : "grid-rows-[0fr]"
        )}
      >
        <div className="overflow-hidden">
          <div 
            className={cn(
              "px-6 pb-4 text-neutral-content-subtle transition-opacity duration-150",
              isExpanded ? "opacity-100" : "opacity-0"
            )}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}; 