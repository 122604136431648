import React from 'react';
import { cn } from '../../utils/cn';

interface RadioProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  label: string;
  disabled?: boolean;
  className?: string;
}

export const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  ({ label, disabled, className, checked, onChange, ...props }, ref) => {
    return (
      <label 
        className={cn(
          "flex items-center gap-2 relative w-[150px]",
          "cursor-pointer select-none",
          disabled && "cursor-not-allowed",
          className
        )}
      >
        <div className="relative">
          <input
            ref={ref}
            type="radio"
            disabled={disabled}
            checked={checked}
            onChange={onChange}
            className="sr-only"
            {...props}
          />
          <div 
            className={cn(
              "w-5 h-5 rounded-full border transition-all duration-200",
              "flex items-center justify-center",
              // Default state
              !disabled && !checked && "border-neutral-border bg-neutral-background hover:bg-neutral-background-hovered",
              // Checked state
              checked && !disabled && "border-neutral-content bg-neutral-background",
              // Disabled states
              disabled && "border-interactive-background-disabled bg-interactive-background-disabled"
            )}
          >
            {checked && !disabled && (
              <div className="w-3 h-3 rounded-full bg-[#570df8]" />
            )}
            {checked && disabled && (
              <div className="w-3 h-3 rounded-full bg-[#A1A1AA]" />
            )}
          </div>
        </div>
        <span 
          className={cn(
            "text-sm leading-5",
            !disabled && "text-neutral-content",
            disabled && "text-[#A1A1AA]"
          )}
        >
          {label}
        </span>
      </label>
    );
  }
);

Radio.displayName = 'Radio'; 