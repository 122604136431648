import { Outlet, useNavigate, Link, useLocation } from 'react-router-dom';
import { Search, Bell, Settings } from 'lucide-react';
import { SignedIn, SignedOut, SignInButton, UserButton, OrganizationSwitcher, useOrganization } from "@clerk/clerk-react";
import { Footer } from './Footer';
import { useTheme } from './ThemeProvider';
import { Button } from './ui/Button';
import { Logo } from './ui/Logo';
import { useClerkTheme } from '../hooks/useClerkTheme';

export default function Layout() {
  const navigate = useNavigate();
  const location = useLocation();
  const { theme, toggleTheme } = useTheme();
  const { organization } = useOrganization();

  const isActive = (path: string) => {
    if (path === '/app/dashboard') {
      return location.pathname === '/app' || location.pathname === '/app/dashboard';
    }
    return location.pathname === path;
  };

  return (
    <div className="min-h-screen flex flex-col">
      <SignedIn>
        <header className="bg-neutral-background border-b sticky top-0 z-10">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center h-16">
              <div className="flex items-center gap-1">
                <Button variant="plain" onClick={() => navigate('/app/dashboard')}>
                  <Logo />
                </Button>
                <span className="text-2xl font-semibold text-neutral-content-strong mb-1">
                  Product Sense
                </span>
                
                <nav className="hidden md:flex items-center gap-6 ml-8">
                  <Link 
                    to="/app/dashboard" 
                    className={`text-sm font-medium ${
                      isActive('/app/dashboard')
                        ? 'text-primary-plain' 
                        : 'text-neutral-content-weak hover:text-neutral-content-strong'
                    }`}
                  >
                    Dashboard
                  </Link>
                  <Link 
                    to="/app/settings" 
                    className={`text-sm font-medium ${
                      isActive('/app/settings') 
                        ? 'text-primary-plain' 
                        : 'text-neutral-content-weak hover:text-neutral-content-strong'
                    }`}
                  >
                    Settings
                  </Link>
                  <Link
                    to="/app/design-system"
                    className={`text-sm font-medium ${
                      location.pathname === '/app/design-system'
                        ? 'text-primary-plain' 
                        : 'text-neutral-content-weak hover:text-neutral-content-strong'
                    }`}
                  >
                    Design System
                  </Link>
                </nav>
              </div>

              <div className="flex items-center gap-4">
                <div className="flex items-center gap-1">
                  <button 
                    onClick={() => navigate('/app/search')} 
                    className="p-2 text-neutral-content-weak hover:text-neutral-content-strong rounded-full hover:bg-neutral-background-hovered"
                  >
                    <Search className="h-5 w-5" />
                  </button>
                  <button 
                    onClick={() => navigate('/app/notifications')} 
                    className="p-2 text-neutral-content-weak hover:text-neutral-content-strong rounded-full hover:bg-neutral-background-hovered"
                  >
                    <Bell className="h-5 w-5" />
                  </button>
                  <button 
                    onClick={() => navigate('/app/settings')}
                    className="p-2 text-neutral-content-weak hover:text-neutral-content-strong rounded-full hover:bg-neutral-background-hovered md:hidden"
                  >
                    <Settings className="h-5 w-5" />
                  </button>
                  <div className="flex items-center gap-2">
                    <button
                      onClick={toggleTheme}
                      className="flex h-8 w-8 items-center justify-center rounded-md border border-neutral-border bg-neutral-background hover:bg-neutral-background-hovered"
                    >
                      {theme === 'dark' ? '🌙' : '☀️'}
                    </button>
                    <OrganizationSwitcher 
                      appearance={{
                        variables: {
                          ...useClerkTheme().variables,
                          colorText: theme === 'dark' ? '#FFFFFF' : '#1F2937',
                          colorTextSecondary: theme === 'dark' ? '#A1A1AA' : '#6B7280'
                        },
                        elements: {
                          ...useClerkTheme().elements.organizationSwitcher,
                          organizationSwitcherTrigger: `w-[180px] rounded-md border border-neutral-border px-0 py-0 ${theme === 'dark' ? '!text-white' : 'text-neutral-content-weak'} hover:text-neutral-content-strong bg-neutral-background hover:bg-neutral-background-hovered`,
                          organizationSwitcherPopoverCard: "w-[180px] bg-neutral-background border border-neutral-border shadow-lg rounded-md",
                          organizationSwitcherTriggerIcon: "hidden",
                          organizationSwitcherTriggerMainIdentifier: `mx-0 text-sm font-medium ${theme === 'dark' ? '!text-white' : 'text-neutral-content-weak'}`,
                          organizationSwitcherTriggerContainer: "p-0 m-0",
                          avatarBox: "hidden",
                          organizationPreviewText: theme === 'dark' ? '!text-white' : 'text-neutral-content-weak',
                          organizationSwitcherPopoverActionButton: `w-full text-left py-2 px-3 text-sm font-medium ${theme === 'dark' ? '!text-white hover:!text-white' : 'text-neutral-content-weak hover:text-neutral-content-strong'} hover:bg-neutral-background-hovered`
                        }
                      }}
                    />
                    <UserButton 
                      afterSignOutUrl="/"
                      appearance={{
                        elements: {
                          userButtonTrigger: "rounded-full hover:opacity-80",
                          userButtonPopoverCard: "bg-neutral-background border border-neutral-border shadow-lg",
                          userButtonPopoverActions: "px-2 py-2",
                          userButtonPopoverActionButton: "px-4 py-2 text-neutral-content-strong hover:bg-neutral-background-hovered w-full text-left",
                          userButtonPopoverFooter: "border-t border-neutral-border mt-2",
                          userPreview: "px-4 py-2 hover:bg-neutral-background-hovered",
                          userPreviewMainIdentifier: "text-base",
                          userPreviewSecondaryIdentifier: "text-sm text-neutral-content-weak"
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <main className="flex-1 bg-neutral-background">
          <SignedIn>
            <div className="flex flex-col min-h-full">
              <div className="flex-1">
                <Outlet />
              </div>
            </div>
          </SignedIn>
          <SignedOut>
            <div className="flex flex-col min-h-screen">
              <div className="flex-1 flex items-center justify-center">
                <div className="flex flex-col items-center">
                  <div className="flex flex-col items-center mb-12">
                    <Logo className="h-100 w-auto" />
                  </div>
                  <div className="flex flex-col items-center gap-2">
                    <SignInButton mode="modal">
                      <Button variant="primary" size="md" className="w-32">
                        Sign In
                      </Button>
                    </SignInButton>
                    <button
                      onClick={toggleTheme}
                      className="flex h-8 w-8 items-center justify-center rounded-md border border-neutral-border bg-neutral-background hover:bg-neutral-background-hovered"
                    >
                      {theme === 'dark' ? '🌙' : '☀️'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </SignedOut>
        </main>

        <Footer />
      </SignedIn>
    </div>
  );
}