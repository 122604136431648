import React from 'react';
import { Check } from 'lucide-react';
import { cn } from '../../utils/cn';

interface MenuItemProps {
  label: string;
  selected?: boolean;
  disabled?: boolean;
  icon?: React.ReactNode;
  shortcut?: string;
  onClick?: () => void;
  className?: string;
}

export const MenuItem = React.forwardRef<HTMLButtonElement, MenuItemProps>(
  ({ label, selected, disabled, icon, shortcut, onClick, className }, ref) => {
    return (
      <button
        ref={ref}
        className={cn(
          "flex w-full items-center gap-2 px-3 py-2 text-left text-sm rounded",
          "text-neutral-content hover:bg-neutral-surface",
          disabled && "text-neutral-content-disabled hover:bg-transparent cursor-not-allowed",
          selected && "bg-neutral-surface hover:bg-neutral-surface",
          className
        )}
        onClick={onClick}
        disabled={disabled}
      >
        {icon && (
          <span className="flex w-4 h-4 items-center justify-center text-neutral-content">
            {icon}
          </span>
        )}
        <span className="flex-grow truncate">{label}</span>
        {selected && !shortcut && (
          <Check className="w-4 h-4 text-neutral-content" />
        )}
        {shortcut && (
          <span className="text-xs px-1 py-0.5 bg-neutral-background-weak text-neutral-content-weak rounded border border-neutral-border">
            {shortcut}
          </span>
        )}
      </button>
    );
  }
);

MenuItem.displayName = 'MenuItem'; 