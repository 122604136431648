import React from 'react';
import { cn } from '../../lib/utils';
import { Loader2 } from 'lucide-react';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'plain' | 'bordered' | 'tertiary' | 'destructive';
  size?: 'xs' | 'sm' | 'md' | 'lg';
  isLoading?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  sectionHeader?: string;
  showSectionHeader?: boolean;
}

const variants = {
  primary: 'bg-[#570df8] text-white hover:bg-[#4a0bd3] focus:ring-[#570df8]',
  secondary: 'bg-neutral-surface text-neutral-content hover:bg-neutral-surface/80 focus:ring-neutral-content/30',
  plain: 'bg-transparent text-neutral-content hover:bg-neutral-surface/10 focus:ring-neutral-content/30',
  bordered: 'border border-neutral-border text-neutral-content hover:bg-neutral-surface/10 focus:ring-neutral-content/30',
  tertiary: 'bg-teal-700 text-white hover:bg-teal-800 focus:ring-teal-500',
  destructive: 'bg-red-500 text-neutral-content hover:bg-red-600 focus:ring-red-500'
};

const sizes = {
  xs: 'px-2.5 py-1.5 text-xs',
  sm: 'px-3 py-1.5 text-sm',
  md: 'px-4 py-2 text-sm',
  lg: 'px-6 py-3 text-base'
};

const baseStyles = cn(
  'inline-flex items-center justify-center font-medium rounded-md',
  'transition-colors duration-200',
  'focus:outline-none focus:ring-2 focus:ring-offset-2',
  'disabled:opacity-50 disabled:cursor-not-allowed'
);

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ 
    variant = 'primary', 
    size = 'md', 
    isLoading,
    leftIcon,
    rightIcon,
    className = '',
    children,
    sectionHeader,
    showSectionHeader = false,
    disabled,
    ...props 
  }, ref) => {
    const buttonContent = (
      <>
        {isLoading && (
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
        )}
        {!isLoading && leftIcon && (
          <span className="mr-2 -ml-0.5">{leftIcon}</span>
        )}
        <span>{children}</span>
        {!isLoading && rightIcon && (
          <span className="ml-2 -mr-0.5">{rightIcon}</span>
        )}
      </>
    );

    return (
      <div className="flex flex-col gap-1.5">
        {showSectionHeader && sectionHeader && (
          <div className="text-sm font-medium text-neutral-content/70">
            {sectionHeader}
          </div>
        )}
        <button
          ref={ref}
          className={cn(
            baseStyles,
            variants[variant],
            sizes[size],
            className
          )}
          disabled={disabled || isLoading}
          {...props}
        >
          {buttonContent}
        </button>
      </div>
    );
  }
);

Button.displayName = 'Button';
