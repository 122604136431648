import { useAuth, useOrganization } from '@clerk/clerk-react';
import { useCallback } from 'react';

interface FetchOptions extends RequestInit {
  headers?: Record<string, string>;
  skipOrg?: boolean; // Add option to skip organization header for certain routes
}

interface Headers {
  'Content-Type': string;
  'Authorization': string;
  'Accept': string;
  'x-forwarded-host': string;
  'x-forwarded-proto': string;
  'organization-id'?: string;  // Note the lowercase
}

interface ApiResponse<T = any> {
  success: boolean;
  data?: T;
  error?: string;
  message?: string;
}

export const useApi = () => {
  const { getToken, isLoaded, isSignedIn } = useAuth();
  const { organization } = useOrganization();

  const fetchWithAuth = useCallback(async <T extends ApiResponse>(endpoint: string, options: FetchOptions = {}): Promise<T> => {
    if (!isLoaded || !isSignedIn) {
      throw new Error('Not authenticated');
    }

    try {
      const token = await getToken();
      
      if (!token) {
        throw new Error('No authentication token available');
      }

      const baseUrl = process.env.NODE_ENV === 'development'
        ? 'https://localhost:3001'
        : 'https://productsense-ai-backend.onrender.com';

      const headers: Headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json',
        'x-forwarded-host': window.location.host,
        'x-forwarded-proto': window.location.protocol.replace(':', '')
      };

      // Add organization header if available and not explicitly skipped
      if (organization && !options.skipOrg) {
        headers['organization-id'] = organization.id;  // Use lowercase consistently
      }

      const requestUrl = `${baseUrl}${endpoint}`;

      console.log('Making request to:', requestUrl);

      const fetchOptions = {
        ...options,
        headers: {
          ...headers,
          ...options.headers,
        },
        credentials: 'include' as RequestCredentials,
      };

      const response = await fetch(requestUrl, fetchOptions);

      // Handle non-JSON responses
      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/json')) {
        const text = await response.text();
        console.error('Non-JSON response:', text);
        throw new Error('Invalid response format');
      }

      const responseData = await response.json() as T;

      if (!response.ok) {
        throw new Error(responseData.error || `HTTP error! status: ${response.status}`);
      }

      return responseData;
    } catch (error) {
      console.error('API request failed:', {
        error: error instanceof Error ? error.message : 'Unknown error',
        type: error instanceof TypeError ? 'TypeError' : error instanceof Error ? 'Error' : 'Unknown'
      });
      throw error;
    }
  }, [getToken, isLoaded, isSignedIn, organization]);

  return { fetchWithAuth };
};